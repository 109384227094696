import { LitElement, html, css } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class AgentView extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      agent: { type: Object },
    };
  }

  static get scopedElements() {
    return {
      'aab-button': customElements.get('aab-button'),
      'aab-typography': customElements.get('aab-typography'),
      'agent-um-simple-tile': customElements.get('agent-um-simple-tile'),
    };
  }

  static get styles() {
    return css`
      .button-container {
        display: flex;
        justify-content: flex-end;
        margin: 16px 0;
      }

      .button-container aab-button:first-child {
        margin-inline-end: 16px;
      }

      .logo {
        display: block;
        margin-top: 1rem;
        max-width: 160px;
        max-height: 120px;
        object-fit: contain;
      }
    `;
  }

  _handleEditClicked() {
    this.dispatchEvent(new CustomEvent('edit-click'));
  }

  _handleViewUsersClicked() {
    this.dispatchEvent(new CustomEvent('view-users-click'));
  }

  render() {
    if (!this.agent) {
      return null;
    }

    return html`
      <agent-um-simple-tile title="Agent details">
        <aab-typography>
          <label>Name</label>
          <p>${this.agent.username}</p>

          <label>Phone number</label>
          <p>${this.agent.personalPhone}</p>

          <!-- Email -->
          <label>Email</label>
          <p>${this.agent.emailAddress}</p>

          <!-- City -->
          <label>City</label>
          <p>${this.agent.city}</p>

          <!-- Country -->
          <label>Country</label>
          <p>${this.agent.country}</p>

          <!-- Logo -->
          <label>Logo</label>
          <img
            class="logo"
            src="data:image/png;base64,${this.agent.userGroupVO.logoBaseStr}"
          />
        </aab-typography>
      </agent-um-simple-tile>

      <div class="button-container">
        <aab-button style-type="secondary">
          <button @click="${this._handleEditClicked}">Edit</button>
        </aab-button>

        <aab-button style-type="base">
          <button @click="${this._handleViewUsersClicked}">
            View Users
          </button>
        </aab-button>
      </div>
    `;
  }
}

customElements.define('agent-um-agent-view', AgentView);
