export default [
  'CbpResourceRepositoryFactory',
  '$http',
  function (CbpResourceRepositoryFactory, $http) {
    const repo = new CbpResourceRepositoryFactory('/sid/agent/sid-requests');

    repo.setApproval = async (id, approved, reason) => {
      return (
        await $http.post(`${repo.resolvedResourceUrl()}/${id}/set-approval`, {
          approved: approved,
          reason: reason,
        })
      ).data;
    };

    repo.changeStatus = async (id, status) => {
      return (
        await $http.post(`${repo.resolvedResourceUrl()}/${id}/changeStatus`, {
          status,
        })
      ).data;
    };

    repo.requestApproval = async (id) => {
      return (
        await $http.post(`${repo.resolvedResourceUrl()}/${id}/request-approval`)
      ).data;
    };

    return repo;
  },
];
