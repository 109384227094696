/**
 * Returns an array where the `topAmount` is returned as-is, and the remaining
 * items are aggregates into one item
 *
 * @param {SidCountryData} sortedData - is assumed to be sorted descendingly
 * @param {number} topAmount - the top number of countries that will not be grouped
 */
export function groupSortedCountryData(sortedData, topAmount) {
  if (sortedData.length <= topAmount) {
    return sortedData;
  }
  const topItems = sortedData.slice(0, topAmount);
  const others = sortedData.slice(topAmount);

  const aggregate = others.reduce(
    (acc, cdi) => {
      acc.totalLegalShares += cdi.totalLegalShares;
      acc.totalNaturalShares += cdi.totalNaturalShares;
      acc.numberLegalShareholders += cdi.numberLegalShareholders;
      acc.numberNaturalShareholders += cdi.numberNaturalShareholders;
      return acc;
    },
    {
      totalLegalShares: 0,
      totalNaturalShares: 0,
      numberLegalShareholders: 0,
      numberNaturalShareholders: 0,
    }
  );

  aggregate.countryCode = 'Others';

  topItems.push(aggregate);
  return topItems;
}
