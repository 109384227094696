import 'Modules/shareholders-id/modules/common/sid-common.module';
import sidIntermediaryLandingPage from './views/landing-page/sid-intermediary-landing-page.component';
import routes from './routes';

const sidIntermediaryModule = angular.module('sidIntermediary', [
  'ui.router',
  'sidCommon',
]);

sidIntermediaryModule.component({
  sidIntermediaryLandingPage,
});

/**
 * Routes
 */
sidIntermediaryModule.config(routes);

export default sidIntermediaryModule;
