export default [
  '$http',
  function ($http) {
    async function getById(sidRequestId, responseItemId) {
      return (
        await $http.get(
          `/v2/sid/issuer/sid-requests/${sidRequestId}/response-items/${responseItemId}`
        )
      ).data;
    }

    return {
      getById,
    };
  },
];
