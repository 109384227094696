module.exports = {
  bindings: {
    // Valid variants are numbers 1 up to 4
    variant: '<',
    tag: '@',
    color: '@',
  },
  transclude: true,
  controller: [
    function () {
      this.$onInit = () => {
        if (this.variant > 4) {
          this.variant = 1;
        }

        this.className = `h${this.variant}`;
        this.style = this.color === 'light' ? { color: 'white' } : null;

        this.tagName = this.tag ? this.tag : `h${this.variant}`;
      };
    },
  ],
  // This is not the cleanest way to do this in Angularjs - not in the slightest.
  // If you want to change this to a proper directive, feel free to do so.
  template: `
    <h1 ng-transclude class="{{ $ctrl.className }}" ng-if="$ctrl.tagName === 'h1'" ng-style="$ctrl.style"></h1>
    <h2 ng-transclude class="{{ $ctrl.className }}" ng-if="$ctrl.tagName === 'h2'" ng-style="$ctrl.style"></h2>
    <h3 ng-transclude class="{{ $ctrl.className }}" ng-if="$ctrl.tagName === 'h3'" ng-style="$ctrl.style"></h3>
    <h4 ng-transclude class="{{ $ctrl.className }}" ng-if="$ctrl.tagName === 'h4'" ng-style="$ctrl.style"></h4>
  `,
};
