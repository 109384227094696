const { BASE_URL } = require('../constants');

module.exports = {
  /**
   * Should expose the same API as the `em-input`, with, of course, the addition of the accessory{Label|Location}
   * bindings
   */
  bindings: {
    name: '@',
    type: '@',
    placeholder: '@',
    accessoryLabel: '@',
    accessoryLocation: '@',
    isInvalid: '<',

    value: '<',

    onChange: '&',
    onFocus: '&',
    onBlur: '&',
  },

  controller: [
    function () {
      this.$onInit = () => {
        if (!this.accessoryLocation) {
          this.accessoryLocation = 'left';
        }

        this.modelValue = this.value;
      };

      this.handleChange = () => {
        this.onChange({
          name: this.name,
          value: this.modelValue,
        });
      };

      this.handleFocus = () => {
        this.onFocus({
          name: this.name,
          value: this.modelValue,
        });
      };
      this.handlBlur = () => {
        this.onBlur({
          name: this.name,
          value: this.modelValue,
        });
      };
    },
  ],

  templateUrl: `${BASE_URL}/em-input-w-accessory/em-input-w-accessory.template.html`,
};
