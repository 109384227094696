export default function formatNumber(value) {
  return new Intl.NumberFormat().format(value);
}

/**
 * @param {number} number floating-point number between 0 and 1, will bedisplayed as 0-100%
 */
export function formatAsPercentageNumber(number, decimals = 0) {
  return parseFloat(number * 100).toFixed(decimals);
}
