/** Issuer "group"  */
export const issuersMainState = {
  name: 'agentUserManagement.issuers',
  url: '/issuers',
  template: '<div ui-view></div>',
  redirectTo: 'agentUserManagement.issuers.list',
};

export const listIssuersState = {
  name: 'agentUserManagement.issuers.list',
  url: '/list',
  component: 'agUserManagementIssuers',
};

export const viewIssuerState = {
  name: 'agentUserManagement.issuers.issuer',
  url: '/{issuerId}',
  component: 'agUserManagementIssuer',
};

export const addIssuerState = {
  name: 'agentUserManagement.issuers.addIssuer',
  url: '/add',
  component: 'agUserManagementAddIssuer',
};

export const editIssuerState = {
  name: 'agentUserManagement.issuers.editIssuer',
  url: '/edit/{issuerId}',
  component: 'agUserManagementEditIssuer',
};

/** Issuer users  */
export const viewIssuerUsersState = {
  name: 'agentUserManagement.issuers.users',
  url: '/{issuerId}/users',
  component: 'agUserManagementIssuerUsers',
};

export const viewIssuerUserState = {
  name: 'agentUserManagement.issuers.user',
  url: '/{issuerId}/users/{userId}',
  component: 'agUserManagementIssuerUser',
};

export const addIssuerUserState = {
  name: 'agentUserManagement.issuers.addUser',
  url: '/{issuerId}/addUser',
  component: 'agUserManagementAddIssuerUser',
};

export const editIssuerUserState = {
  name: 'agentUserManagement.issuers.editUser',
  url: '/{issuerId}/editUser/{userId}',
  component: 'agUserManagementEditIssuerUser',
};
