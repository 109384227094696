import { BASE_URL } from '../constants';

import './em-date-input.scss';

/**
 * How it's written now, the underlying input element will be [type="text"] rather than "date", to avoid horrible
 * browser specific datepicker implementations
 */
export default {
  /**
   * value should be either a Date object, or an ISO 8601 string
   * onChange returns a date object
   */
  bindings: {
    label: '@',
    name: '@',
    value: '<',
    onChange: '&',
    required: '<',
  },

  controller: [
    '$filter',
    '$element',
    function ($filter, $element) {
      this.$onInit = () => {
        this.form = $element.controller('form');

        this.dateOptions = {
          dateDisabled: false,
          formatYear: 'yy',
          maxDate: new Date(2120, 5, 22),
          minDate: new Date(),
          showWeeks: false,
        };

        this.inputId = String(Math.random() * 182934);
        this.opened = false;
        this.uibValue = new Date(this.value);
      };

      this.$onChanges = (changeObj) => {
        if (changeObj.value) {
          this.uibValue = new Date(this.value);
        }
      };

      this.handleChange = (ev) => {
        this.form[this.name].$setValidity('invalidDate', _isValidDate(), this);
        this.onChange({
          name: this.name,
          value: _formatDate(this.uibValue),
        });
      };

      let _isValidDate = () => {
        return (
          this.uibValue !== undefined &&
          typeof this.uibValue.getMonth === 'function'
        );
      };

      let _formatDate = (dateTime) => {
        return $filter('date')(dateTime, 'yyyy-MM-dd');
      };
    },
  ],

  templateUrl: `${BASE_URL}/em-date-input/em-date-input.template.html`,
};
