// make Continent
const c = (name, code, subContinents) => ({ name, code, subContinents });

// make SubContinent
const sc = (name, code, countries) => ({
  name,
  code,
  countries,
});

// See: https://developers.google.com/chart/interactive/docs/gallery/geochart#continent-hierarchy-and-codes
export default [
  c('Africa', '002', [
    sc('Nortern Africa', '015', ['DZ, EG, EH, LY, MA, SD, SS, TN']),
    sc('Western Africa', '011', [
      'BF',
      'BJ',
      'CI',
      'CV',
      'GH',
      'GM',
      'GN',
      'GW',
      'LR',
      'ML',
      'MR',
      'NE',
      'NG',
      'SH',
      'SL',
      'SN',
      'TG',
    ]),
    sc('Middle Africa', '017', [
      'AO',
      'CD',
      'ZR',
      'CF',
      'CG',
      'CM',
      'GA',
      'GQ',
      'ST',
      'TD',
    ]),
    sc('Eastern Africa', '014', [
      'BI',
      'DJ',
      'ER',
      'ET',
      'KE',
      'KM',
      'MG',
      'MU',
      'MW',
      'MZ',
      'RE',
      'RW',
      'SC',
      'SO',
      'TZ',
      'UG',
      'YT',
      'ZM',
      'ZW',
    ]),
    sc('Southern Africa', '018', ['BW', 'LS', 'NA', 'SZ', 'ZA']),
  ]),
  c('Europe', '150', [
    sc('Northern Europe', '154', [
      'GG',
      'JE',
      'AX',
      'DK',
      'EE',
      'FI',
      'FO',
      'GB',
      'IE',
      'IM',
      'IS',
      'LT',
      'LV',
      'NO',
      'SE',
      'SJ',
    ]),
    sc('Western Europe', '155', [
      'AT',
      'BE',
      'CH',
      'DE',
      'DD',
      'FR',
      'FX',
      'LI',
      'LU',
      'MC',
      'NL',
    ]),
    sc('Eastern Europe', '151', [
      'BG',
      'BY',
      'CZ',
      'HU',
      'MD',
      'PL',
      'RO',
      'RU',
      'SU',
      'SK',
      'UA',
    ]),
    sc('Southern Europe', '039', [
      'AD',
      'AL',
      'BA',
      'ES',
      'GI',
      'GR',
      'HR',
      'IT',
      'ME',
      'MK',
      'MT',
      'RS',
      'PT',
      'SI',
      'SM',
      'VA',
      'YU',
    ]),
  ]),
  c('Americas', '019', [
    sc('Northern America', '021', ['BM', 'CA', 'GL', 'PM', 'US']),
    sc('Caribbean', '029', [
      'AG',
      'AI',
      'AN',
      'AW',
      'BB',
      'BL',
      'BS',
      'CU',
      'DM',
      'DO',
      'GD',
      'GP',
      'HT',
      'JM',
      'KN',
      'KY',
      'LC',
      'MF',
      'MQ',
      'MS',
      'PR',
      'TC',
      'TT',
      'VC',
      'VG',
      'VI',
    ]),
    sc('Central America', '013', [
      'BZ',
      'CR',
      'GT',
      'HN',
      'MX',
      'NI',
      'PA',
      'SV',
    ]),
    sc('South America', '005', [
      'AR',
      'BO',
      'BR',
      'CL',
      'CO',
      'EC',
      'FK',
      'GF',
      'GY',
      'PE',
      'PY',
      'SR',
      'UY',
      'VE',
    ]),
  ]),
  c('Asia', '142', [
    sc('Central Asia', '143', ['TM', 'TJ', 'KG', 'KZ', 'UZ']),
    sc('Eastern Asia', '030', ['CN', 'HK', 'JP', 'KP', 'KR', 'MN', 'MO', 'TW']),
    sc('Southern Asia', '034', [
      'AF',
      'BD',
      'BT',
      'IN',
      'IR',
      'LK',
      'MV',
      'NP',
      'PK',
    ]),
    sc('South-Eastern Asia', '035', [
      'BN',
      'ID',
      'KH',
      'LA',
      'MM',
      'BU',
      'MY',
      'PH',
      'SG',
      'TH',
      'TL',
      'TP',
      'VN',
    ]),
    sc('Western Asia', '145', [
      'AE',
      'AM',
      'AZ',
      'BH',
      'CY',
      'GE',
      'IL',
      'IQ',
      'JO',
      'KW',
      'LB',
      'OM',
      'PS',
      'QA',
      'SA',
      'NT',
      'SY',
      'TR',
      'YE',
      'YD',
    ]),
  ]),
  c('Oceania', '009', [
    sc('Austalia and New Zealand', '053', ['AU', 'NF', 'NZ']),
    sc('Melanesia', '054', ['FJ', 'NC', 'PG', 'SB', 'VU']),
    sc('Micronesia', '057', ['FM', 'GU', 'KI', 'MH', 'MP', 'NR', 'PW']),
    sc('Polynesia', '061', [
      'AS',
      'CK',
      'NU',
      'PF',
      'PN',
      'TK',
      'TO',
      'TV',
      'WF',
      'WS',
    ]),
  ]),
];
