/* eslint-disable indent */
import { LitElement, html, css } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class UserInputForm extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      user: { type: Object },
    };
  }

  static get scopedElements() {
    return {
      'aab-button': customElements.get('aab-button'),
      'aab-checkbox': customElements.get('aab-checkbox'),
      'aab-input': customElements.get('aab-input'),
      'aab-select': customElements.get('aab-select'),
      'aab-tile': customElements.get('aab-tile'),
      'aab-typography': customElements.get('aab-typography'),
    };
  }

  static get styles() {
    return css`
      aab-input,
      aab-select {
        display: block;
        margin-bottom: 1rem;
      }
    `;
  }

  _update() {
    this.dispatchEvent(
      new CustomEvent('user-change', { detail: { ...this.user } })
    );
  }

  render() {
    return html`
      <aab-select
        aab-select-label="Title"
        .value="${this.user?.title || ''}"
        .options="${[
          { name: 'Mr.', value: 'Mr.' },
          { name: 'Mrs.', value: 'Mrs.' },
        ]}"
        @aab-select-value-change="${(ev) => {
          this.user.title = ev.detail.value;
          this._update();
        }}"
      ></aab-select>

      <aab-input>
        <label slot="label">First name</label>
        <input
          type="text"
          .value=${this.user?.firstName || ''}
          @input="${(ev) => {
            this.user.firstName = ev.target.value;
            this._update();
          }}"
        />
      </aab-input>

      <aab-input>
        <label slot="label">Last name</label>
        <input
          type="text"
          .value=${this.user?.lastName || ''}
          @input="${(ev) => {
            this.user.lastName = ev.target.value;
            this._update();
          }}"
        />
      </aab-input>

      <aab-input>
        <label slot="label">Office phone</label>
        <input
          type="text"
          .value=${this.user?.officePhoneNo || ''}
          @input="${(ev) => {
            this.user.officePhoneNo = ev.target.value;
            this._update();
          }}"
        />
      </aab-input>

      <aab-input>
        <label slot="label">Email</label>
        <input
          type="text"
          .value=${this.user?.email || ''}
          @input="${(ev) => {
            this.user.email = ev.target.value;
            this._update();
          }}"
        />
      </aab-input>

      <aab-input>
        <label slot="label">Personal phone number</label>
        <input
          type="text"
          .value=${this.user?.personalPhoneNo || ''}
          @input="${(ev) => {
            this.user.personalPhoneNo = ev.target.value;
            this._update();
          }}"
        />
      </aab-input>
    `;
  }
}

customElements.define('agent-um-user-input-form', UserInputForm);
