export default [
  '$stateProvider',
  function ($stateProvider) {
    const mainState = {
      name: 'shareholders-id.issuer',
      url: '/issuer',
      template: '<div ui-view></div>',
      redirectTo: 'shareholders-id.issuer.landing',
    };
    const landingState = {
      name: 'shareholders-id.issuer.landing',
      url: '/list',
      component: 'sidIssuerLandingPage',
    };
    const createRequestState = {
      name: 'shareholders-id.issuer.createRequest',
      component: 'sidIssuerCreateRequest',
      url: '/create-request',
    };
    const sidRequestDetailsState = {
      name: 'shareholders-id.issuer.sidRequestDetails',
      component: 'sidIssuerRequestDetails',
      url: '/sid-request/:sidRequestId',
    };
    const sidRequestDetailsStateOverview = {
      name: 'shareholders-id.issuer.sidRequestDetails.overview',
      component: 'sidIssuerRequestDetailsOverview',
      url: '/overview',
    };
    const sidRequestDetailsOverviewEditState = {
      name: 'shareholders-id.issuer.sidRequestDetails.edit',
      component: 'sidIssuerRequestDetailsOverviewEdit',
      url: '/edit',
    };

    const sidRequestDetailsResultsState = {
      name: 'shareholders-id.issuer.sidRequestDetails.results',
      component: 'sidIssuerRequestDetailsResults',
      url: '/results',
      redirectTo: 'shareholders-id.issuer.sidRequestDetails.results.overview',
    };

    const sidRequestDetailsResultsOverviewState = {
      name: 'shareholders-id.issuer.sidRequestDetails.results.overview',
      component: 'sidIssuerResultsShareholderReportsOverview',
      url: '/overview',
      params: {
        'shareholdingTypes': 'BENE,OOAC,UKWN,NONE'
      },
    };

    const issuerSidRequestDetailsResultsNomiState = {
      name: 'shareholders-id.issuer.sidRequestDetails.results.nomi',
      component: 'sidIssuerResultsShareholderReportsNomi',
      url: '/nomi',
    };

    const sidRequestDetailsResultsDetailsState = {
      name: 'shareholders-id.issuer.sidRequestDetails.results.details',
      component: 'sidIssuerResultsShareholderDetails',
      url: '/details/:itemIds',
    };

    $stateProvider.state(mainState);
    $stateProvider.state(landingState);
    $stateProvider.state(createRequestState);
    $stateProvider.state(sidRequestDetailsState);
    $stateProvider.state(sidRequestDetailsStateOverview);
    $stateProvider.state(sidRequestDetailsOverviewEditState);

    $stateProvider.state(sidRequestDetailsResultsState);
    $stateProvider.state(sidRequestDetailsResultsOverviewState);
    $stateProvider.state(issuerSidRequestDetailsResultsNomiState);
    $stateProvider.state(sidRequestDetailsResultsDetailsState);
  },
];
