import { BASE_URL } from '../constants';

import './em-input.scss';

export default {
  bindings: {
    label: '@',
    name: '@',
    type: '@',
    placeholder: '@',
    required: '<',
    readonly: '<',
    maxlength: '<',
    disabled: '<',

    value: '<',

    onChange: '&',
    onBlur: '&',
    onFocus: '&',
  },
  controller: [
    function () {
      this.$onInit = () => {
        /**
         * Set some logical defaults
         */
        if (!this.type) {
          this.type = 'text';
        }

        this.inputId = String(Math.random() * 13243);

        this.modelValue = this.value;
      };

      this.$onChanges = ({ value }) => {
        this.modelValue = value.currentValue;
      };

      this.handleChange = () => {
        if (this.onChange) {
          this.onChange({
            value: this.modelValue,
            name: this.name,
          });
        }
      };
      this.handleBlur = () => {
        if (this.onBlur) {
          this.onBlur({
            value: this.modelValue,
            name: this.name,
          });
        }
      };
      this.handleFocus = () => {
        if (this.onFocus) {
          this.onFocus({
            value: this.modelValue,
            name: this.name,
          });
        }
      };
    },
  ],
  templateUrl: `${BASE_URL}/em-input/em-input.template.html`,
};
