import { SID_IS_BASE_URL } from 'Modules/shareholders-id/constants';

import './sid-request-details-overview-edit.scss';

const controller = [
  'IS_SidRequestRepository',
  'IS_SidRequestValidator',
  'SidRequestConverter',
  'cbpToast',
  '$scope',
  '$stateParams',
  '$state',
  'User',
  'issuerPreferencesService',
  function (
    SidRequestRepository,
    SidRequestValidator,
    SidRequestConverter,
    cbpToast,
    $scope,
    $stateParams,
    $state,
    User,
    issuerPreferencesService
  ) {
    this.$onInit = async () => {
      this.sidRequestId = $stateParams.sidRequestId;
      await this.fetchSidRequest();
      $scope.$apply();
      this.fetchCsdPreference();
    };

    this.fetchCsdPreference = async () => {
      const { issuer } = await User.getMeAsIssuer();
      const csdPreference = await issuerPreferencesService.getCsdPreferenceForIssuer(
        issuer.id
      );
      if (csdPreference) {
        this.sidReqData.csdId = csdPreference;
        $scope.$apply();
      }
    };

    this.fetchSidRequest = async () => {
      this.loadingSidRequest = true;
      try {
        this.sidRequest = await SidRequestRepository.getById(this.sidRequestId);
        this.sidRequestData = SidRequestConverter.creationFormDataFromSidRequest(
          this.sidRequest
        );
      } catch (err) {
        this.error = err;
      } finally {
        this.loadingSidRequest = false;
      }
    };

    this.handleInput = (sidReqData) => {
      this.sidRequestData = sidReqData;
    };

    this.validate = () => {
      const validation = SidRequestValidator.validateCreationForm(
        this.sidRequestData
      );
      if (validation.isValid) {
        this.error = null;
        return true;
      }
      this.error = validation.message;
      return false;
    };

    this.handleSaveAsDraft = async () => {
      if (this.validate()) {
        const sidRequestData = SidRequestConverter.sidRequestFromCreationForm(
          this.sidRequestData
        );
        try {
          await SidRequestRepository.updateById(
            this.sidRequest.sidRequestId,
            sidRequestData
          );
          cbpToast.show('The SID request has been saved as draft.', 'success');
          $state.go('shareholders-id.issuer.landing');
        } catch (err) {
          this.error = err;
          $scope.apply();
        }
      }
    };

    this.handleSubmit = async () => {
      if (this.validate()) {
        const sidRequestData = SidRequestConverter.sidRequestFromCreationForm(
          this.sidRequestData
        );
        try {
          const sidRequest = await SidRequestRepository.updateById(
            this.sidRequest.sidRequestId,
            sidRequestData
          );
          await SidRequestRepository.requestApproval(sidRequest.sidRequestId);
          cbpToast.show('The SID request has been submitted.', 'success');
          $state.go('shareholders-id.issuer.landing');
        } catch (err) {
          if (err.data.errors) {
            this.error = `${err.data.errors[0].errorCode}: ${err.data.errors[0].errorMessage}`;
          } else {
            this.error = err;
          }
          $scope.$apply();
        }
      }
    };

    this.handleDelete = async () => {
      await SidRequestRepository.deleteById(this.sidRequest.sidRequestId);
      cbpToast.show('The SID request has been deleted.', 'success');
      $state.go('shareholders-id.issuer.landing');
    };
  },
];

export default {
  controller,
  templateUrl: `${SID_IS_BASE_URL}/views/sid-request-details/overview-edit/sid-request-details-overview-edit.template.html`,
};
