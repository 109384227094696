import moment from 'moment';
import './sid-request-timeline-event.scss';
import { formatDate } from '@/lib/date';

function createTimelineEventDescription(title, date, content, color) {
  return `
    <div class="emerald sid-request-timeline-event">
      <div class="mb-2 sid-request-timeline-event__title">
        <div class="sid-request-timeline-event__color-indicator" style="background-color: ${color}"></div>
        ${title}
      </div>
      <div class="mb-4 sid-request-timeline-event__date">
        ${moment(date).format('D-M-Y')}
      </div>
      <p class="px-3 mb-0 sid-request-timeline-event__description">
        ${content}
      </p>
    </div>
  `;
}

const COLOR_MAP = {
  green: '#004c4c', // g-500
  yellow: '#f3c000', // y-400
  orange: '#f60', // abn-warning-400
  red: '#c21700', // abn-negative-500
};

export default {
  bindings: {
    sidRequest: '<',
  },
  controller: [
    'cbpToast',
    '$scope',
    function (cbpToast, $scope) {
      this.$onInit = () => {
        this.startDate = new Date(this.sidRequest.startDate);
        this.endDate = new Date(this.sidRequest.reportDeadlineDate);

        this.formattedStartDate = formatDate(this.startDate);
        this.formattedEndDate = formatDate(this.endDate);

        this.daysRemaining = Math.max(
          moment(this.endDate).diff(moment().format('YYYY-MM-DD'), 'days'),
          0
        );

        this.sidRequestEvents = this.constructSidRequestTimelineEvents();
        this.hasStarted = new Date(this.sidRequest.startDate) < new Date();
      };

      this.constructSidRequestTimelineEvents = () => {
        const events = [];

        // Add start date
        events.push({
          date: this.startDate,
          description: createTimelineEventDescription(
            'SID request started',
            this.startDate,
            'The SID request is sent out to all relevant intermediaries. In due time, these intermediaries will respond with a list of shareholders',
            COLOR_MAP.green
          ),
          color: COLOR_MAP.green,
        });

        // If the endDate has passed, include endDate
        if (new Date() > this.endDate) {
          events.push({
            date: this.endDate,
            description: createTimelineEventDescription(
              'SID request finished',
              this.endDate,
              'SID request deadline has passed. The request has been finalized, and new responses will not be accepted',
              COLOR_MAP.red
            ),
            color: COLOR_MAP.red,
            magnitude: 1.5,
          });
        }
        return events;
      };
    },
  ],
  templateUrl:
    'features/modules/shareholders-id/modules/common/components/sid-request-timeline/sid-request-timeline.template.html',
};
