import { LitElement, html } from 'lit-element';
import styles from './styles.scss';
import { sy_arrow_chevron_left as backIcon } from '@aab/sc-aab-icon-set';

export default class AabHero extends LitElement {
  static get properties() {
    return {
      title: { type: String, attribute: 'aab-hero-title' },
      subtitle: { type: String, attribute: 'aab-hero-subtitle' },
      backLabel: { type: String, attribute: 'aab-hero-back-label' },
      variant: { type: String },
    };
  }

  static get styles() {
    return styles;
  }

  get _iconColor() {
    return this.variant === 'light' ? '#666666' : '#D3E0E0';
  }

  _handleBackClick() {
    this.dispatchEvent(new CustomEvent('aab-hero-back-click'));
  }

  render() {
    // subtitle can either be a subtitle or a backButton
    const subtitle = this.backLabel
      ? html` <a class="subtitle back-button" @click="${this._handleBackClick}">
          <aab-icon
            class="back-button__icon"
            svg="${backIcon}"
            size="3"
            color="${this._iconColor}"
          ></aab-icon>
          ${this.backLabel || ''}
        </a>`
      : html`<h2 class="subtitle">${this.subtitle || ''}</h2>`;
    return html`
      <div class="root ${this.variant === 'light' ? 'variant-light' : ''}">
        <div class="container d-flex justify-content-between align-items-end">
          <div class="flex-grow-1">
            ${subtitle}
            <h1 class="title">${this.title || 'Title'}</h1>
          </div>

          <div class="right">
            <slot name="right"></slot>
          </div>
        </div>
      </div>
    `;
  }
}
