// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DekCXk1Weh7pGvCTmNHfF{font-size:1.25rem;line-height:32px}._3Z6aMnvENQMJDbLFfPXzZ6{font-family:\"Roboto\",\"Helvetica Neue\",Arial,sans-serif}._13vzpQ1U0J2tNCdeSbu-Ox{font-family:\"Roboto\",\"Helvetica Neue\",Arial,sans-serif;margin-top:0;margin-bottom:1rem;line-height:1.5;color:#222}._1yuSvJybDVQmu85tAJZDpF{transition:.1s color;color:#005e5d}._1yuSvJybDVQmu85tAJZDpF:hover{color:#00857a}._2fYGb-pEPjk_JoUo48NTDU{font-size:.875rem;line-height:1.25rem}._2juc7BPzf-TVS19BDGldwY{font-family:\"Roboto\",\"Helvetica Neue\",Arial,sans-serif;font-weight:500;font-size:.875rem;line-height:1.25rem;color:#3c5e5e}._1GVNLntcg4W2z-svVbg3_C{color:#005e5d}._3GuM9OzGbqCO9D44bZ9mp5{color:#666}._2GWAiZdkmpCZTiAKN3Sjro{color:#999}._35Sg6EUcF4ZfX-dYImerkU{color:#fff}", ""]);
// Exports
exports.locals = {
	"intro": "DekCXk1Weh7pGvCTmNHfF",
	"body": "_3Z6aMnvENQMJDbLFfPXzZ6",
	"paragraph": "_13vzpQ1U0J2tNCdeSbu-Ox",
	"link": "_1yuSvJybDVQmu85tAJZDpF",
	"small": "_2fYGb-pEPjk_JoUo48NTDU",
	"label": "_2juc7BPzf-TVS19BDGldwY",
	"green": "_1GVNLntcg4W2z-svVbg3_C",
	"gray": "_3GuM9OzGbqCO9D44bZ9mp5",
	"lightGray": "_2GWAiZdkmpCZTiAKN3Sjro",
	"white": "_35Sg6EUcF4ZfX-dYImerkU"
};
module.exports = exports;
