import routes from './routes/index';

import './modules/agent/sid-agent.module';
import './modules/issuer/sid-issuer.module';
import './modules/intermediary/sid-intermediary.module';

import './scss/shareholders-id.scss';

const sidModule = angular.module('sid', [
  'sidAgent',
  'sidIssuer',
  'sidIntermediary',
]);

/**
 * Routing + transition layer to abstract away the routing of the rest of the application
 */
sidModule.config(routes);

/**
 * The following few components are to provide a layer between the routing used throughout the application, and the
 * routing as it would ideally be applied to the SID module
 */
import sidMain from './views/sid-main.component';
sidModule.component({
  sidMain,
});
