import './EditProxyIntermediary';
import { fetchIntermediary } from '@/api/v1/userGroups';

export default {
  controller: [
    '$http',
    '$scope',
    '$stateParams',
    '$state',
    function ($http, $scope, $stateParams, $state) {
      this.$onInit = () => {
        $state.go('userMaintenance.editProxyIntermediaryGroup', {
          groupId: $stateParams.proxyIntermediaryId,
        });

        this.fetchProxyIntermediary().then(() => {
          $scope.$apply();
        });
      };

      this.fetchProxyIntermediary = async () => {
        this.proxyIntermediary = await fetchIntermediary(
          $http,
          $stateParams.proxyIntermediaryId
        );
      };
    },
  ],
  template: `
    <div class="agUserManagementEditProxyIntermediary my-3">
      <div class="p-5" ng-if="!$ctrl.proxyIntermediary">
        <cbp-loading-spinner></cbp-loading-spinner>
      </div>
      <agent-um-edit-proxy-intermediary
        ng-if="$ctrl.proxyIntermediary"
        ng-prop-proxy_intermediary="$ctrl.proxyIntermediary"
        ng-on-save-click="$ctrl.handleSaveClick($event)"
      ></agent-um-edit-proxyIntermediary>
    </div>
  `,
};
