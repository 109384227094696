let issuersCache = null;
let intermediariesCache = null;
let proxyIntermediariesCache = null;
let voteCollectorsCache = null;

export async function listIssuers($http) {
  if (issuersCache) {
    return issuersCache;
  }
  const response = await $http.get(`/v1/issuers/GM/EN`);
  issuersCache = response.data;
  return response.data;
}

export async function fetchIssuer($http, issuerId) {
  const response = await $http.get(`/v1/issuers/groups/${issuerId}/GM/EN`);
  return response.data;
}

export async function toggleIssuerDisabled($http, issuerId) {
  const { data } = await $http.put(`/v1/issuers/${issuerId}/IS/GM`);
  issuersCache = null;
  return data;
}

export async function listIntermediaries($http) {
  if (intermediariesCache) {
    return intermediariesCache;
  }
  const response = await $http.get(`/v1/intermediaries/GM/EN`);
  intermediariesCache = response.data;
  return response.data;
}

export async function listProxyIntermediaries($http) {
  if (proxyIntermediariesCache) {
    return proxyIntermediariesCache;
  }
  const response = await $http.get(`/v1/intermediaries/GM/EN/proxies`);
  proxyIntermediariesCache = response.data;
  return response.data;
}

export async function fetchIntermediary($http, intermediaryId) {
  const response = await $http.get(
    `/v1/intermediaries/groups/${intermediaryId}/GM/EN`
  );
  return response.data;
}

export async function toggleIntermediaryDisabled($http, intermediaryId) {
  const { data } = await $http.put(
    `/v1/intermediaries/${intermediaryId}/IN/GM`
  );
  intermediariesCache = null;
  return data;
}

export async function listVoteCollectors($http) {
  if (voteCollectorsCache) {
    return voteCollectorsCache;
  }
  const response = await $http.get(`/v1/votecollectors/GM/EN`);
  voteCollectorsCache = response.data;
  return response.data;
}

export async function fetchVoteCollector($http, vcId) {
  const response = await $http.get(`/v1/votecollectors/groups/${vcId}/GM/EN`);
  return response.data;
}

export async function toggleVoteCollectorDisabled($http, vcId) {
  const { data } = await $http.put(`/v1/votecollectors/${vcId}/VC/GM`);
  voteCollectorsCache = null;
  return data;
}
