export default [
  'CbpResourceRepositoryFactory',
  '$http',
  function (CbpResourceRepositoryFactory, $http) {
    const repo = new CbpResourceRepositoryFactory('/agent/share-classes');

    repo.getByIssuerId = async (issuerId) => {
      return (await $http.get(`/v2/agent/issuers/${issuerId}/share-classes`))
        .data;
    };

    return repo;
  },
];
