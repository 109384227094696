export const agentMainstate = {
  name: 'agentUserManagement.agent',
  url: '/agents',
  template: '<div ui-view></div>',
  redirectTo: 'agentUserManagement.agent.agent',
};

export const listAgentsState = {
  name: 'agentUserManagement.agent.agent',
  url: '/view',
  component: 'agUserManagementAgent',
};

export const editAgentState = {
  name: 'agentUserManagement.agent.editAgent',
  url: '/edit',
  component: 'agUserManagementEditAgent',
};

export const viewAgentUsersState = {
  name: 'agentUserManagement.agent.users',
  url: '/users',
  component: 'agUserManagementAgentUsers',
};

export const addAgentUserState = {
  name: 'agentUserManagement.agent.addUser',
  url: '/addUser',
  component: 'agUserManagementAddAgentUser',
};

export const editAgentUserState = {
  name: 'agentUserManagement.agent.editUser',
  url: '/editUser/{userId}',
  component: 'agUserManagementEditAgentUser',
};

export const viewAgentUserState = {
  name: 'agentUserManagement.agent.user',
  url: '/users/{userId}',
  component: 'agUserManagementAgentUser',
};
