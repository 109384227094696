import {
  RECORD_DATE_MAX_MONTHS_EARLIER,
  RECORD_DATE_MAX_DAYS_LATER,
  START_DATE_MAX_MONTHS_LATER,
  ROLE_ISSUER,
  ROLE_AGENT,
  DEADLINE_DAYS_DELAY_FOR_ROLE,
  MILLISECONDS_IN_A_DAY, REPORT_DEADLINE_DATE_MIN_DAYS_LATER
} from 'Modules/shareholders-id/constants';
import { parseLargeInt, parsePercentageNumber } from '@/lib/parseNumber';

/**
 * This Validator service contains functions to validate individual properties of a SidRequests. Because different
 * workstations (AG & IS) require (slightly) differnt properties to validate, it is up to workstation-specific
 * validators to validate the entire object, using these functions.
 */

/**
 * Dates may either be JS Date object, or ISO 8601 strings (YYYY-MM-DDTHH:MM:SSZ)
 */
export default [
  'ValidationResult',
  function (ValidationResult) {
    // Utility; makes sure we're working with a JS Date object
    function getDate(date) {
      if (typeof date === 'string') {
        date = new Date(date);
      }
      // Make sure date is a date through duck typing
      if (
        date &&
        typeof date.getDate == 'function' &&
        typeof date.getMonth == 'function' &&
        typeof date.getFullYear == 'function'
      ) {
        return date;
      } else {
        return false;
      }
    }

    // Utility
    function getMaxDate(date1, date2) {
      if (date1 > date2) {
        return date1;
      } else if (date2 > date1) {
        return date2;
      } else {
        return date1;
      }
    }

    function validateIssuerId(issuerId) {
      if (issuerId === undefined || issuerId === null) {
        return 'Invalid issuer';
      }
      return true;
    }

    function validateCsdId(csdId) {
      return csdId ? true : 'Invalid CSD';
    }

    function validateIsin(isin) {
      if (!isin || !/[A-Z]{2,2}[A-Z0-9]{9,9}[0-9]{1,1}/.test(isin)) {
        return 'Incorrect ISIN';
      }
      return true;
    }

    function validateTotalOutstandingShares(tos) {
      if (tos <= 0 || isNaN(tos)) {
        return 'Total outstanding shares must be a positive number';
      }
      return true;
    }

    function validateRecordDate(recordDate) {
      const date = getDate(recordDate);
      if (!date) {
        return 'Invalid record date';
      }

      // Must not be more than 1 year in the past
      const minRecordDate = new Date();
      minRecordDate.setMonth(
        minRecordDate.getMonth() - RECORD_DATE_MAX_MONTHS_EARLIER
      );

      if (date < minRecordDate) {
        return 'Record date may not be more than one year in the past';
      }

      // Must not be more than 30 days in the future
      const maxRecordDate = new Date();
      maxRecordDate.setDate(
        maxRecordDate.getDate() + RECORD_DATE_MAX_DAYS_LATER
      );

      if (date > maxRecordDate) {
        return 'Record date may not be later than 30 days from now';
      }

      return true;
    }

    function validateThresholdPct(thresholdPct) {
      if (thresholdPct < 0 || thresholdPct > 100 || isNaN(thresholdPct)) {
        return 'Threshold must be a percentage value between 0 and 100';
      }
      return true;
    }

    function validateThresholdQty(shareThreshold) {
      if (shareThreshold < 1 || isNaN(shareThreshold)) {
        return 'Threshold must be a positive number';
      }
      return true;
    }

    function validateStartDate(startDate) {
      const date = getDate(startDate);
      if (!date) {
        return 'Invalid start date';
      }

      // Must not be in the past
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      // This check is bogus...
      if (date < yesterday) {
        return 'Start date may not be in the past';
      }

      // And must not be more than two months in the future
      const maxStartDate = new Date();
      maxStartDate.setMonth(
        maxStartDate.getMonth() + START_DATE_MAX_MONTHS_LATER
      );
      if (date > maxStartDate) {
        return 'Start date may not be may not be later than two months in the future';
      }
      return true;
    }

    // necessary to deal with daylight savings time
    function dateToUTCDate(date) {
      return Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate()
      );
    }

    function validateDeadlineDate(disclosureDeadlineDate, reportDeadlineDate, { startDate, recordDate }, role) {
      const deadlineDaysDelay = DEADLINE_DAYS_DELAY_FOR_ROLE[role] || 0;
      const startOrRecordDate = dateToUTCDate(
          getMaxDate(getDate(startDate), getDate(recordDate)));
      const minDeadlineDate = startOrRecordDate + deadlineDaysDelay * MILLISECONDS_IN_A_DAY;

      let utcReportDeadlineDate = startOrRecordDate + REPORT_DEADLINE_DATE_MIN_DAYS_LATER * MILLISECONDS_IN_A_DAY;
      if (reportDeadlineDate) {
        utcReportDeadlineDate = dateToUTCDate(getDate(reportDeadlineDate));
      }
      if (utcReportDeadlineDate < minDeadlineDate) {
        if (deadlineDaysDelay) {
          return 'The report deadline date may not be sooner than ' + deadlineDaysDelay +' days after the record date or start date (whichever is later). Please contact the account manager in case you want an earlier report deadline date.';
        }
        return 'The report deadline date may not be sooner than the record date or start date (whichever is later).';
      }
      if (disclosureDeadlineDate) {
        const utcDisclosureDeadlineDate = dateToUTCDate(
            getDate(disclosureDeadlineDate));
        if (utcDisclosureDeadlineDate >= utcReportDeadlineDate) {
          return 'The disclosure deadline date must be before the report deadline date';
        }
        if (utcDisclosureDeadlineDate < startOrRecordDate) {
          return 'The disclosure deadline date may not be sooner than the record date or start date (whichever is later).';
        }
      }
      return true;
    }

    function validateIssuerCreationForm(formData) {
      return validateBaseCreationForm(formData, ROLE_ISSUER);
    }

    function validateAgentCreationForm(formData) {
      return validateBaseCreationForm(formData, ROLE_AGENT);
    }
    function validateBaseCreationForm(formData, role) {
      let csdResult = validateCsdId(formData.csdId);
      if (csdResult !== true) {
        return new ValidationResult(false, 'csdId', csdResult);
      }

      let isinResult = validateIsin(formData.isin);
      if (isinResult !== true) {
        return new ValidationResult(false, 'isin', isinResult);
      }

      let tosResult = validateTotalOutstandingShares(
        parseLargeInt(formData.totalOutstandingShares)
      );
      if (tosResult !== true) {
        return new ValidationResult(false, 'totalOutstandingShares', tosResult);
      }

      let recordDateResult = validateRecordDate(formData.recordDate);
      if (recordDateResult !== true) {
        return new ValidationResult(false, 'recordDate', recordDateResult);
      }

      let thresholdResult;
      if (formData.thresholdOption === 'percentage') {
        thresholdResult = validateThresholdPct(
          parsePercentageNumber(formData.thresholdPct)
        );
      } else {
        thresholdResult = validateThresholdQty(
          parseLargeInt(formData.thresholdQty)
        );
      }
      if (thresholdResult !== true) {
        return new ValidationResult(false, 'threshold', thresholdResult);
      }

      let startDateResult = validateStartDate(formData.startDate);
      if (startDateResult !== true) {
        return new ValidationResult(false, 'startDate', startDateResult);
      }

      let deadlineResult;
      if (formData.reportDeadlineDateOption === 'custom' ||
          formData.disclosureDeadlineDateOption === 'custom') {
        deadlineResult = validateDeadlineDate(
          formData.disclosureDeadlineDate,
          formData.reportDeadlineDate,
          {
            startDate: formData.startDate,
            recordDate: formData.recordDate,
          },
          role
        );
      } else {
        deadlineResult = true;
      }
      if (deadlineResult !== true) {
        return new ValidationResult(
          false,
          'reportDeadlineDate',
          deadlineResult
        );
      }

      return new ValidationResult(true);
    }

    return {
      validateIssuerId,
      validateIsin,
      validateTotalOutstandingShares,
      validateRecordDate,
      validateThresholdPct,
      validateThresholdQty,
      validateStartDate,
      validateDeadlineDate,

      validateBaseCreationForm,
      validateIssuerCreationForm,
      validateAgentCreationForm
    };
  },
];
