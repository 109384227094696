/* eslint-disable indent */
import { LitElement, html, css } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class SimpleTile extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      title: { type: String },
    };
  }

  static get scopedElements() {
    return {
      'aab-button': customElements.get('aab-button'),
      'aab-tile': customElements.get('aab-tile'),
      'aab-typography': customElements.get('aab-typography'),
    };
  }

  static get styles() {
    return css`
      main {
        padding: 32px;
      }
    `;
  }

  render() {
    return html` <aab-tile>
      <main>
        <div style="margin-bottom: 2rem">
          <aab-typography>
            <h3>${this.title}</h3>
          </aab-typography>
        </div>

        <aab-typography>
          <slot></slot>
        </aab-typography>
      </main>
    </aab-tile>`;
  }
}

customElements.define('agent-um-simple-tile', SimpleTile);
