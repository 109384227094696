import {BASE_URL} from '../../constants';

import './pagination-control.scss';

const Ctrl = [
  function () {
    this.$onInit = () => {
      this.numberOfVisiblePages = 10;
      this._centerPageSelection(this.current);
    };

    /**
     * Creates the sequence of pagination button
     * @returns array of pagination buttons
     */
    this.totalAsArray = () => {
      const result = [];

      for (let i = 0; i < this.total; i++) {
        result.push(i + 1);
      }
      return result;
    };

    /**
     * Handle page click
     *
     * @param page page to navigate to
     */
    this.handleChange = (page) => {
      this.onChange({ page: page });
      this._centerPageSelection(page);
    };

    /**
     * Scroll to next set of pagination buttons
     */
    this.nextPages = () => {
      this.startBy = this._correctViewWindow(this.startBy + this.numberOfVisiblePages);
    };

    /**
     * Scroll to the previous set of pagination buttons
     */
    this.previousPages = () => {
      this.startBy = this._correctViewWindow(this.startBy - this.numberOfVisiblePages);
    };

    /**
     * Set the selected page in the middle of the pagination button sequence
     *
     * @param page
     * @private
     */
    this._centerPageSelection = (page) => {
      this.startBy = this._correctViewWindow(page - Math.floor(this.numberOfVisiblePages / 2));
    };

    /**
     * Correct the view port of the pagination items.
     *
     * @param firstItem first item in the view port
     * @returns {number|*} the correct first item if necessary
     * @private
     */
    this._correctViewWindow = (firstItem) => {
      if (this.total - firstItem < this.numberOfVisiblePages) {
        // if first item is too close to the end then take the
        // last item minus the allowed number of visible items
        let corrected = this.total - this.numberOfVisiblePages;
        // On initial load this.total can be 0 or 1 making the corrected
        // negative. If so then correct it to first item in the list
        if (corrected < 0) {
          corrected = 0;
        }
        return corrected;
      } else if (firstItem < 0) {
        // If first item is to close the beginning, then set it to 0
        return 0;
      } else {
        return firstItem;
      }

    };
  },
];

export default {
  bindings: {
    total: '<',
    current: '<',
    onChange: '&',
  },
  controller: Ctrl,
  templateUrl: `${BASE_URL}/components/pagination-control/pagination-control.template.html`,
};
