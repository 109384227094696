import './AgentView';
import { fetchAgent } from '@/api/v1/agent';

export default {
  controller: [
    '$state',
    '$http',
    '$scope',
    function ($state, $http, $scope) {
      this.$onInit = () => {
        this.fetchAgent().then(() => {
          $scope.$apply();
        });
      };

      this.fetchAgent = async () => {
        this.agent = await fetchAgent($http);
      };

      this.handleEditClick = () => {
        $state.go('agentUserManagement.agent.editAgent');
      };

      this.handleViewUsersClick = () => {
        $state.go('agentUserManagement.agent.users');
      };
    },
  ],
  template: `
    <div class="my-3">
      <div class="p-5" ng-if="!$ctrl.agent">
        <cbp-loading-spinner></cbp-loading-spinner>
      </div>
      <agent-um-agent-view 
        ng-if="$ctrl.agent"
        ng-prop-agent="$ctrl.agent"
        ng-on-edit-click="$ctrl.handleEditClick()"
        ng-on-view-users-click="$ctrl.handleViewUsersClick()"
      ></agent-um-agent-view>
    </div>
  `,
};
