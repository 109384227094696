const { BASE_URL } = require('../constants');

require('./em-icon.scss');

const Ctrl = [
  function () {
    this.$onInit = () => {
      if (!this.size) {
        this.size = 3;
      }
      if (!this.icon) {
        throw new Error();
      }
    };

    this.iconClassMap = {
      pencil: 'em-icon-tools-pencil',
      search: 'em-icon-tools-search',
      company: 'em-icon-others-company',
      profile: 'em-icon-profile-profile',
      selfservice: 'em-icon-tools-selfservice',
      document: 'em-icon-form-document-ok',
      'document-add': 'em-icon-form-document-add',
      settings: 'em-icon-tools-settings',
      employee: 'em-icon-profile-employee',
      money: 'em-icon-money-money',
      calendar: 'em-icon-datetime-calender',
      download: 'sy-arrow-download',

      // Notification icons
      info: 'sy-notification-info',
      positive: 'sy-notification-positive',
      negative: 'sy-notification-negative',
      warning: 'sy-notification-warning',
      privacy: 'sy-notification-privacy',

      // Product icons
      'pr-profiles-family': 'pr-profiles-family',
      'pr-paper-marker': 'pr-paper-marker',
      'pr-documents-search': 'pr-documents-search',
      'pr-books-instructions': 'pr-books-instructions',
      'pr-investments-securities': 'pr-investments-securities',
    };
  },
];

/**
 * Valid icon names can be inferred from `iconClassMap`
 */
module.exports = {
  bindings: {
    icon: '@',
    size: '@',
  },
  controller: Ctrl,
  templateUrl: `${BASE_URL}/em-icon/em-icon.template.html`,
};
