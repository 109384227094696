let hasGeoChartLoaded = false;
let isGeoChartLoading = false;
let hasScriptLoaded = false;
let isScriptLoading = false;

const POLL_INTERVAL_TIME = 500;

const _waitForScriptLoaded = () =>
  new Promise((resolve) => {
    let interval;
    const poll = () => {
      if (window.google?.charts) {
        hasScriptLoaded = true;
        window.clearInterval(interval);
        resolve();
      }
    };
    interval = window.setInterval(poll, POLL_INTERVAL_TIME);
  });

const _waitForOnLoadCallback = () =>
  new Promise((resolve) => {
    let interval;
    const poll = () => {
      if (hasGeoChartLoaded) {
        window.clearInterval(interval);
        resolve();
      }
    };
    interval = window.setInterval(poll, POLL_INTERVAL_TIME);
  });

const _handleGeoChartLoaded = () => {
  hasGeoChartLoaded = true;
};

async function _loadScript() {
  if (hasScriptLoaded) {
    return;
  } else if (isScriptLoading) {
    // wait for script to have loaded
    await _waitForScriptLoaded();
  } else {
    isScriptLoading = true;
    const script = document.createElement('script');
    script.src = 'https://www.gstatic.com/charts/loader.js';
    document.head.appendChild(script);
    await _waitForScriptLoaded();
  }
}

async function _loadGeoChart() {
  if (hasGeoChartLoaded) {
    return;
  } else if (isGeoChartLoading) {
    // wait for it to have loaded
    await _waitForOnLoadCallback();
  } else {
    // start loading the geoChart
    window.google.charts.load('current', {
      packages: ['geochart'],
    });
    isGeoChartLoading = true;
    window.google.charts.setOnLoadCallback(_handleGeoChartLoaded);
    // wait for it to have loaded
    await _waitForOnLoadCallback();
  }
}

export async function loadGoogleGeoCharts() {
  await _loadScript();
  await _loadGeoChart();
}
