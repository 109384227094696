import './sid-response-item.scss';

export default {
  bindings: {
    sidResponseItem: '<',
  },
  controller: [function () {}],
  templateUrl:
    '/features/modules/shareholders-id/modules/common/components/sid-response-item/sid-response-item.template.html',
};
