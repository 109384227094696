import downloadFile from '@/lib/downloadFile';

export default [
  '$http',
  function ($http) {
    async function downloadAsync(href, postData) {
      await new Promise(async (resolve, reject) => {
        const response = await $http.post(href, postData, {
          headers: {
            Accept: '*/*',
          },
        });
        const location = response.headers('Location');
        const interval = setInterval(async () => {
          const intervalResponse = await $http.get(location, {
            headers: {
              Accept: '*/*',
            },
          });
          if (intervalResponse.status > 300) {
            clearInterval(interval);
            reject();
          }
          if (intervalResponse.data === 'DONE') {
            clearInterval(interval);
            await download(intervalResponse.headers('Location'));
            resolve();
          }
        }, 5000);
      });
    }

    async function download(href) {
      const response = await $http.get(href, {
        responseType: 'arraybuffer',
        headers: {
          Accept: '*/*',
        },
      });
      const contentDispositionHeader = response.headers('Content-Disposition');
      // Depending on the environment + configuration, the Content-Disposition
      // might not be exposed.
      const filename = contentDispositionHeader
        ? getFilenameFromContentDispositionHeader(contentDispositionHeader)
        : generateFilenameForSidReport();

      downloadFile(response.data, filename);
    }

    function getFilenameFromContentDispositionHeader(cd) {
      // cd = 'inline; filename="foo"'
      const filenameSection = cd.split(';')[1];
      const filename = filenameSection.split('=')[1];
      return filename.replace(/"/g, '');
    }

    /**
     * For when, for whatever reason, the Content-Disposition header can't be
     * read. (Most likely because the request is made cross-origin and the
     * 'Content-Disposition' header is not present in the 'Access-Control-Expose-Headers'
     * header
     */
    function generateFilenameForSidReport() {
      const today = new Date();
      return `${today.getFullYear()}${(today.getMonth() + 1)
        .toString()
        .padStart(2, '0')}${today
        .getDate()
        .toString()
        .padStart(2, '0')}-SidReport.xlsx`;
    }

    return { downloadRequest: downloadAsync, download };
  },
];
