export function isIe11() {
  if (!!window.MSInputMethodContext && !!document.documentMode) {
    return true;
  }

  try {
    if (
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      navigator.appVersion.indexOf('Trident/') > -1
    ) {
      return true;
    }
  } catch {
    return false;
  }

  return false;
}
