const { PRODUCTION_HOSTNAME, DEMO_HOSTNAME } = require('./constants');

module.exports = [function () {

  /**
   * A function to check whether we are running on production or not.
   *
   * Can be used to disable some features we want to 'demo' in production.
   */
  function isProduction() {
    const {host} = window.location;
    return host.indexOf(PRODUCTION_HOSTNAME) > -1;
  }

  function isDemo() {
    const {host} = window.location;
    return host.indexOf(DEMO_HOSTNAME) > -1;
  }

  return {
    isProduction,
    isDemo
  };
}];