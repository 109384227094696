import './cbp-combobox.scss';
import { sy_shapes_close_large } from '@aab/sc-aab-icon-set';

export default {
  bindings: {
    name: '@',
    placeholder: '@',
    textValue: '<',
    options: '<',
    showClearButton: '<',
    onTextChange: '&',
    onOptionSelected: '&',
    onClearClick: '&',
  },
  controller: [
    '$element',
    function ($element) {
      this.$onInit = () => {
        this.focusedOptionIndex = -1;
        this.element = $element[0];
        this.clearIconSvg = sy_shapes_close_large;
        this.clearIconColor = '#00716B'; // default g300 color
        this.element.addEventListener('keydown', this.handleKeydown.bind(this));
      };

      this.$onDestroy = () => {
        this.element.removeEventListener(
          'keydown',
          this.handleKeydown.bind(this)
        );
      };

      this.handleKeydown = (event) => {
        if (event.key === 'ArrowDown') {
          event.preventDefault();
          this.handleDownKey();
        }
        if (event.key === 'ArrowUp') {
          event.preventDefault();
          this.handleUpKey();
        }
        if (event.key === 'Enter') {
          event.preventDefault();
          this.handleOptionSelected(this.options[this.focusedOptionIndex]);
        }
      };

      this.handleDownKey = () => {
        const options = this.element.querySelectorAll('.cbp-combobox__option');
        if (this.focusedOptionIndex + 1 < this.options.length) {
          this.focusedOptionIndex += 1;
          options[this.focusedOptionIndex].focus();
        } else {
          this.focusedOptionIndex = -1;
          this.element.querySelector('em-input input').focus();
        }
      };

      this.handleUpKey = () => {
        const options = this.element.querySelectorAll('.cbp-combobox__option');
        if (this.focusedOptionIndex - 1 > -1) {
          this.focusedOptionIndex -= 1;
          options[this.focusedOptionIndex].focus();
        } else if (this.focusedOptionIndex === -1) {
          this.focusedOptionIndex = this.options.length - 1;
          options[this.focusedOptionIndex].focus();
        } else {
          this.focusedOptionIndex = -1;
          this.element.querySelector('em-input input').focus();
        }
      };

      this.handleMouseover = (index) => {
        this.focusedOptionIndex = index;
        const options = this.element.querySelectorAll('.cbp-combobox__option');
        options[this.focusedOptionIndex].focus();
      };

      this.handleTextChange = (name, value) => {
        this.focusedOptionIndex = -1;
        this.onTextChange({ text: value });
      };

      this.handleOptionSelected = (option) => {
        this.focusedOptionIndex = -1;
        this.onOptionSelected({ option });
      };
    },
  ],
  template: `
  <div class="cbp-combobox">
    <em-input 
        id=""
        name="{{ $ctrl.name }}" 
        value="$ctrl.textValue" 
        on-change="$ctrl.handleTextChange(name, value)" 
        placeholder="{{ $ctrl.placeholder }}"
    ></em-input>

    <aab-icon
      ng-show="$ctrl.showClearButton"
      class="cbp-combobox__clear-button"
      ng-prop-svg="$ctrl.clearIconSvg"
      size="3"
      ng-prop-color="$ctrl.clearIconColor"
      ng-mouseover="$ctrl.clearIconColor = '#37aba1'"
      ng-mouseout="$ctrl.clearIconColor = '#00716B'"
      ng-click="$ctrl.onClearClick()"
    ></aab-icon>

    <div class="cbp-combobox__dropdown">
      <div class="cbp-combobox__option"
        tabindex="-1"
        ng-repeat="option in $ctrl.options"
        ng-click="$ctrl.handleOptionSelected(option)"
        ng-mouseover="$ctrl.handleMouseover($index)"
      >
        {{ option.name }}
      </div>
    </div>
  </div>
  `,
};
