/* eslint-disable indent */
import { LitElement, html } from 'lit-element';

class StatusMessageRenderer extends LitElement {
  static get properties() {
    return {
      status: { type: String },
      statusMessage: { type: Array },
    };
  }

  createRenderRoot() {
    return this;
  }

  render() {
    if (!['success', 'error'].includes(this.status)) {
      return null;
    }
    return html`
      <div class="mt-4">
        <aab-notification
          type="${this.status === 'success' ? 'positive' : 'negative'}"
        >
          ${this.status === 'success'
            ? html`<p>${this.statusMessage}</p>`
            : html`<div>
                <p>The following errors have occurred:</p>

                <ul style="list-style-type: disc">
                  ${this.statusMessage.map(
                    (message) => html`<li class="d-block">
                      - ${message}
                    </li>`
                  )}
                </ul>
              </div>`}
        </aab-notification>
      </div>
    `;
  }
}

customElements.define(
  'agent-um-status-message-renderer',
  StatusMessageRenderer
);
