import './sid-response-item-type-tag.scss';

export default {
  bindings: {
    type: '@',
  },
  controller: [
    function () {
      this.iconMap = {
        legal: 'pr-investments-securities',
        natural: 'pr-profiles-family',
      };
    },
  ],
  templateUrl:
    '/features/modules/shareholders-id/modules/common/components/sid-response-item-type-tag/sid-response-item-type-tag.template.html',
};
