const { BASE_URL } = require('../constants');

require('./i-text.scss');

module.exports = {
  bindings: {
    message: '@',
    /** `htmlMessage` takes precedence over `message`. I.e., if `htmlMessage` is set, `message` is ignored */
    htmlMessage: '@',
    direction: '@',

    hoverMode: '<'
  },

  controller: [function() {
    const VALID_DIRECTIONS = ['left', 'right', 'top', 'bottom'];
    this.$onInit = () => {
      if (VALID_DIRECTIONS.indexOf(this.direction) == -1) {
        this.direction = 'top';
      }
    };

    this.handleClick = () => {
      if (!this.hoverMode) {
        this.showText = true;
      }
    };
  }],

  templateUrl: `${BASE_URL}/i-text/i-text.template.html`
};