import './cbp-accordion.scss';

export default {
  bindings: {
    // whether the accordion is open by default
    open: '<',
  },
  transclude: {
    header: 'cbpAccordionHeader',
    body: 'cbpAccordionBody',
  },
  controller: [
    function () {
      this.$onInit = () => {
        this.isOpen = false;
      };
    },
  ],
  templateUrl:
    '/features/modules/cbp-ui/components/cbp-accordion/cbp-accordion.template.html',
};
