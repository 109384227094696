import './IssuerView';
import { fetchIssuer } from '@/api/v1/userGroups';

export default {
  controller: [
    '$http',
    '$scope',
    '$stateParams',
    function ($http, $scope, $stateParams) {
      this.$onInit = () => {
        this.fetchIssuer().then(() => {
          $scope.$apply();
        });
      };

      this.fetchIssuer = async () => {
        this.issuer = await fetchIssuer($http, $stateParams.issuerId);
      };
    },
  ],
  template: `
    <div class="agUserManagementIssuer my-3">
      <div class="py-5" ng-if="!$ctrl.issuer">
        <cbp-loading-spinner></cbp-loading-spinner>
      </div>
      <agent-um-issuer-view ng-if="$ctrl.issuer" ng-prop-issuer="$ctrl.issuer"></agent-um-issuer-view>
    </div>
  `,
};
