export default {
  bindings: {
    address: '<',
  },
  template: `
  <div ng-if="$ctrl.address">
    <em-typography variant="label">Address</em-typography>
    <em-typography ng-if="!!$ctrl.address.addressLines" ng-repeat="line in $ctrl.address.addressLines">
      {{ line }}
    </em-typography>
    <em-typography ng-if="!!$ctrl.address.streetName">
      {{ $ctrl.address.streetName }} {{ $ctrl.address.buildingNumber }}
    </em-typography>
    <em-typography ng-if="!!$ctrl.address.postalBox">
      P.O. box: {{ $ctrl.address.postalBox }}
    </em-typography>
    <em-typography ng-if="!!$ctrl.address.postalCode || !!$ctrl.address.townName">
      {{ $ctrl.address.postalCode }} {{ $ctrl.address.townName }}
    </em-typography>
    <em-typography ng-if="!!$ctrl.address.countrySubDivision || !!$ctrl.address.country">
      {{ $ctrl.address.countrySubDivision }} {{ $ctrl.address.country }}
    </em-typography>
  </div>
  `,
};
