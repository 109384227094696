import { LitElement, html, css } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';
class EditAgent extends ScopedElementsMixin(LitElement) {
  constructor() {
    super();

    this.agent = {};
    this._agent = {};
    this.countryOptions = [];
  }

  static get properties() {
    return {
      agent: { type: Object },
      countryOptions: { type: Array },
      agentFormData: { type: Object, attribute: false },
    };
  }

  static get scopedElements() {
    return {
      'aab-button': customElements.get('aab-button'),
      'aab-input': customElements.get('aab-input'),
      'aab-typography': customElements.get('aab-typography'),
      'aab-select': customElements.get('aab-select'),
      'agent-um-simple-tile': customElements.get('agent-um-simple-tile'),
    };
  }

  static get styles() {
    return css`
      .button-container {
        margin-top: 3rem;
      }

      aab-input,
      aab-select {
        display: block;
        margin-bottom: 1rem;
      }

      .image-container {
        width: 240px;
      }
      .image-container img {
        width: 100%;
      }
    `;
  }

  set agent(val) {
    let oldVal = this._agent;
    this._agent = val;
    this.agentFormData = val;
    this.requestUpdate('agent', oldVal);
  }
  get agent() {
    return this._agent;
  }

  _handleSaveClicked() {
    this.dispatchEvent(
      new CustomEvent('save-click', {
        detail: { ...this.agentFormData },
      })
    );
  }

  render() {
    return html`
      <agent-um-simple-tile title="Edit agent: ${this.agent?.username}">
        <aab-input>
          <label slot="label">Group name</label>
          <input
            value="${this.agentFormData?.username}"
            @input="${(ev) => {
              this.agentFormData.username = ev.target.value;
            }}"
          />
        </aab-input>

        <aab-input>
          <label slot="label">Phone number</label>
          <input
            value="${this.agentFormData?.personalPhone}"
            @input="${(ev) => {
              this.agentFormData.personalPhone = ev.target.value;
            }}"
          />
        </aab-input>

        <aab-input>
          <label slot="label">Email</label>
          <input
            value="${this.agentFormData?.emailAddress}"
            @input="${(ev) => {
              this.agentFormData.emailAddress = ev.target.value;
            }}"
          />
        </aab-input>

        <aab-input>
          <label slot="label">City</label>
          <input
            value="${this.agentFormData?.city}"
            @input="${(ev) => {
              this.agentFormData.city = ev.target.value;
            }}"
          />
        </aab-input>

        <aab-select
          aab-select-label="Country"
          .options="${this.countryOptions}"
          .value="${this.agentFormData?.country}"
          @aab-select-value-change="${(ev) => {
            this.agentFormData = {
              ...this.agentFormData,
              country: ev.detail.value,
            };
          }}"
        ></aab-select>

        <!-- Logo -->
        <aab-typography>
          <label>Logo</label>
        </aab-typography>
        <div class="image-container">
          <img
            class="logo"
            src="data:image/png;base64,${this.agent.userGroupVO.logoBaseStr}"
          />
        </div>

        <div class="button-container">
          <aab-button style-type="primary">
            <button @click="${this._handleSaveClicked}">Save</button>
          </aab-button>
        </div>

        <div>
          <slot></slot>
        </div>
      </agent-um-simple-tile>
    `;
  }
}

customElements.define('agent-um-edit-agent', EditAgent);
