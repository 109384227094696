import { BASE_URL } from '../../constants';

import './action-button.scss';

export default {
  bindings: {
    icon: '@',
    tag: '@',
    href: '@',
    download: '@', // Only if tag === 'a'
    disabled: '<',
  },
  transclude: true,
  controller: [
    function () {
      this.iconClassName = () => {
        if (!this.icon) {
          return;
        }
        if (this.icon.indexOf('em-icon') > -1) {
          return this.icon;
        }
        return `em-icon-${this.icon}`;
      };
    },
  ],
  templateUrl: `${BASE_URL}/components/action-button/action-button.template.html`,
};
