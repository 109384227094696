export function userFilter(user, searchString) {
  const lowerSearchString = searchString.toLowerCase();
  const { firstName, lastName } = user;
  const fullName = `${firstName} ${lastName}`;
  return (
    firstName?.toLowerCase().includes(lowerSearchString) ||
    lastName?.toLowerCase().includes(lowerSearchString) ||
    fullName.toLowerCase().includes(lowerSearchString)
  );
}

export function userGroupFilter(userGroup, searchString) {
  return userGroup.groupName.toLowerCase().includes(searchString.toLowerCase());
}
