import { BASE_URL } from '../../constants';

import './cbp-dialog.scss';

export default {
  bindings: {
    header: '@',
    show: '<',
    onClose: '&',
    onConfirm: '&'
  },
  transclude: true,
  templateUrl: `${BASE_URL}/components/cbp-dialog/cbp-dialog.template.html`
};
