import { LitElement, html, css } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class AddIssuerView extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      countryOptions: { type: Array },
      issuerData: { type: Object, attribute: false },
    };
  }

  static get scopedElements() {
    return {
      'issuer-input-form': customElements.get('agent-um-issuer-input-form'),
      'agent-um-simple-tile': customElements.get('agent-um-simple-tile'),
    };
  }

  static get styles() {
    return css`
      aab-input,
      aab-select {
        display: block;
        margin-bottom: 1rem;
      }
    `;
  }

  constructor() {
    super();
    this.issuerData = {};
  }

  render() {
    return html`
      <agent-um-simple-tile title="Add issuer">
        <issuer-input-form
          .issuer="${this.issuerData}"
          .countryOptions=${this.countryOptions}
          @issuer-change=${(ev) => {
            this.issuerData = { ...ev.detail };
          }}
          @save-click="${(ev) =>
            this.dispatchEvent(
              new CustomEvent('save-click', { detail: { ...ev.detail } })
            )}"
        ></issuer-input-form>

        <slot></slot>
      </agent-um-simple-tile>
    `;
  }
}

customElements.define('agent-um-add-issuer-view', AddIssuerView);
