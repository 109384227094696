/* eslint-disable indent */
import { LitElement, html } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class UserDetails extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      user: { type: Object },
    };
  }

  static get scopedElements() {
    return {
      'aab-typography': customElements.get('aab-typography'),
    };
  }

  render() {
    return html` <aab-typography
      ><label>Title</label>
      <p>${this.user.title || '-'}</p>

      <label>First name</label>
      <p>${this.user.firstName || '-'}</p>

      <label>Last name</label>
      <p>${this.user.lastName || '-'}</p>

      <label>Office phone</label>
      <p>${this.user.officePhoneNo || '-'}</p>

      <label>Phone number</label>
      <p>${this.user.personalPhoneNo || '-'}</p>

      <label>Email</label>
      <p>${this.user.email}</p>

      <label>Opt out for email related to general meetings?</label>
      <p>${this.user.groupMail === 'Y' ? 'Yes' : 'No'}</p>

      <label>Admin</label>
      <p>${this.user.admin === 'Y' ? 'Yes' : 'No'}</p>
      <aab-typography></aab-typography
    ></aab-typography>`;
  }
}

customElements.define('agent-um-user-details', UserDetails);
