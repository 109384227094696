import { LitElement, html, css } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';
import styles from './AabTileButton.scss';

export default class AabTile extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      title: { type: String, attribute: 'aab-tile-button-title' },
      iconSvg: { type: String, attribute: 'icon-svg' },

      fullWidth: { type: Boolean }
    };
  }

  static get scopedElements() {
    return {
      'aab-tile': customElements.get('aab-tile'),
      'aab-icon': customElements.get('aab-icon'),
      'aab-typography': customElements.get('aab-typography'),
    };
  }

  static get styles() {
    return [styles];
  }

  render() {
    return html`
      <button class="root ${this.fullWidth ? 'full-width' : ''}">
        <aab-tile>
          <div class="tile-content">
            <aab-icon class="icon" .svg="${this.iconSvg}" size="7"></aab-icon>

            <aab-typography class="text"><label>${this.title}</label></aab-typography>
          </div>
        </aab-tile>
      </button>
    `;
  }
}
