/* eslint-disable indent */
import { LitElement, html, css } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';
import './UserInputForm';

class AgentUserInputForm extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      user: { type: Object },
    };
  }

  static get scopedElements() {
    return {
      'aab-button': customElements.get('aab-button'),
      'aab-checkbox': customElements.get('aab-checkbox'),
      'aab-typography': customElements.get('agent-um-user-input-form'),

      'user-input-form': customElements.get('agent-um-user-input-form'),
    };
  }

  static get styles() {
    return css`
      aab-input,
      aab-select {
        display: block;
        margin-bottom: 1rem;
      }
    `;
  }

  render() {
    return html`
      <form>
        <user-input-form
          .user=${this.user || {}}
          @user-change=${(ev) => {
            this.user = ev.detail;
          }}
        ></user-input-form>

        <!-- Checkbox: admin-->

        <aab-button style-type="primary">
          <button
            @click="${(ev) => {
              ev.preventDefault();
              this.dispatchEvent(
                new CustomEvent('agent-user-form-submit', {
                  detail: { ...this.user },
                })
              );
            }}"
          >
            Save
          </button>
        </aab-button>
      </form>
    `;
  }
}

customElements.define('agent-um-agent-user-input-form', AgentUserInputForm);
