import 'Modules/shareholders-id/modules/common/sid-common.module';

const sidAgentModule = angular.module('sidAgent', ['ui.router', 'sidCommon']);

/**
 * Services
 */
import issuerRepository from './model/repositories/issuer-repository.service';
import shareClassRepository from './model/repositories/share-class-repository.service';
import sidCountryDataRepository from './model/repositories/sid-country-data-repository.service';
import sidRequestRepository from './model/repositories/sid-request-repository.service';
import sidReportShareholdersRepository from './model/repositories/sid-report-shareholders-repository.service';
import sidResponseItemRepository from './model/repositories/sid-response-item-repository.service';
import sidResponseForwardRepository from './model/repositories/sid-response-forward-repository.service';
import sidShareholderBoDetailsRepository from './model/repositories/shareholder-bo-details.service';

import sidRequestValidator from './model/validators/sid-request-validator.service';

sidAgentModule.service('AG_IssuerRepository', issuerRepository);
sidAgentModule.service('AG_ShareClassRepository', shareClassRepository);
sidAgentModule.service('AG_SidCountryDataRepository', sidCountryDataRepository);
sidAgentModule.service('AG_SidRequestRepository', sidRequestRepository);
sidAgentModule.service(
  'AG_SidReportShareholdersRepository',
  sidReportShareholdersRepository
);
sidAgentModule.service(
  'AG_SidResponseItemRepository',
  sidResponseItemRepository
);
sidAgentModule.service(
  'AG_SidResponseForwardRepository',
  sidResponseForwardRepository
);
sidAgentModule.service(
  'AG_SidShareholderBoDetails',
  sidShareholderBoDetailsRepository
);

sidAgentModule.service('AG_SidRequestValidator', sidRequestValidator);

/**
 * Views
 */
import sidAgentLandingPage from './views/landing-page/sid-agent-landing-page.component';
import sidAgentCreateRequest from './views/create-request/sid-agent-create-request.component';
import sidAgentRequestDetails from './views/sid-request-details/sid-request-details.component';
import sidAgentRequestDetailsOverview from './views/sid-request-details/overview/sid-request-details-overview.component';
import sidAgentRequestDetailsOverviewEdit from './views/sid-request-details/overview-edit/sid-request-details-overview-edit.component';
import sidAgentRequestDetailsResults from './views/sid-request-details/results/sid-request-details-results.component';
import sidAgentResultsShareholderReportsOverview from './views/sid-request-details/results/shareholder-reports-overview/shareholder-reports-overview.component';
import sidAgentResultsShareholderReportsNomi from './views/sid-request-details/results/shareholder-reports-nomi/shareholder-reports-nomi.component';
import sidAgentResultsShareholderDetails from './views/sid-request-details/results/shareholder-details/shareholder-details.component';

sidAgentModule.component({
  sidAgentLandingPage,
  sidAgentCreateRequest,
  sidAgentRequestDetails,
  sidAgentRequestDetailsOverview,
  sidAgentRequestDetailsOverviewEdit,
  sidAgentRequestDetailsResults,
  sidAgentResultsShareholderReportsOverview,
  sidAgentResultsShareholderReportsNomi,
  sidAgentResultsShareholderDetails,
});

/**
 * Components
 */
import agentSidRequestTile from './components/sid-request-tile/sid-request-tile.component';
import agentSidResponseShareholderReportsFilter from './components/sid-response-shareholder-reports-filter/sid-response-shareholder-reports-filter.component';
import agentSidRequestTimeline from './components/sid-request-timeline/sid-request-timeline.component';
import agentSidRequestReport from './components/sid-request-report/sid-request-report.component';
import agentSidRequestReportShareholderTile from './components/sid-request-report-shareholder-tile/sid-request-report-shareholder-tile.component';

sidAgentModule.component({
  agentSidRequestTile,
  agentSidResponseShareholderReportsFilter,
  agentSidRequestTimeline,
  agentSidRequestReport,
  agentSidRequestReportShareholderTile,
});

/**
 * Routes
 */
import routes from './routes/index';

sidAgentModule.config(routes);

export default sidAgentModule;
