/* eslint-disable indent */
import { LitElement, html, css } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class IssuerInputForm extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      issuer: { type: Object },
      countryOptions: { type: Array },
    };
  }

  static get scopedElements() {
    return {
      'aab-button': customElements.get('aab-button'),
      'aab-checkbox': customElements.get('aab-checkbox'),
      'aab-input': customElements.get('aab-input'),
      'aab-select': customElements.get('aab-select'),
      'aab-tile': customElements.get('aab-tile'),
      'aab-typography': customElements.get('aab-typography'),
    };
  }

  static get styles() {
    return css`
      aab-input,
      aab-select {
        display: block;
        margin-bottom: 1rem;
      }
    `;
  }

  _updateIssuer() {
    this.dispatchEvent(
      new CustomEvent('issuer-change', { detail: { ...this.issuer } })
    );
  }

  _handleSaveClick(ev) {
    ev.preventDefault();
    this.dispatchEvent(
      new CustomEvent('save-click', {
        detail: { ...this.issuer },
      })
    );
  }

  render() {
    return html`
      <form>
        <aab-input>
          <label slot="label">Group name</label>
          <input
            type="text"
            .value="${this.issuer?.groupName || ''}"
            @input="${(ev) => {
              this.issuer.groupName = ev.target.value;
              this._updateIssuer();
            }}}"
          />
        </aab-input>

        <aab-input>
          <label slot="label">Email</label>
          <input
            type="text"
            .value="${this.issuer?.groupEmail || ''}"
            @input="${(ev) => {
              this.issuer.groupEmail = ev.target.value;
              this._updateIssuer();
            }}}"
          />
        </aab-input>

        <aab-input>
          <label slot="label">City</label>
          <input
            type="text"
            .value="${this.issuer?.city || ''}"
            @input="${(ev) => {
              this.issuer.city = ev.target.value;
              this._updateIssuer();
            }}}"
          />
        </aab-input>

        <!-- Country -->
        <aab-select
          aab-select-label="Country"
          .value="${this.issuer?.country}"
          .options=${this.countryOptions}
          @aab-select-value-change="${(ev) => {
            this.issuer.country = ev.detail.value;
            this._updateIssuer();
          }}"
        ></aab-select>

        <!-- Logo -->

        <!-- Checkbox -->

        <aab-button style-type="primary">
          <button @click="${this._handleSaveClick}">Save</button>
        </aab-button>
      </form>
    `;
  }
}

customElements.define('agent-um-issuer-input-form', IssuerInputForm);
