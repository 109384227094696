export default [
  'SidRequestValidator',
  'ValidationResult',
  function (SRV, ValidationResult) {
    /**
     * See issuer/_services/sid-request-validator.service.js for more info. Basically the same except for `issuerId`.
     * Could probably be refactored a bit better.
     */
    function validateCreationForm(sidReqData) {
      let issuerIdResult = SRV.validateIssuerId(sidReqData.issuerId);
      if (issuerIdResult !== true) {
        return new ValidationResult(false, 'issuerId', issuerIdResult);
      }
      return SRV.validateAgentCreationForm(sidReqData);
    }

    return {
      validateCreationForm,
    };
  },
];
