import { LitElement, html } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';
import {
  sy_arrow_chevron_right as chevronRight,
  sy_arrow_arrow_right as arrowRight,
  sy_arrow_download as download,
} from '@aab/sc-aab-icon-set';
import styles from './AabLink.scss';

const iconMap = {
  chevronRight,
  arrowRight,
  download,
};

export default class AabLink extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      icon: { type: String },
      disabled: { type: Boolean, attribute: 'aab-link-disabled' },
      href: { type: String, attribute: 'aab-link-href' },
      uiSref: { type: String, attribute: 'aab-link-ui-sref' },
    };
  }

  constructor() {
    super();
    this.icon = 'chevronRight';
  }

  static get styles() {
    return styles;
  }

  static get scopedElements() {
    return {
      'aab-icon': customElements.get('aab-icon'),
    };
  }

  _handleClick() {
    if (!this.disabled) {
      this.dispatchEvent(new CustomEvent('aab-link-click'));
    }
  }

  render() {
    const icon =
      this.icon && this.icon !== 'none'
        ? html`<aab-icon
            class="icon ${this.icon}"
            size="${this.icon === 'download' ? 2 : 3}"
            svg="${iconMap[this.icon]}"
          ></aab-icon>`
        : '';

    return html`
      <a
        class="root ${this.disabled ? 'disabled' : ''}"
        @click="${this._handleClick}"
      >
        <slot></slot>

        ${icon}
      </a>
    `;
  }
}
