import { SID_COMMON_BASE_URL } from 'Modules/shareholders-id/constants';

import './sid-request-report-nomi-tile.scss';

const Ctrl = [
  function () {
    this.$onInit = () => {
      this.status = this.nomi.fullFilledDateTime ? 'COMPLETED' : 'IN_PROGRESS';
      this.fullFilledDate = this.nomi.fullFilledDateTime
        ? this.nomi.fullFilledDateTime
        : '-';
    };
  },
];

export default {
  bindings: {
    nomi: '<',
  },
  controller: Ctrl,
  templateUrl: `${SID_COMMON_BASE_URL}/components/sid-request-report-nomi-tile/sid-request-report-nomi-tile.template.html`,
};
