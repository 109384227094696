export default {
  bindings: {
    legalPerson: '<',
  },
  controller: [
    function () {
      /**
       * Identification 'filters'
       */
      const identificationTypeDisplayNameMap = {
        LEI: 'LEI',
        BIC: 'BIC',
        NRN: 'National reg. number',
        PROP: 'Proprietary',
        CLIENT: 'Client',
        NONE: 'Unknown type',
      };

      const identificationTypePropMap = {
        LEI: 'lei',
        BIC: 'bic',
        NRN: 'nationalRegistrationNumber',
        PROP: 'proprietaryIdentification',
        CLIENT: 'clientIdentification',
        NONE: '-',
      };

      this.identificationTypeDisplayName = () => {
        return identificationTypeDisplayNameMap[
          this.legalPerson.identificationType
        ];
      };

      this.identificationValue = () => {
        return this.legalPerson[
          identificationTypePropMap[this.legalPerson.identificationType]
        ];
      };
    },
  ],
  templateUrl:
    '/features/modules/shareholders-id/modules/common/components/sid-response-legal-person-info/sid-response-legal-person-info.template.html',
};
