const controller = [
  'AG_SidResponseForwardRepository',
  'cbpToast',
  '$stateParams',
  '$state',
  '$scope',
  function (
    SidResponseForwardRepository,
    cbpToast,
    $stateParams,
    $state,
    $scope
  ) {
    this.$onInit = () => {
      this.sidRequestId = $stateParams.sidRequestId;

      this.unfulfilledFetching = false;
      this.fetching = false;

      this.unfulfilledPageNo = 1;
      this.unfulfilledTotalPages = 1;

      this.pageNo = 1;
      this.totalPages = 1;

      this.fetchUnfulfilledShareholders();
      this.fetchShareholders();
    };

    this.fetchUnfulfilledShareholders = async () => {
      this.unfulfilledFetching = true;
      try {
        const page = await SidResponseForwardRepository.list(
          {
            pageNo: this.unfulfilledPageNo,
            pageSize: this.pageSize,
            fulfilled: false,
            sortyBy: '-quantityAmount',
          },
          { requestId: this.sidRequestId }
        );
        this.unfulfilledTotalPages = Math.ceil(
          page.totalElements / page.pageSize
        );
        this.unfulfilledNomiData = page.list;
      } catch (err) {
        cbpToast.show(`There was an error: ${err.message}`, 'danger');
      } finally {
        this.unfulfilledFetching = false;
        $scope.$apply();
      }
    };

    this.handleUnfulfilledPaginationChanged = (newPage) => {
      if (newPage !== this.unfulfilledPageNo) {
        this.unfulfilledPageNo = newPage;
        this.fetchUnfulfilledShareholders();
      }
    };

    this.fetchShareholders = async () => {
      this.fetching = true;
      try {
        const page = await SidResponseForwardRepository.list(
          {
            pageNo: this.pageNo,
            pageSize: this.pageSize,
            fulfilled: true,
            sortyBy: '-quantityAmount',
          },
          { requestId: this.sidRequestId }
        );
        this.totalPages = Math.ceil(page.totalElements / page.pageSize);
        this.nomiData = page.list;
      } catch (err) {
        cbpToast.show(`There was an error: ${err.message}`, 'danger');
      } finally {
        this.fetching = false;
        $scope.$apply();
      }
    };

    this.handlePaginationChanged = (newPage) => {
      if (newPage !== this.pageNo) {
        this.pageNo = newPage;
        this.fetchShareholders();
      }
    };
  },
];

export default {
  controller,
  templateUrl:
    'features/modules/shareholders-id/modules/agent/views/sid-request-details/results/shareholder-reports-nomi/shareholder-reports-nomi.template.html',
};
