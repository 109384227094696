import { LitElement, html } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class AddAgentUser extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {};
  }

  static get scopedElements() {
    return {
      'agent-um-simple-tile': customElements.get('agent-um-simple-tile'),
      'agent-user-input-form': customElements.get(
        'agent-um-agent-user-input-form'
      ),
    };
  }

  _handleSubmit(event) {
    this.dispatchEvent(new CustomEvent('save-user', { detail: event.detail }));
  }

  render() {
    return html`
      <agent-um-simple-tile title="Add Agent User">
        <agent-user-input-form
          @agent-user-form-submit="${this._handleSubmit}"
        ></agent-user-input-form>

        <slot></slot>
      </agent-um-simple-tile>
    `;
  }
}

customElements.define('agent-um-add-agent-user', AddAgentUser);
