import './sid-response-shareholding-tile.scss';

export default {
  bindings: {
    shareholding: '<',
  },
  controller: [
    function () {
      const shareholdingTypeDisplayMap = {
        BENE: 'Beneficiary',
        NOMI: 'Nominee',
        OOAC: 'OnOwnAccount',
        UKWN: 'Unknown shareholding type',
      };

      const shareholdingTypeSubtitleMap = {
        BENE: 'Shares held on behalf of benecifial owner',
        NOMI: 'Shares held on behalf of on intermediary',
        OOAC: 'Shares held by the responding intermediary',
        UKWN:
          'Shareholding type is unknown: intermediary\'s client hasn\'t been classiefied',
      };

      this.shareholdingTypeDisplayName = () => {
        return shareholdingTypeDisplayMap[
          this.shareholding.sidShareholdingType
        ];
      };

      this.shareholdingTypeSubtitle = () => {
        return shareholdingTypeSubtitleMap[
          this.shareholding.sidShareholdingType
        ];
      };
    },
  ],
  templateUrl:
    '/features/modules/shareholders-id/modules/common/components/sid-response-shareholding-tile/sid-response-shareholding-tile.template.html',
};
