/* eslint-disable indent */
import { html, LitElement } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class IntermediaryView extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      intermediary: { type: Object },
    };
  }

  static get scopedElements() {
    return {
      'aab-typography': customElements.get('aab-typography'),
      'agent-um-simple-tile': customElements.get('agent-um-simple-tile'),
    };
  }

  render() {
    if (!this.intermediary) {
      return null;
    }

    return html`
      <agent-um-simple-tile
        title="Intermediary: ${this.intermediary.groupName}"
      >
        <aab-typography>
          <label>Name</label>
          <p>${this.intermediary.groupName}</p>

          <label>Email</label>
          <p>${this.intermediary.groupEmail}</p>

          <label>City</label>
          <p>${this.intermediary.city}</p>

          <label>Country</label>
          <p>${this.intermediary.country}</p>
        </aab-typography>
      </agent-um-simple-tile>
    `;
  }
}

customElements.define('agent-um-intermediary-view', IntermediaryView);
