export default {
  controller: [
    '$state',
    function ($state) {
      this.$onInit = () => {
        this.tabs = [
          {
            id: 'shareholders',
            title: 'Shareholders',
          },
        ];
      };

      this.selectedTab = () => {
        return $state.current.name.split('.')[1];
      };

      this.handleTabClicked = (ev) => {
        $state.go(`agentUserManagement.${ev.target.selected}`);
      };

      this.handleBackToOldVersion = () => {
        $state.go('userMaintenance');
      };
    },
  ],
  templateUrl:
    '/features/modules/agent/user-management/views/agUserManagementMain.template.html',
};
