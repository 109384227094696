import { SID_COMMON_BASE_URL } from 'Modules/shareholders-id/constants';

import './sid-request-report-shareholder-tile.scss';
import { joinStringValuesMaxLength } from '@/lib/sid/formatBoDetails';

const Ctrl = [
  function () {
    this.$onInit = () => {
      this.expanded = false;
      this.countries = [];
      this.identifications = [];
      this.joinedShareholderNames = joinStringValuesMaxLength(
        this.shareholder.names,
        60
      );
      if (this.shareholder.countries) {
        this.countries = $.unique(this.shareholder.countries);
      }
      if (this.shareholder.identifications) {
        this.identifications = $.unique(this.shareholder.identifications);
      }
      if (this.shareholder.quantityUnit) {
        this.shareholder.quantityUnit = Math.round(
          this.shareholder.quantityUnit
        );
      }
    };

    this.handleToggleAccordion = (event) => {
      const { opened } = event.detail;
      this.onToggle({ opened });
    };

    this.$onChanges = (changeObj) => {
      if (changeObj.boDetails?.currentValue) {
        const boDetailsCopy = angular.copy(changeObj.boDetails.currentValue);
        this.boDetailsAddress = boDetailsCopy['Address'];
        this.displayedLabels = Object.keys(boDetailsCopy)
          .filter(
            (key) => !['Address', 'Responding Intermediaries'].includes(key)
          )
          .map((key) => {
            return {
              label: key,
              value: boDetailsCopy[key],
            };
          });
      }
    };
  },
];

export default {
  bindings: {
    shareholder: '<',
    boDetails: '<',
    onToggle: '&',
  },
  controller: Ctrl,
  templateUrl: `${SID_COMMON_BASE_URL}/components/sid-request-report-shareholder-tile/sid-request-report-shareholder-tile.template.html`,
};
