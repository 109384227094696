import './VoteCollectors';
import {
  listVoteCollectors,
  toggleVoteCollectorDisabled,
} from '@/api/v1/userGroups';

export default {
  controller: [
    '$state',
    '$http',
    '$scope',
    function ($state, $http, $scope) {
      this.$onInit = () => {
        this.fetchVoteCollectors().then(() => {
          $scope.$apply();
        });
      };

      this.fetchVoteCollectors = async () => {
        this.voteCollectors = await listVoteCollectors($http);
      };

      this.handleAddVoteCollector = () => {
        $state.go('agentUserManagement.voteCollectors.addVoteCollector');
      };

      this.handleEditVoteCollector = ({ detail: { voteCollectorId } }) => {
        $state.go('agentUserManagement.voteCollectors.editVoteCollector', {
          voteCollectorId,
        });
      };

      this.handleViewUsersClick = ({ detail: { voteCollectorId } }) => {
        $state.go('agentUserManagement.voteCollectors.users', {
          voteCollectorId,
        });
      };

      this.handleVoteCollectorClick = ({ detail: { voteCollectorId } }) => {
        $state.go('agentUserManagement.voteCollectors.voteCollector', {
          voteCollectorId,
        });
      };

      this.toggleVoteCollectorDisabled = async ({
        detail: { voteCollectorId },
      }) => {
        await toggleVoteCollectorDisabled($http, voteCollectorId);
        this.voteCollectors = null;
        $scope.$apply();
        await this.fetchVoteCollectors();
        $scope.$apply();
      };
    },
  ],
  template: `
    <div class="my-3">
      <div class="p-5" ng-if="!$ctrl.voteCollectors">
        <cbp-loading-spinner></cbp-loading-spinner>
      </div>

      <agent-um-vote-collectors
        ng-if="$ctrl.voteCollectors"
        ng-prop-vote_collectors="$ctrl.voteCollectors"
        ng-on-add-vote-collector-click="$ctrl.handleAddVoteCollector($event)"
        ng-on-edit-click="$ctrl.handleEditVoteCollector($event)"
        ng-on-vote-collector-click="$ctrl.handleVoteCollectorClick($event)"
        ng-on-view-users-click="$ctrl.handleViewUsersClick($event)"
        ng-on-enable-click="$ctrl.toggleVoteCollectorDisabled($event)"
        ng-on-disable-click="$ctrl.toggleVoteCollectorDisabled($event)"
      ></agent-um-vote-collectors>
    </div>
  `,
};
