import './AddIssuerView';
import fetchCountries from '@/api/v1/countries';
import { addIssuer } from '@/api/v1/issuer';

export default {
  controller: [
    '$scope',
    '$http',
    '$sessionStorage',
    function ($scope, $http, $sessionStorage) {
      let countries;

      this.$onInit = () => {
        Promise.all([this.fetchCountries()]).then(() => {
          $scope.$apply();
        });
      };

      this.fetchCountries = async () => {
        countries = await fetchCountries($http);
        this.countryOptions = countries.map((c) => ({
          value: c.countryName,
          name: c.countryName,
        }));
      };

      this.handleSaveClick = async (ev) => {
        ev.detail.country = countries.find(
          (c) => c.countryName === ev.detail.country
        ).isoCountryCode;

        this.status = 'pending';
        try {
          const response = await addIssuer($http, {
            ...ev.detail,
            userId: $sessionStorage.userId,
            productCode: 'GM',
          });
          this.status = 'success';
          this.statusMessage = response.message;
        } catch (err) {
          this.status = 'error';
          this.statusMessage = err.data.errors.map((e) => e.errorMessage);
        } finally {
          $scope.$apply();
        }
      };
    },
  ],
  template: `
    <div class="my-3">
      <agent-um-add-issuer-view
        ng-prop-country_options="$ctrl.countryOptions"
        ng-on-save-click="$ctrl.handleSaveClick($event)"
      >
        <agent-um-status-message-renderer
          ng-prop-status="$ctrl.status"
          ng-prop-status_message="$ctrl.statusMessage"
        ></agent-um-status-message-renderer>
      </agent-um-add-issuer-view>
    </div>
  `,
};
