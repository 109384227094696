/* eslint-disable indent */
import { LitElement, html } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class EditVoteCollector extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      voteCollector: { type: Object },
    };
  }

  static get scopedElements() {
    return {
      'vote-collector-input-form': customElements.get(
        'agent-um-vote-collector-input-form'
      ),
      'agent-um-simple-tile': customElements.get('agent-um-simple-tile'),
    };
  }

  _handleSaveClick(event) {
    console.warn(event);
  }

  render() {
    if (!this.voteCollector) {
      return null;
    }

    return html`
      <agent-um-simple-tile
        title="Edit voteCollector: ${this.voteCollector.groupName}"
      >
        <vote-collector-input-form
          .voteCollector=${this.voteCollector}
          @save-click="${this._handleSaveClick}"
        ></vote-collector-input-form>
      </agent-um-simple-tile>
    `;
  }
}

customElements.define('agent-um-edit-vote-collector', EditVoteCollector);
