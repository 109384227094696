import 'Modules/emerald/emerald.module';
import '@aab/sc-aab-icon';
import '@aab/sc-aab-datepicker';

const cbpUi = angular.module('cbpUi', ['emerald']);

import cbpActionButton from './components/action-button/action-button.component';
import cbpTabs from './components/cbp-tabs/cbp-tabs.component';
import cbpPaginationControl from './components/pagination-control/pagination-control.component';
import productTileAlertBox from './components/product-tile-alert-box/product-tile-alert-box.component';
import cbpDialog from './components/cbp-dialog/cbp-dialog.component';
import cbpCountryIcon from './components/cbp-country-icon/cbp-country-icon.component';
import cbpProgressTimeline from './components/progress-timeline/progress-timeline.component';
import cbpProgressTimelineEvent from './components/progress-timeline-event/progress-timeline-event.component';
import cbpIconCircle from './components/cbp-icon-circle/cbp-icon-circle.component';
import cbpAabIcon from './components/cbp-aab-icon/cbp-aab-icon.component';
import cbpBackgroundCover from './components/cbp-background-cover/cbp-background-cover.component';
import cbpFileLoadingBackgroundCover from './components/cbp-file-loading-background-cover/cbp-file-loading-background-cover.component';
import cbpLoadingSpinner from './components/cbp-loading-spinner/cbp-loading-spinner.component';
import cbpAccordion from './components/cbp-accordion/cbp-accordion.component';
import emPageGradientPositioner from './components/em-page-gradient-positioner/em-page-gradient-positioner.component';
import cbpChart from './components/cbp-chart/cbp-chart.component';
import cbpCombobox from './components/cbp-combobox/cbp-combobox.component';

cbpUi.component({
  cbpActionButton,
  cbpTabs,
  cbpPaginationControl,
  productTileAlertBox,
  cbpDialog,
  cbpCountryIcon,
  cbpProgressTimeline,
  cbpProgressTimelineEvent,
  cbpIconCircle,
  cbpAabIcon,
  cbpBackgroundCover,
  cbpFileLoadingBackgroundCover,
  cbpLoadingSpinner,
  cbpAccordion,
  emPageGradientPositioner,
  cbpChart,
  cbpCombobox,
});

export default cbpUi;
