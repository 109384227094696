/* eslint-disable indent */
import { LitElement, html } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class EditIntermediary extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      intermediary: { type: Object },
    };
  }

  static get scopedElements() {
    return {
      'intermediary-input-form': customElements.get(
        'agent-um-intermediary-input-form'
      ),
      'agent-um-simple-tile': customElements.get('agent-um-simple-tile'),
    };
  }

  _handleSaveClick(event) {
    console.warn(event);
  }

  render() {
    return html`
      <agent-um-simple-tile
        title="Edit intermediary: ${this.intermediary.name}"
      >
        <intermediary-input-form
          .intermediary=${this.intermediary}
          @save-click="${this._handleSaveClick}"
        ></intermediary-input-form>
      </agent-um-simple-tile>
    `;
  }
}

customElements.define('agent-um-edit-intermediary', EditIntermediary);
