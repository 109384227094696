import './IntermediaryUser';
import { fetchUser } from '@/api/v1/users';

export default {
  controller: [
    '$http',
    '$scope',
    '$stateParams',
    function ($http, $scope, $stateParams) {
      this.$onInit = () => {
        this.fetchUser().then(() => {
          $scope.$apply();
        });
      };

      this.fetchUser = async () => {
        this.user = await fetchUser(
          $http,
          'IN',
          $stateParams.intermediaryId,
          $stateParams.userId
        );
      };
    },
  ],
  template: `
    <div class="my-3">
      <div class="p-5" ng-if="!$ctrl.user">
        <cbp-loading-spinner></cbp-loading-spinner>
      </div>
      <agent-um-intermediary-user ng-if="$ctrl.user" ng-prop-user="$ctrl.user"></agent-um-intermediary-user>
    </div>
  `,
};
