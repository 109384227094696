const styles = require('./em-typography.module.scss');

module.exports = {
  bindings: {
    variant: '@',
    color: '@',
  },
  transclude: true,
  controller: [
    function () {
      const VALID_VARIANTS = ['intro', 'body', 'small', 'label', 'link'];
      const VALID_COLORS = ['green', 'lightGray', 'gray', 'white'];

      this.$onInit = () => {
        if (!VALID_VARIANTS.includes(this.variant)) {
          this.variant = 'body';
        }

        if (this.color && !VALID_COLORS.includes(this.color)) {
          this.color = '';
        }

        this.className = `${styles[this.variant]} ${styles[this.color]}`;
      };
    },
  ],
  template: `
        <div ng-if="$ctrl.variant !== 'link'" class="{{ $ctrl.className }}" ng-transclude></div>
        <span ng-if="$ctrl.variant === 'link'" class="{{ $ctrl.className }}" ng-transclude></span>
    `,
};
