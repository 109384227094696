export default {
  controller: function () {
    this.$onChanges = (changeObj) => {
      if (changeObj.thirdParty?.currentValue) {
        const thirdPartyCopy = angular.copy(changeObj.thirdParty.currentValue);
        this.address = thirdPartyCopy['Address'];
        this.displayedLabels = Object.keys(thirdPartyCopy)
          .filter((key) => !['Address'].includes(key))
          .map((key) => {
            return {
              label: key,
              value: thirdPartyCopy[key],
            };
          });
      }
    };
  },
  bindings: {
    thirdParty: '<',
  },
  template: `
  <div class="row">
    <div class="col-12" ng-class="{'col-md-8': $ctrl.address}">
      <div
        ng-repeat="displayedLabel in $ctrl.displayedLabels"
        ng-if="!!displayedLabel.value"
        class="row"
      >
        <div class="col-12 col-md-5">
          <em-typography color="gray">
            <strong>{{ displayedLabel.label }}:</strong>
          </em-typography>
        </div>
        <div class="col-12 col-md-7">
          <em-typography>
            {{ displayedLabel.value }}
          </em-typography>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4" ng-if="$ctrl.address">
      <div class="mb-3">
        <sid-address address="$ctrl.address"></sid-address>
      </div>
    </div>
  </div>
  `,
};
