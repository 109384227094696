import { LitElement, html } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class EditAgentUser extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      user: { type: Object },
    };
  }

  static get scopedElements() {
    return {
      'agent-user-input-form': customElements.get(
        'agent-um-agent-user-input-form'
      ),
      'agent-um-simple-tile': customElements.get('agent-um-simple-tile'),
    };
  }

  _handleSubmit(event) {
    this.dispatchEvent(new CustomEvent('save-user', { detail: event.detail }));
  }

  render() {
    if (!this.user) {
      return null;
    }

    return html`
      <agent-um-simple-tile title="Edit Agent: ${this.user.email}">
        <agent-user-input-form
          .user=${this.user}
          @agent-user-form-submit="${this._handleSubmit}"
        ></agent-user-input-form>

        <slot></slot>
      </agent-um-simple-tile>
    `;
  }
}

customElements.define('agent-um-edit-agent-user', EditAgentUser);
