export default [
  '$stateProvider',
  function ($stateProvider) {
    const mainState = {
      name: 'shareholders-id.intermediary',
      url: '/intermediary',
      template: '<div ui-view></div>',
      redirectTo: 'shareholders-id.intermediary.landing',
    };
    const landingState = {
      name: 'shareholders-id.intermediary.landing',
      url: '/',
      component: 'sidIntermediaryLandingPage',
    };

    $stateProvider.state(mainState);
    $stateProvider.state(landingState);
  },
];
