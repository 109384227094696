import { LitElement, html, css } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class AddIntermediaryView extends ScopedElementsMixin(LitElement) {
  static get scopedElements() {
    return {
      'intermediary-input-form': customElements.get('agent-um-intermediary-input-form'),
      'agent-um-simple-tile': customElements.get('agent-um-simple-tile'),
    };
  }

  static get styles() {
    return css`
      aab-input,
      aab-select {
        display: block;
        margin-bottom: 1rem;
      }
    `;
  }

  _handleSaveClick(event) {
    console.warn(event);
  }

  render() {
    return html`
      <agent-um-simple-tile title="Add intermediary">
        <intermediary-input-form
          @save-click="${this._handleSaveClick}"
        ></intermediary-input-form>
      </agent-um-simple-tile>
    `;
  }
}

customElements.define('agent-um-add-intermediary-view', AddIntermediaryView);
