export default [
  '$filter',
  function () {
    var directive = {};
    directive.restrict = 'E';
    directive.replace = true;
    directive.scope = {
      startProgressBar: '=',
      serviceurl: '=',
      action: '=',
      recordCount: '=',
      fileName: '=',
      jobExecutionId: '=',
    };
    directive.compile = function () {
      return function (scope, element, attribute) {
        var id =
          attribute.progressid === undefined || attribute.progressid === ''
            ? 'progressModal'
            : attribute.progressid;
        scope.tabId = id;
      };
    };
    var controller = function ($scope, $interval, $http, $filter) {
      var stop;
      var progress;
      $scope.percentage = 0;
      $scope.active = true;
      $scope.itemsProcessed = 0;
      $scope.itemsValidated = 0;

      $scope.fetchExecutionStatus = function () {
        return $http({
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          url: $scope.serviceurl,
          method: 'GET',
        });
      };
      $scope.startService = function () {
        if (angular.isDefined(stop)) {
          return;
        }

        $scope.active = true;
        $scope.loading = true;
        stop = $interval(function () {
          //if there is an unresolved promise do not send a new API request to prevent concurrency issues
          if ($scope.waiting) {
            return;
          }
          // Make a call to Service to get the status and based on status call stop service
          let promise = $scope.fetchExecutionStatus();
          $scope.waiting = true;
          promise.then(
            function (data) {
              $scope.waiting = false;
              $scope.timeRemaining = $scope.msToTime(
                $scope.estimateMsRemaining(data.data)
              );

              if (
                data.data.batchStatus === 'FAILED' ||
                data.data.stepStatus === 'FAILED' ||
                data.data.batchStatus === 'COMPLETED'
              ) {
                $scope.timeRemaining = $scope.msToTime(0);
                $scope.exitCode = data.data.batchStatus;
                $scope.loading = false;
                $scope.active = false;

                if (data.data.errors) {
                  let errorMessage = data.data.errors.replace(
                    /\r\n/g,
                    '&lt;br&gt;'
                  );
                  $scope.progressMessage =
                    $filter('translate')('bulkUpload_errorMsg') +
                    '<br/>' +
                    $filter('htmlDecode')(errorMessage);
                  $scope.errorFound = true;
                  $scope.percentage = 0;
                }
                if (data.data.batchStatus === 'COMPLETED') {
                  $scope.percentage = 100;
                  $scope.errorFound = false;
                  $scope.progressMessage = $filter('translate')(
                    'bulkUpload_successMsg'
                  );
                }
                if (
                  !data.data.errors &&
                  (data.data.batchStatus === 'FAILED' ||
                    data.data.batchStatus === 'STOPPED')
                ) {
                  $scope.percentage = 0;
                  $scope.errorFound = true;
                  $scope.technicalError = true;
                  $scope.progressMessage = $filter('translate')(
                    'label_technicalError'
                  );
                }
                $scope.startProgressBar = undefined;
                $scope.stopService();
              }
            },
            function (error) {
              $scope.errorFound = true;
              $scope.active = false;
              $scope.waiting = false;
              $scope.percentage = 0;
              $scope.progressMessage = $filter('htmlDecode')(
                error.errors[0].errorMessage
              );
              $scope.stopService();
            }
          );
        }, 1000);
      };

      $scope.estimateMsRemaining = function (jobmetrics) {
        //number of steps defined in reportJob
        const nSteps = 2;
        //validation speed per item as the base speed used for estimation purposes.
        const baseSpeed = 1;
        //relative processing speed (data persistence + sending email) compared to validation speed.
        const relativeProcSpeed = 3.5;

        let totalItems = $scope.recordCount * nSteps;
        let estimatedProcessingTime = 0;
        let itemsWritten = 0;
        $scope.msPerItem = 0;
        let startTime = new Date(jobmetrics.startTime).getTime();
        let lastUpdated = new Date(jobmetrics.lastUpdated).getTime();
        let timePassed = lastUpdated - startTime;
        if (jobmetrics.writeCount > 0) {
          itemsWritten = jobmetrics.writeCount;
          $scope.msPerItem = Math.round(timePassed / itemsWritten);
        }

        if (jobmetrics.stepName === 'shareplanValidate') {
          $scope.itemsValidated = itemsWritten;
          $scope.itemsProcessed = 0;
          estimatedProcessingTime =
            $scope.msPerItem * relativeProcSpeed * (totalItems - itemsWritten);
        } else {
          $scope.itemsValidated = $scope.recordCount;
          $scope.itemsProcessed = itemsWritten;
          if (itemsWritten === 0) {
            estimatedProcessingTime =
              $scope.msPerItem * $scope.recordCount * relativeProcSpeed;
          }
        }

        let relativeProcTime = baseSpeed + relativeProcSpeed;
        let valRatio = baseSpeed / relativeProcTime;
        let procRatio = relativeProcSpeed / relativeProcTime;
        let progressCount =
          valRatio * $scope.itemsValidated + procRatio * $scope.itemsProcessed;
        $scope.percentage = Math.round(
          ((progressCount * 2) / totalItems) * 100
        );

        let timeRemaining =
          ($scope.recordCount - itemsWritten) * $scope.msPerItem +
          estimatedProcessingTime;

        return timeRemaining;
      };

      $scope.msToTime = function (duration) {
        var seconds = parseInt((duration / 1000) % 60),
          minutes = parseInt((duration / (1000 * 60)) % 60),
          hours = parseInt((duration / (1000 * 60 * 60)) % 24);

        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        let timeRemaining;
        if (hours > 0) {
          timeRemaining = hours + ':' + minutes + ':' + seconds + ' hrs';
        } else if (minutes > 0) {
          timeRemaining = minutes + ':' + seconds + ' min';
        } else if (seconds >= 0) {
          timeRemaining = seconds + ' s';
        }

        return timeRemaining;
      };

      $scope.startProgressService = function () {
        var id = '#' + $scope.tabId;
        $(id).modal('show');
      };
      $scope.stopService = function () {
        if (angular.isDefined(stop)) {
          $interval.cancel(stop);
          stop = undefined;
          $scope.stopProgressService();
        }
      };
      $scope.stopProgressService = function () {
        if (angular.isDefined(progress)) {
          $interval.cancel(progress);
          progress = undefined;
        }
      };
      $scope.$on('$destroy', function () {
        // Make sure that the interval is destroyed too
        $scope.stopService();
      });
      $scope.closeProgressBar = function () {
        $scope.progressMessage = '';
        $scope.percentage = 0;
        $scope.itemsValidated = 0;
        $scope.itemsProcessed = 0;
        if (
          $scope.action === 'startProgressBar' &&
          $scope.errorFound === false
        ) {
          $scope.inviteSharePlanSuccess = true;
        } else {
          $scope.inviteSharePlanSuccess = false;
          if ($scope.technicalError) {
            $scope.$parent.saveDisabled = false;
          }
        }
        let id = '#' + $scope.tabId;
        $(id).modal('hide');
        $('.modal-backdrop').remove();
        // destroy the modal
        $(id).data('modal', null);
        $scope.$root.$broadcast(
          'onProgressBarClose',
          $scope.jobStatus,
          $scope.inviteSharePlanSuccess
        );
      };
      $scope.$watch('startProgressBar', function (newValue, oldValue) {
        if (newValue === true) {
          $scope.percentage = 0;
          $scope.startService();
          $scope.startProgressService();
        } else if (newValue === false) {
          let id = '#' + $scope.tabId;
          $(id).modal('hide');
          $scope.stopService();
        }
      });
    };

    directive.controller = [
      '$scope',
      '$interval',
      '$http',
      '$filter',
      '$state',
      controller,
    ];
    directive.templateUrl =
      'shared/directives/templates/progressbar-template.html';
    return directive;
  },
];
