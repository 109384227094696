// interface Role {
//   name: string
//   statusCode: string
// }

// interface Resource {
//   name: string
// }

// interface Permission {
//   role: Role
//   resources: Resources
// }

// interface UserAccount {
//   id: string
//   username: string
//   accountLocked: boolean
//   lastLoginTimeStamp: ZonedDateTime
//   assignedPermissions: Permisson
// }

/**
 * A shareholder user is a user who has
 *
 * @param {UserAccount[]} userAccounts
 * @returns {UserAccount[]} but only the ones that are shareholders
 */
function filterNonShareholders(userAccounts) {
  const a = userAccounts.filter((account) => {
    return !!account.assignedPermissions.find((p) => p.role.name === 'SH');
  });
  return a;
}

/**
 * @param {*} $http
 * @param {*} email
 * @returns {UserAccount[]}
 */
export default async function searchShareholderByEmail($http, email) {
  const shareholders = (await $http.get(`/v2/users?email=${email}`)).data;
  return filterNonShareholders(shareholders);
}
