import { SID_COMMON_BASE_URL } from 'Modules/shareholders-id/constants';

const controller = [
  function () {
    this.statusMap = {
      draft: 'Draft',
      awaiting_agent_approval: 'Awaiting agent approval',
      awaiting_issuer_approval: 'Awaiting issuer approval',
      rejected: 'Rejected',
      approved: 'Approved',
      in_progress: 'In progress',
      completed: 'Completed',
      cancelled: 'Cancelled',
    };

    this.colorMap = {
      draft: 'red',
      awaiting_agent_approval: 'blue',
      awaiting_issuer_approval: 'blue',
      rejected: 'red',
      approved: 'gray',
      in_progress: 'gray',
      completed: 'green',
      cancelled: 'gray',
    };
  },
];

export default {
  bindings: {
    status: '@',
  },
  controller,
  templateUrl: `${SID_COMMON_BASE_URL}/components/sid-status-tag/sid-status-tag.template.html`,
};
