import { SID_IS_BASE_URL } from 'Modules/shareholders-id/constants';

import './sid-request-tile.scss';

export default {
  bindings: {
    sidRequest: '<',
    showArrow: '<',
  },

  controller: [
    function () {
      this.iconName = () => {
        if (this.sidRequest.status === 'completed') {
          return 'pr-profiles-family';
        }
        if (this.sidRequest.status === 'draft') {
          return 'pr-paper-marker';
        } else {
          return 'pr-documents-search';
        }
      };
    },
  ],

  templateUrl: `${SID_IS_BASE_URL}/components/sid-request-tile/sid-request-tile.template.html`,
};
