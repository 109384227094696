// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1IHgUhAeIm21BkaOlKyxmK{position:fixed;top:0;left:0;z-index:999999;width:100%;height:100%;background-color:rgba(0,76,76,.3);display:flex;align-items:center;justify-content:center}", ""]);
// Exports
exports.locals = {
	"root": "_1IHgUhAeIm21BkaOlKyxmK"
};
module.exports = exports;
