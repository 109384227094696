import './IntermediariesView';
import {
  listIntermediaries,
  toggleIntermediaryDisabled,
} from '@/api/v1/userGroups';

export default {
  controller: [
    '$state',
    '$http',
    '$scope',
    function ($state, $http, $scope) {
      this.$onInit = () => {
        this.fetchIntermediaries().then(() => {
          $scope.$apply();
        });
      };

      this.fetchIntermediaries = async () => {
        this.intermediaries = await listIntermediaries($http);
      };

      this.handleAddIntermediaryClick = () => {
        $state.go('agentUserManagement.intermediaries.addIntermediary');
      };

      this.handleIntermediaryClick = ({ detail: { intermediaryId } }) => {
        $state.go('agentUserManagement.intermediaries.intermediary', {
          intermediaryId,
        });
      };

      this.handleViewUsersClick = ({ detail: { intermediaryId } }) => {
        $state.go('agentUserManagement.intermediaries.users', {
          intermediaryId,
        });
      };

      this.handleEditClick = ({ detail: { intermediaryId } }) => {
        $state.go('agentUserManagement.intermediaries.editIntermediary', {
          intermediaryId,
        });
      };

      this.toggleIntermediaryDisabled = async ({
        detail: { intermediaryId },
      }) => {
        await toggleIntermediaryDisabled($http, intermediaryId);
        this.intermediaries = null;
        await this.fetchIntermediaries();
        $scope.$apply();
      };
    },
  ],
  template: `
    <div class="my-3">
      <div class="p-5" ng-if="!$ctrl.intermediaries">
        <cbp-loading-spinner></cbp-loading-spinner>
      </div>

      <agent-um-intermediaries-view
        ng-if="$ctrl.intermediaries"
        ng-prop-intermediaries="$ctrl.intermediaries"
        ng-on-add-intermediary-click="$ctrl.handleAddIntermediaryClick()"
        ng-on-intermediary-click="$ctrl.handleIntermediaryClick($event)"
        ng-on-view-users-click="$ctrl.handleViewUsersClick($event)"
        ng-on-edit-click="$ctrl.handleEditClick($event)"
        ng-on-enable-click="$ctrl.toggleIntermediaryDisabled($event)"
        ng-on-disable-click="$ctrl.toggleIntermediaryDisabled($event)"
      ></agent-um-intermediaries-view>
    </div>
  `,
};
