import { LitElement, html } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';
import styles from './SimpleNavigator.scss';

/**
 * `items`:
 * - name
 * - key
 * - children: Item[]
 * - expandable
 * - badge
 */
export default class SimpleNavigator extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      items: {
        type: Array,
      },
      selected: {
        // the 'key' of the selected 'item'
        type: String,
      },
      badge: {
        type: [Number, String],
      },
    };
  }

  static get scopedElements() {
    return {
      'aab-link': customElements.get('aab-link'),
    };
  }

  static get styles() {
    return styles;
  }

  constructor() {
    super();
    this.items = [];
  }

  _handleItemClick(item) {
    this.dispatchEvent(
      new CustomEvent('item-selected', { detail: { key: item.key } })
    );
  }

  _renderItem(item) {
    const children = item.children
      ? html`<ul>
          ${item.children.map((child) => this._renderItem(child))}
        </ul>`
      : '';

    const badge =
      item.badge !== undefined
        ? html`<span class="badge">${item.badge}</span>`
        : '';
    return html`
      <li>
        <a
          class="item-name ${this.selected === item.key ? 'selected' : ''}"
          icon="none"
          @click="${() => this._handleItemClick(item)}"
        >
          ${item.name} ${badge}
        </a>

        ${children}
      </li>
    `;
  }

  render() {
    if (!this.items.length) {
      return null;
    }

    return html`
      <ul>
        ${this.items.map((item) => this._renderItem(item))}
      </ul>
    `;
  }
}
