import './AddIntermediaryView';
import fetchCountries from '@/api/v1/countries';

export default {
  controller: [
    '$scope',
    '$http',
    '$state',
    function ($scope, $http, $state) {
      let countries;

      this.$onInit = () => {
        $state.go('userMaintenance.addIntermediaryGroup');

        Promise.all([this.fetchCountries()]).then(() => {
          $scope.$apply();
        });
      };

      this.fetchCountries = async () => {
        countries = await fetchCountries($http);
        this.countryOptions = countries.map((c) => ({
          value: c.countryName,
          name: c.countryName,
        }));
      };
    },
  ],
  template: `
    <div class="my-3">
      <agent-um-add-intermediary-view>
      </agent-um-add-intermediary-view>
    </div>
  `,
};
