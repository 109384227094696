import './ProxyIntermediaryView';
import { fetchIntermediary } from '@/api/v1/userGroups';

export default {
  controller: [
    '$http',
    '$scope',
    '$stateParams',
    function ($http, $scope, $stateParams) {
      this.$onInit = () => {
        this.fetchProxyIntermediary().then(() => {
          $scope.$apply();
        });
      };

      this.fetchProxyIntermediary = async () => {
        this.proxyIntermediary = await fetchIntermediary(
          $http,
          $stateParams.proxyIntermediaryId
        );
      };
    },
  ],
  template: `
    <div class="agUserManagementProxyIntermediary my-3">
      <div class="p-5" ng-if="!$ctrl.proxyIntermediary">
        <cbp-loading-spinner></cbp-loading-spinner>
      </div>

      <agent-um-proxy-intermediary-view
        ng-if="$ctrl.proxyIntermediary"
        ng-prop-proxy_intermediary="$ctrl.proxyIntermediary"
      ></agent-um-proxy-intermediary-view>
    </div>
  `,
};
