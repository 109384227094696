const { BASE_URL } = require('../constants');

require('./em-button.scss');

/**
 * Port of the same component in the CBP-FE app
 */
const Ctrl = [
  function () {
    this.$onInit = () => {
      if (!this.variant) {
        this.variant = 'primary';
      }
    };

    this.classNames = () => {
      const classes = ['btn', this.variantClassName()];
      if (this.large) {
        classes.push('btn-lg');
      }
      return classes;
    };

    this.variantClassName = () => {
      return `btn-${this.variant}`;
    };
  },
];

module.exports = {
  bindings: {
    variant: '@',
    icon: '@',
    large: '<',
    disabled: '<',
    fullWidth: '<',
    onClick: '&',
    height: '@',
  },
  transclude: true,
  controller: Ctrl,
  templateUrl: `${BASE_URL}/em-button/em-button.template.html`,
};
