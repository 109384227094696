/**
 * Takes an arrayBuffer (raw data), and triggers a download from the browser
 * with the specified filename
 *
 * @param {ArrayBuffer} arrayBuffer
 * @param {String} filename
 */
export default function (arrayBuffer, filename) {
  const blob = new Blob([arrayBuffer]);
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
