import { sortCountryDataByShares } from '@/lib/sid/sortCountryData';
import { groupSortedCountryData } from '@/lib/sid/groupSortedCountryData';
import { chartColors } from '../../../../../cbp-ui/components/cbp-chart/cbp-chart.component';

export default {
  bindings: {
    countryData: '<',
    totalOutstandingShares: '<',
  },
  controller: [
    function () {
      this.$onInit = () => {
        this.chartOptions = {
          elements: {
            bar: {
              borderWidth: 1,
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  const amount = context.dataset.data[context.dataIndex];
                  const percentage = parseFloat(
                    (amount / this.totalOutstandingShares) * 100
                  ).toFixed(2);
                  const formattedAmount = new Intl.NumberFormat().format(
                    amount
                  );
                  return `${formattedAmount} - ${percentage}%`;
                },
              },
            },
          },
        };

        const filteredCountryData = this.countryData.filter(
          (cdi) => !!cdi.countryCode
        );
        const sortedCountryData = sortCountryDataByShares(filteredCountryData);
        const groupedCountryData = groupSortedCountryData(sortedCountryData, 7);

        this.data = {
          labels: groupedCountryData.map(
            (countryDataItem) => countryDataItem.countryCode
          ),
          datasets: [
            {
              label: 'Legal',
              backgroundColor: chartColors[0].light,
              borderColor: chartColors[0].default,
              data: groupedCountryData.map((cdi) => cdi.totalLegalShares),
            },
            {
              label: 'Natural',
              backgroundColor: chartColors[1].light,
              borderColor: chartColors[1].default,
              data: groupedCountryData.map((cdi) => cdi.totalNaturalShares),
            },
          ],
        };
      };
    },
  ],
  templateUrl:
    '/features/modules/shareholders-id/modules/common/components/sid-shares-country-chart/sid-shares-country-chart.template.html',
};
