export default ({ actions: actionsRenderFn }) => [
  {
    id: 'name',
    label: 'Group name',
    key: 'groupName',
  },
  {
    id: 'email',
    label: 'Email',
    key: 'groupEmail',
  },
  {
    id: 'city',
    label: 'City',
    key: 'city',
  },
  {
    id: 'country',
    label: 'Country',
    key: 'country',
  },
  {
    id: 'status',
    label: 'Status',
    key: 'status',
  },
  {
    id: 'actions',
    label: 'Actions',
    render: actionsRenderFn,
  },
];
