const {BASE_URL} = require('../constants');

function validateIsin(isin) {
  const mapToInt = (stringNum) => parseInt(stringNum, 10);
  const mapMultiply = (num) => `${num * 2}`;
  const filterCollectGroup1 = (num, i) => i % 2 === 0;
  const filterCollectGroup2 = (num, i) => i % 2 === 1;
  const sum = (acc, num) => acc + num;

  function _convertCharactersToNumbersArray(stringValue) {
    // charCodeAt returns ASCII code. We want 'A' (65) to be 10, so we do minus 55
    return stringValue.split('').map(v => {
      const number = parseInt(v, 10);
      if (isNaN(number)) {
        return v.charCodeAt(0) - 55;
      } else {
        return number;
      }
    }).join('').split('').map(mapToInt);
  }

  // Check global format: AB1234567890
  if (!/[A-Z]{2,2}[A-Z0-9]{9,9}[0-9]{1,1}/.test(isin)) {
    return false;
  }
  const countryCode = isin.substr(0, 2);
  const nsin = isin.substr(2, 9);
  const control = parseInt(isin.charAt(11), 10);

  const countryIntArray = _convertCharactersToNumbersArray(countryCode);
  const nIsinArray = _convertCharactersToNumbersArray(nsin);
  const numStringArray = countryIntArray.concat(nIsinArray);

  let group1 = numStringArray.filter(filterCollectGroup1);
  const group2 = numStringArray.filter(filterCollectGroup2);
  // multiply group1 by 2 and split up two digits into two separate value
  group1 = group1.map(mapMultiply).join('').split('').map(mapToInt);

  const total = group1.reduce(sum) + group2.reduce(sum);

  const expectedControl = 10 - (total % 10);
  return control === expectedControl;
}


module.exports = {
  bindings: {
    name: '@',
    placeholder: '@',
    required: '<',

    value: '<',

    onChange: '&',
    onBlur: '&',
    onFocus: '&',
  },
  controller: ['$element', function ($element) {
    this.$onInit = () => {
      this.form = $element.controller('form');
    };

    this.onUpdate = (value, name, event) => {
      this.value = value;

      if (validateIsin(value)) { // TODO fix validation
        this.form[this.name].$setValidity('invalidIsin', true, this);
        if (event === 'change') {
          this.onChange({value, name});
        } else if (event === 'blur') {
          this.onBlur({value, name});
        }
      } else {
        this.form[this.name].$setValidity('invalidIsin', false, this);
      }
    };

    this.handleChange = (value, name) => {
      this.onUpdate(value, name, 'change');
    };

    this.handleBlur = (value, name) => {
      this.onUpdate(value, name, 'blur');
    };

  }],
  templateUrl: `${BASE_URL}/em-isin-input/em-isin-input.template.html`
};