export default [
  function () {
    var directive = {};
    directive.restrict = 'E';
    directive.replace = true;
    directive.scope = {
      startProgressBar: '=',
      serviceurl: '=',
      jobStatus: '=',
      actionTaken: '=',
      claimStateParms: '=',
      fileName: '=',
      modalRightButton: '=modalRightButton',
      callbackbuttonright: '&ngClickRightButton',
      modalLeftButton: '=modalLeftButton',
      callbackbuttonleft: '&ngClickLeftButton',
      modalLeftButton2: '=modalLeftButton2',
      callbackbuttonleft2: '&ngClickLeftButton2',
    };
    directive.compile = function () {
      return function (scope, element, attribute) {
        var id =
          attribute.progressid === undefined || attribute.progressid === ''
            ? 'progressBulkModal'
            : attribute.progressid;
        scope.tabId = id;
      };
    };
    var controller = function ($scope, $interval, $http, $filter, $state) {
      var stop;
      var progress;
      $scope.percentage = 0;
      $scope.active = true;
      $scope.showProceed = false;
      $scope.counter = 0;
      $scope.bulkuploadProgressService = function () {
        return $http({
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          url: $scope.serviceurl,
          method: 'GET',
        });
      };
      $scope.startService = function () {
        if (angular.isDefined(stop)) {
          return;
        }
        $scope.active = true;
        $scope.showProceed = false;
        $scope.loading = true;
        var array = $scope.serviceurl.split('/');
        $scope.jobExecutionId = array[array.length - 2];
        var fileNameWithoutExt = array[array.length - 1];
        $scope.fileName = fileNameWithoutExt.replace('&middot', '.');
        stop = $interval(function () {
          // Make a call to Service to get the status and based on status call stop service
          var promise = $scope.bulkuploadProgressService();
          promise.then(
            function (data) {
              $scope.progressMessage = '';
              $scope.active = false;
              var testProceed = data.data.duplicateRegistration;
              if (testProceed === 'Y') {
                $scope.showProceed = true;
              } else {
                $scope.showProceed = false;
              }

              if (
                data.data.exitCode === 'FAILED' ||
                data.data.exitCode === 'COMPLETED'
              ) {
                $scope.exitCode = data.data.exitCode;
                $scope.percentage = 100;
                $scope.loading = false;
                $scope.progressStyle = { width: $scope.percentage + '%' };
                if (data.data.exitCode === 'COMPLETED') {
                  $scope.errorFound = false;
                  if (data.data.errorMessage) {
                    $scope.progressMessage = data.data.errorMessage;
                  } else if ($scope.actionTaken === 'inviteSharePlan') {
                    $scope.progressMessage = $filter('translate')(
                      'label_saveSuccessfullyStatus'
                    );
                  } else if (data.data.successMessage) {
                    $scope.progressMessage = data.data.successMessage;
                  } else {
                    $scope.progressMessage = $filter('translate')(
                      'bulkUpload_successMsg'
                    );
                  }
                } else {
                  $scope.errorFound = true;
                  if (
                    data.data.errorMessage &&
                    data.data.errorMessage !== null
                  ) {
                    var message = data.data.errorMessage;
                    $scope.progressMessage = message;
                  }
                }
                $scope.startProgressBar = false;
                $scope.stopService();
              }
            },
            function (error) {
              $scope.errorFound = true;
              $scope.percentage = 100;
              $scope.progressStyle = { width: $scope.percentage + '%' };
              $scope.active = false;
              $scope.progressMessage = error.message;
              $scope.stopService();
            }
          );
        }, 3000);
      };
      $scope.startProgressService = function () {
        if (angular.isDefined(progress)) {
          return;
        }
        $scope.startTime = new Date().getTime();
        progress = $interval(function () {
          if ($scope.percentage < 90) {
            $scope.percentage = $scope.percentage + 1;
            $scope.progressStyle = { width: $scope.percentage + '%' };
          } else {
            $scope.stopProgressService();
          }
        }, 200);
        var id = '#' + $scope.tabId;
        $(id).modal('show');
      };
      $scope.stopService = function () {
        if (angular.isDefined(stop)) {
          $interval.cancel(stop);
          stop = undefined;
          $scope.stopProgressService();
        }
      };
      $scope.stopProgressService = function () {
        if (angular.isDefined(progress)) {
          $interval.cancel(progress);
          progress = undefined;
        }
      };
      $scope.minimizeProgressBar = function () {
        var id = '#' + $scope.tabId;
        $(id).modal('hide');
        $(id).data('modal', null);
        var jobStatus = {};
        jobStatus.exitCode = $scope.exitCode;
        jobStatus.percentage = $scope.percentage;
        jobStatus.progressMessage = $scope.progressMessage;
        jobStatus.jobExecutionId = $scope.jobExecutionId;
        jobStatus.fileName = $scope.fileName;
        jobStatus.actionTaken = $scope.actionTaken;
        jobStatus.startTime = $scope.startTime;
        jobStatus.errorFound = $scope.errorFound || false;
        $scope.$root.$broadcast('onMinimize', jobStatus);
        $scope.startProgressBar = false;
        $scope.stopService();
      };
      $scope.$on('$destroy', function () {
        // Make sure that the interval is destroyed too
        $scope.stopService();
        $scope.stopProgressService();
      });
      $scope.progressStyle = { width: $scope.percentage + '%' };
      $scope.closeProgressBar = function () {
        $scope.progressMessage = '';
        $scope.percentage = 0;
        $scope.progressStyle = { width: $scope.percentage + '%' };
        if (
          ($scope.actionTaken === 'inviteSharePlan' ||
            $scope.actionTaken === 'bulkUpload') &&
          $scope.errorFound === false
        ) {
          $scope.inviteSharePlanSuccess = true;
        } else {
          $scope.inviteSharePlanSuccess = false;
        }
        if (
          $scope.actionTaken === 'dwtQrfBulkAction' &&
          $scope.errorFound === false
        ) {
          $state.go('dwtDetails', {
            selectedIndex: $scope.claimStateParms.selectedIndex,
            eventId: $scope.claimStateParms.eventId,
            flag: $scope.claimStateParms.flag,
            issuerId: $scope.claimStateParms.issuerId,
            issuerName: $scope.claimStateParms.issuerName,
            eventStatus: $scope.claimStateParms.eventStatus,
            claimsList: $scope.claimStateParms.claimsList,
            eventName: $scope.claimStateParms.eventName,
            dwtPerSecurity: $scope.claimStateParms.dwtPerSecurity,
            qrfEndDate: $scope.claimStateParms.qrfEndDate,
            peFlag: $scope.claimStateParms.peFlag,
            qrfFlag: $scope.claimStateParms.qrfFlag,
            extReqFlag: $scope.claimStateParms.extReqFlag,
            navFrom: $scope.claimStateParms.navFrom,
          });
        }
        var id = '#' + $scope.tabId;
        $(id).modal('hide');
        $('.modal-backdrop').remove();
        // destroy the modal
        $(id).data('modal', null);
        $scope.$root.$broadcast(
          'onProgressBarClose',
          $scope.jobStatus,
          $scope.inviteSharePlanSuccess
        );
      };
      $scope.$watch('startProgressBar', function (newValue, oldValue) {
        if (newValue === true) {
          if ($scope.jobStatus) {
            $scope.jobExecutionId = $scope.jobStatus.jobExecutionId;
            $scope.fileName = $scope.jobStatus.fileName;
            $scope.actionTaken = $scope.jobStatus.actionTaken;
            $scope.percentage = $scope.jobStatus.percentage;
            $scope.progressStyle = {
              width: $scope.percentage + '%',
            };
            $scope.progressMessage = $scope.jobStatus.progressMessage;
            $scope.errorFound = $scope.jobStatus.errorFound;
            if (
              $scope.jobStatus.exitCode === 'FAILED' ||
              $scope.jobStatus.exitCode === 'COMPLETED'
            ) {
              $scope.percentage = 100;
              $scope.progressStyle = {
                width: $scope.percentage + '%',
              };
              if ($scope.jobStatus.exitCode === 'COMPLETED') {
                $scope.errorFound = false;
                if (data.data.errorMessage) {
                  $scope.progressMessage = data.data.errorMessage;
                } else if ($scope.actionTaken === 'inviteSharePlan') {
                  $scope.progressMessage = $filter('translate')(
                    'label_saveSuccessfullyStatus'
                  );
                } else if (data.data.successMessage) {
                  $scope.progressMessage = data.data.successMessage;
                } else {
                  $scope.progressMessage = $filter('translate')(
                    'bulkUpload_successMsg'
                  );
                }
              } else {
                $scope.errorFound = true;
                $scope.progressMessage = $filter('translate')(
                  'bulkUpload_errorMsg'
                );
              }
              var id = '#' + $scope.tabId;
              $(id).modal('show');
              $scope.active = false;
            } else {
              var time = new Date().getTime() - $scope.jobStatus.startTime;
              time = time / 100 > 90 ? 90 : time / 100;
              $scope.percentage = Math.round(time);
              $scope.progressStyle = {
                width: $scope.percentage + '%',
              };
              $scope.active = true;
              $scope.startService();
              $scope.startProgressService();
            }
          } else {
            $scope.percentage = 0;
            $scope.progressStyle = {
              width: $scope.percentage + '%',
            };
            $scope.startService();
            $scope.startProgressService();
          }
        }
      });
    };
    directive.controller = [
      '$scope',
      '$interval',
      '$http',
      '$filter',
      '$state',
      controller,
    ];
    directive.templateUrl =
      'shared/directives/templates/progressbarBulkUpload-template.html';
    return directive;
  },
];
