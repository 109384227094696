import {
  em_folder_filled_search as folderFilledSearchSvg,
  em_folder_search as folderSearchSvg,
} from '@aab/sc-aab-icon-set/src/lib/emptyStates';
import './SearchShareholder';
import './ShareholderSearchResult';

import './searchShareholder.scss';

import searchShareholdersByEmail from '../../../lib/searchShareholdersByEmail';

export default {
  controller: [
    '$scope',
    '$http',
    function ($scope, $http) {
      this.pristineSvg = folderFilledSearchSvg;
      this.emptySvg = folderSearchSvg;
      this.searchInput = '';

      this.handleShareholderEmailInput = async (event) => {
        this.searchInput = event.detail.value;
        this.error = null;
        this.shareholderSearchResults = null;

        if (this.searchInput.length < 3) {
          return;
        }

        try {
          this.loading = true;
          const shareholders = await searchShareholdersByEmail(
            $http,
            this.searchInput
          );
          this.shareholderSearchResults = shareholders;
        } catch (error) {
          this.error = error.errors[0].errorMessage;
        } finally {
          this.loading = false;
          $scope.$apply();
        }
      };
    },
  ],
  templateUrl:
    '/features/modules/agent/user-management/views/shareholders/searchShareholder/searchShareholder.template.html',
};
