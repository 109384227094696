export default {
  bindings: {
    warningTime: '<',
    onCancel: '&',
  },
  controller: [
    '$timeout',
    function ($timeout) {
      this.$onInit = () => {
        this.warningTime ||= 8000;

        this.timeout = $timeout(() => {
          this.showTimeWarning = true;
        }, this.warningTime);
      };

      this.$onDestroy = () => {
        if (this.timeout) {
          $timeout.cancel(this.timeout);
        }
      };

      this.handleCancelClick = () => {
        this.onCancel();
      };
    },
  ],
  templateUrl:
    '/features/modules/cbp-ui/components/cbp-file-loading-background-cover/cbp-file-loading-background-cover.template.html',
};
