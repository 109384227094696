/* eslint-disable indent */
import { LitElement, html } from 'lit-element';
import styles from './AabTable.scss';

/**
 * What a table support/what can a table do?
 *
 * - displaying two dimensional data, of course
 * - flexibility in the contents of a cell (not only elements, but also rendering other kinds of elements)
 * - sortable
 * - optional pagination
 */

const ALIGNMENT_CLASS_MAP = {
  left: 'align-left',
  center: 'align-center',
  right: 'align-right',
};

export default class AabTable extends LitElement {
  static get properties() {
    return {
      caption: { type: String },
      columnHeaders: { type: Array },
      data: { type: Array },
      paginated: { type: Boolean },

      fullWidth: { type: Boolean },
      fixed: { type: Boolean },
      condensed: { type: Boolean },

      pageSize: { type: Number, attribute: false },
      pageNo: { type: Number, attribute: false },
    };
  }

  constructor() {
    super();
    this.pageNo = 0;
    this.pageSize = 10;
  }

  static get styles() {
    return [styles];
  }

  _handlePaginationChange(ev) {
    this.pageNo = ev.detail.value.pageNumber - 1;
    this.pageSize = ev.detail.value.itemsPerPage;
  }

  _renderCaption() {
    return html`<caption>
      ${this.caption}
    </caption>`;
  }

  _renderThead() {
    const ths = this.columnHeaders.map(
      (header) => html`
        <th
          class="${ALIGNMENT_CLASS_MAP[header.align || 'left']}"
          style="${header.width ? `width: ${header.width}px` : 'auto'}"
        >
          ${header.renderHeader ? header.renderHeader() : header.label}
        </th>
      `
    );

    return html`
      <thead>
        <tr>
          ${ths}
        </tr>
      </thead>
    `;
  }

  _renderedDataItems() {
    if (!this.paginated) {
      return this.data;
    } else {
      const offset = this.pageNo * this.pageSize;
      return this.data.slice(offset, offset + this.pageSize);
    }
  }

  _renderRows() {
    return this._renderedDataItems().map((rowItem, index) => {
      const rowContents = this.columnHeaders.map(
        (header) => html`
          <td class="${ALIGNMENT_CLASS_MAP[header.align || 'left']}">
            ${header.render
              ? header.render(rowItem, index + 1)
              : rowItem[header.key]}
          </td>
        `
      );

      return html`<tr>
        ${rowContents}
      </tr>`;
    });
  }

  _renderPagination() {
    if (!this.data.length) {
      return null;
    }

    return html` <aab-pagination
      selected-language="en"
      .itemsPerPage="${[10, 25, 50, 100]}"
      .selectedPageItem="${this.pageSize}"
      .selectedPageNumber="${this.pageNo + 1}"
      .totalItems="${this.data.length}"
      @aab-pagination-updated="${this._handlePaginationChange}"
    ></aab-pagination>`;
  }

  render() {
    return html`
      <table
        class="${this.fullWidth ? 'full-width' : ''} ${this.fixed
          ? 'table-fixed'
          : ''} ${this.condensed ? 'table-condensed' : ''}"
      >
        ${this.caption ? this._renderCaption() : ''}
        ${this.columnHeaders ? this._renderThead() : ''}
        ${this.columnHeaders ? this._renderRows() : ''}
      </table>

      ${this.paginated ? this._renderPagination() : ''}
    `;
  }
}
