import './AgentUsers';
import { listAgentUsers, clearUsersCache } from '@/api/v1/agent';
import { toggleUserDisabled } from '@/api/v1/users';

export default {
  controller: [
    '$state',
    '$http',
    '$scope',
    '$timeout',
    '$sessionStorage',
    function ($state, $http, $scope, $timeout, $sessionStorage) {
      this.$onInit = () => {
        this.fetchUsers().then(() => {
          $scope.$apply();
        });
      };

      this.fetchUsers = async () => {
        this.users = await listAgentUsers($http);
      };

      this.handleAddUserClick = () => {
        $state.go('agentUserManagement.agent.addUser');
      };

      this.handleUserClick = ({ detail: { userId } }) => {
        $state.go('agentUserManagement.agent.user', { userId });
      };

      this.handleEditUserClick = ({ detail: { userId } }) => {
        $state.go('agentUserManagement.agent.editUser', { userId });
      };

      this.toggleUserDisabled = async ({ detail: { userId } }) => {
        if (!confirm("Are you sure you want to update the user's status?")) {
          return;
        }
        await toggleUserDisabled(
          $http,
          'AG',
          1,
          userId,
          $sessionStorage.userId
        );
        clearUsersCache();
        this.users = null;
        $scope.$apply();

        $timeout(() => {
          this.fetchUsers().then(() => {
            $scope.$apply();
          });
        });
      };
    },
  ],
  template: `
    <div class="my-3">
      <div class="p-5" ng-if="!$ctrl.users">
        <cbp-loading-spinner></cbp-loading-spinner>
      </div>

      <agent-um-agent-users
        ng-if="$ctrl.users"
        ng-prop-users="$ctrl.users"
        ng-on-add-user-click="$ctrl.handleAddUserClick()"
        ng-on-user-click="$ctrl.handleUserClick($event)"
        ng-on-edit-user-click="$ctrl.handleEditUserClick($event)"
        ng-on-enable-click="$ctrl.toggleUserDisabled($event)"
        ng-on-disable-click="$ctrl.toggleUserDisabled($event)"
      ></agent-um-agent-users>
    </div>
  `,
};
