import { SID_COMMON_BASE_URL } from 'Modules/shareholders-id/constants';

import './sid-request-dl.scss';
import { formatDate } from '@/lib/date';

const controller = [
  '$element',
  '$http',
  'cbpToast',
  '$state',
  '$sessionStorage',
  '$scope',
  function ($element, $http, cbpToast, $state, $sessionStorage, $scope) {
    $scope.userType = $sessionStorage.usertype;

    this.$onChanges = (changeObj) => {
      if (changeObj.sidRequest?.currentValue) {
        const sidRequestCopy = angular.copy(changeObj.sidRequest.currentValue);
        this.reportDeadlineDateFormatted = formatDate(
          sidRequestCopy.reportDeadlineDate
        );
        this.disclosureDeadlineDateFormatted = formatDate(
            sidRequestCopy.disclosureDeadlineDate
        );
        this.recordDateFormatted = formatDate(sidRequestCopy.recordDate);
        this.startDateFormatted = formatDate(sidRequestCopy.startDate);
      }
    };
    this.canEditNumberOfShares = false;

    this.toggleCanEditOutstandingShares = () => {
      this.canEditNumberOfShares = !this.canEditNumberOfShares;
      if (this.canEditNumberOfShares === false) {
        $state.reload();
      }
    };

    this.closeModal = () => {
      this.setAction = null;
      const root = $element[0];
      const modal = root.querySelector('#updateModal');
      modal.closeModal(new Event('click'));
    };

    this.closeCopyCsdSharesModal = () => {
      this.setAction = null;
      const root = $element[0];
      const modal = root.querySelector('#copyCsdSharesModal');
      modal.closeModal(new Event('click'));
    };

    this.showUpdateModal = () => {
      const root = $element[0];
      const modal = root.querySelector('#updateModal');
      modal.openModal();
    };

    this.showCopyCsdSharesModal = () => {
      const root = $element[0];
      const modal = root.querySelector('#copyCsdSharesModal');
      modal.openModal();
    };

    this.updateSidRequest = (sidId, noOfShares) => {
      this.updateSidRequestRestCall(sidId, noOfShares).then(
        function (response) {
          $state.reload();
        },
        function (error) {
          if (error.data.errors[0].errorMessage) {
            cbpToast.show(error.data.errors[0].errorMessage, 'negative');
          } else {
            cbpToast.show('An unexpected error has occurred', 'negative');
          }
        }
      );
    };

    this.updateSidRequestRestCall = (sidId, noOfShares) => {
      return $http({
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        url: '/v2/sid/agent/sid-requests/' + sidId + '/updateOutstandingShares',
        method: 'PUT',
        data: {
          totalOutstandingShares: noOfShares,
        },
      });
    };
  },
];

export default {
  bindings: {
    sidRequest: '<',
    source: '<',
  },
  controller,
  templateUrl: `${SID_COMMON_BASE_URL}/components/sid-request-dl/sid-request-dl.template.html`,
};
