import './sid-request-report.scss';
import { chartColors } from '../../../../../cbp-ui/components/cbp-chart/cbp-chart.component';

export default {
  bindings: {
    totalOutstandingShares: '<',
    totalAmountOfSharesDisclosed: '<',
    beneAmountOfSharesDisclosed: '<',
    ooacAmountOfSharesDisclosed: '<',
    unknownAmountOfSharesDisclosed: '<',
  },
  controller: [
    function () {
      this.$onInit = () => {
        this.amountUndisclosed =
          this.totalOutstandingShares - this.totalAmountOfSharesDisclosed;

        this.undisclosedPercentage = parseFloat(
          (this.amountUndisclosed / this.totalOutstandingShares) * 100
        ).toFixed(2);

        this.benePercentage = parseFloat(
          (this.beneAmountOfSharesDisclosed / this.totalOutstandingShares) * 100
        ).toFixed(2);
        this.ooacPercentage = parseFloat(
          (this.ooacAmountOfSharesDisclosed / this.totalOutstandingShares) * 100
        ).toFixed(2);
        this.unknownPercentage = parseFloat(
          (this.unknownAmountOfSharesDisclosed / this.totalOutstandingShares) *
            100
        ).toFixed(2);

        this.data = {
          labels: [
            'Beneficial shareholding',
            'On Own Account',
            'Unknown',
            'Unidentified',
          ],
          datasets: [
            {
              data: [
                this.beneAmountOfSharesDisclosed,
                this.ooacAmountOfSharesDisclosed,
                this.unknownAmountOfSharesDisclosed,
                this.amountUndisclosed,
              ],
              backgroundColor: [
                chartColors[0].default,
                chartColors[1].default,
                chartColors[2].default,
                '#e8e8e8',
              ],
            },
          ],
        };

        //2Do Non disclosed
        //2Do below threshold

        this.options = {
          plugins: {
            tooltip: {
              callbacks: {
                title: (tooltipItem) =>
                  this.data.labels[tooltipItem[0].dataIndex],
                label: (tooltipItem) => {
                  const data = this.data.datasets[tooltipItem.datasetIndex]
                    .data[tooltipItem.dataIndex];
                  const percentage = Math.round(
                    (data / this.totalOutstandingShares) * 100
                  );

                  const formattedNumber = new Intl.NumberFormat().format(data);

                  return `${formattedNumber} - ${percentage}%`;
                },
              },
            },
          },
        };
      };
    },
  ],
  templateUrl:
    '/features/modules/shareholders-id/modules/common/components/sid-request-report/sid-request-report.template.html',
};
