/**
 * ```ts
 * interface TimelineEvent {
 *  date: date;
 *  description: string;
 *  magnitude: number;
 *  color: string;
 * }
 *
 * ```
 */

import './progress-timeline.scss';

function getPctBetweenStartAndEnd(date, start, end) {
  const startMs = start.getTime();
  const endMs = end.getTime();
  const total = endMs - startMs;
  const offset = date.getTime() - startMs;
  return Math.max(0, Math.min(1, offset / total));
}

export default {
  bindings: {
    // TimelineEvent[]
    events: '<',
    startDate: '<', // date
    endDate: '<', // date (optional, defaults to 'new Date()')
    progressDate: '<', // date (optional, defaults to new Date())

    height: '<',
    primaryColor: '@',
    secondaryColor: '@',
  },
  controller: [
    function () {
      this.$onInit = () => {
        if (!this.endDate) {
          this.endDate = new Date();
        }

        if (!this.progressDate) {
          this.progressDate = new Date();
        }

        if (!this.height) {
          this.height = 8;
        }
      };

      this.getPositionForTimelineEvent = (timelineEvent) => {
        const pct = getPctBetweenStartAndEnd(
          timelineEvent.date,
          this.startDate,
          this.endDate
        );
        const magnitude = timelineEvent.magnitude || 1;
        const radius = (this.height / 2) * magnitude;
        const offset = this.height / 2 - radius;
        return {
          position: 'absolute',
          top: `${offset}px`,
          left: `calc(${pct * 100}% - ${radius}px)`,
          zIndex: 10,
        };
      };

      this.getProgressWidth = () => {
        return `${
          getPctBetweenStartAndEnd(
            this.progressDate,
            this.startDate,
            this.endDate
          ) * 100
        }%`;
      };
    },
  ],
  templateUrl:
    '/features/modules/cbp-ui/components/progress-timeline/progress-timeline.template.html',
};
