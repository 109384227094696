import styles from './em-datetime-input.module.css';

export default {
  bindings: {
    value: '<',
    showSeconds: '<',
    onChange: '&',
  },
  controller: [
    '$element',
    '$timeout',
    function ($element, $timeout) {
      this.styles = styles;

      this.$onInit = () => {
        if (this.value) {
          this.updateModels(this.value);
        } else {
          this.internalDate = null;
          this.hh = undefined;
          this.mm = undefined;
          this.ss = undefined;
        }

        this.errorFields = {};

        this.timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
      };

      this.$onChanges = ({ value }) => {
        if (
          value.currentValue &&
          typeof value.currentValue.getMonth === 'function'
        ) {
          this.updateModels(value.currentValue);
        }
      };

      this.handleDateSelected = (ev) => {
        this.internalDate = new Date(ev.originalEvent.detail.value * 1000);
        this.handleChange();
      };

      this.updateModels = (date) => {
        this.internalDate = date;
        this.hh = date.getHours();
        this.mm = date.getMinutes();
        this.ss = date.getSeconds();

        // the html for the component might not have been loaded
        // so we wait a bit. Apparently, just having it be picking up
        // on the event loop is sufficient
        $timeout(() => {
          const datepicker = $element[0].querySelector('aab-datepicker');
          if (datepicker) {
            const input = datepicker.shadowRoot.querySelector('#from-input');
            if (input) {
              input.shadowRoot.querySelector(
                'input'
              ).value = `${date.getDate()}-${
                date.getMonth() + 1
              }-${date.getFullYear()}`;
            }
          }
        });
      };

      this.handleTimeChange = (val, name) => {
        val = parseInt(val, 10);

        if (isNaN(val)) {
          val = 0;
        }

        if (name === 'hh' && val > 23) {
          this.errorFields.hh = true;
          return;
        } else {
          this.errorFields.hh = false;
        }

        if ((name === 'mm' || name === 'ss') && val > 59) {
          this.errorFields[name] = true;
          return;
        } else {
          this.errorFields[name] = false;
        }

        this[name] = val;
        this.handleChange();
      };

      this.handleChange = () => {
        // Construct a valid date from the input we've gotten
        if (!this.internalDate) {
          this.errorFields.date = true;
          return;
        } else {
          this.errorFields.date = false;
        }

        const year = this.internalDate.getFullYear();
        const month = this.internalDate.getMonth();
        const day = this.internalDate.getDate();

        const { hh = 0, mm = 0, ss = 0 } = this;

        this.onChange({ value: new Date(year, month, day, hh, mm, ss) });
      };
    },
  ],
  templateUrl:
    '/features/modules/emerald/_modules/em-form-controls/em-datetime-input/em-datetime-input.template.html',
};
