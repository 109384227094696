import enTranslations from '@/locale/localeEn';
import nlTranslations from '@/locale/localeNl';
import frTranslations from '@/locale/localeFr';
import deTranslations from '@/locale/localeDe';
import esTranslations from '@/locale/localeEs';

function isApiRequest(config) {
  const apiRequestRegex = /\/?(v1|v2).*/;
  if (apiRequestRegex.test(config.url)) {
    return true;
  }

  if (config.url.includes('logout')) {
    return true;
  }

  if (config.url.includes('buildinfo.json')) {
    return true;
  }

  return false;
}

function apiUrl(url) {
  if (!url.startsWith('/')) {
    url = `/${url}`;
  }
  return `${process.env.API_BASE_URL}${url}`;
}

function getCookie(name) {
  for (const cookie of document.cookie.split(';')) {
    const [key, val] = cookie.trim().split('=').map(decodeURIComponent);
    if (name === key) {
      return val;
    }
  }
}

export default [
  '$httpProvider',
  '$translateProvider',
  'KeepaliveProvider',
  'IdleProvider',
  '$logProvider',
  'ngIntlTelInputProvider',
  'uibTimepickerConfig',
  function (
    $httpProvider,
    $translateProvider,
    KeepaliveProvider,
    IdleProvider,
    $logProvider,
    ngIntlTelInputProvider,
    uibTimepickerConfig
  ) {
    $logProvider.debugEnabled(false);
    uibTimepickerConfig.showSpinners = false;
    //International telephone number
    var obj = {
      initialCountry: 'nl',
      separateDialCode: 'true',
      autoPlaceholder: 'off',
    };
    ngIntlTelInputProvider.set(obj);

    if (process.env.API_BASE_URL) {
      $httpProvider.xsrfTrustedOrigins = [process.env.API_BASE_URL];
    }

    $httpProvider.interceptors.push([
      '$q',
      '$location',
      '$localStorage',
      '$sessionStorage',
      '$rootScope',
      function ($q, $location, $localStorage, $sessionStorage, $rootScope) {
        return {
          request: function (config) {
            config.headers = config.headers || {};
            var authToken = $sessionStorage.userId;
            if (typeof authToken !== 'undefined') {
              config.headers.AuthorizationID = 'Bearer:' + authToken;
            }
            var authType = $sessionStorage.usertype;
            if (typeof authType !== 'undefined') {
              config.headers.AuthorizationType = 'BearerType:' + authType;
            }

            if (process.env.API_BASE_URL && isApiRequest(config)) {
              config.url = apiUrl(config.url);
              config.withCredentials = true;

              config.headers['X-XSRF-TOKEN'] = getCookie('XSRF-TOKEN');

              // @anyone: Some request have an 'X-Internal-Auth-Token' header (with 'secureToken' as value)?
              // Since it's purpose is unclear, and it required setting an additonal CORS header on the
              // server, we just remove it from requests.
              delete config.headers['X-Internal-Auth-Token'];
            }

            return config;
          },
          response: function (response) {
            //function for decoding html encoded strings in response
            function decodeString(stringValue) {
              let div = document.createElement('div');
              div.innerHTML = stringValue;
              return div.textContent;
            }

            function decodeResponseData(object) {
              if (typeof object === 'object' && object !== null) {
                let itemsOrKeys = [];
                let isArray = Array.isArray(object);
                if (isArray) {
                  itemsOrKeys = object;
                } else {
                  itemsOrKeys = Object.keys(object);
                }

                for (let i = 0; i < itemsOrKeys.length; i++) {
                  let value = null;
                  if (isArray) {
                    value = object[i];
                  } else {
                    value = object[itemsOrKeys[i]];
                  }

                  if (typeof value === 'string') {
                    if (isArray) {
                      object[i] = decodeString(value);
                    } else {
                      object[itemsOrKeys[i]] = decodeString(value);
                    }
                  } else if (typeof value === 'object' && value !== null) {
                    decodeResponseData(value);
                  }
                }
              }
            }

            //decodes all JSON responses received from the backend with data in the response
            if (
              !response.config.url.contains('.html') &&
              !response.config.url.contains('.js') &&
              response.config.headers.Accept.contains('application/json') &&
              response.data != null
            ) {
              if (typeof response.data === 'object') {
                // decodeResponseData([], [], 0, response.data);
                decodeResponseData(response.data);
              } else if (typeof response.data === 'string') {
                response.data = decodeString(response.data);
              }
            }
            //returns transformed response
            return response;
          },
          responseError: function (response) {
            if (
              response.status === 500 &&
              response.config.method === 'POST' &&
              new RegExp(
                'v2/gm/agent/general-meetings/(.*)/shareclasses/(.*)/vote-instruction-execution-confirmation/intermediary'
              ).test(response.config.url)
            ) {
              // Timeout of the vote execution confirmation is expected
              return;
            }

            if (
              response.status >= 500 ||
              (response.status === 422 &&
                undefined !== response.data.errors &&
                'CBP_ERR_492' === response.data.errors[0].errorCode)
            ) {
              if (
                $rootScope.globalErrorOpened === false ||
                $rootScope.globalErrorOpened === undefined
              ) {
                $('#globalError').modal('show');
                $rootScope.globalErrorOpened = true;
              }
            }

            if (response.status === 403) {
              if (
                $rootScope.globalErrorOpened === false ||
                $rootScope.globalErrorOpened === undefined
              ) {
                $('#accessDeniedError').modal('show');
                $rootScope.globalErrorOpened = true;
                // $location.path(process.env.API_BASE_URL);
              }
            }

            if (response.status === 401) {
              var loginUrl;
              //window.location.path is used to check if user is trying to login with deep link url
              if (
                $sessionStorage.workstationData &&
                $sessionStorage.workstationData.menuData &&
                (window.location.href.indexOf('/shareholderlogin?') > -1 ||
                  $sessionStorage.fromState.url !== '/shGeneralMeetings')
              ) {
                loginUrl =
                  '/' + $sessionStorage.workstationData.menuData.logOut.url;
              }
              $sessionStorage.workstationData = {};
              $sessionStorage.$reset();
              delete $sessionStorage.token;
              if (loginUrl != undefined) {
                $location.path(loginUrl);
              }
            }

            if (response.status === -1) {
              $('#nwkError').modal('show');
            }
            return $q.reject(response);
          },
        };
      },
    ]);

    $translateProvider.translations('EN', enTranslations);
    $translateProvider.translations('NL', nlTranslations);
    $translateProvider.translations('FR', frTranslations);
    $translateProvider.translations('DE', deTranslations);
    $translateProvider.translations('ES', esTranslations);

    $translateProvider.preferredLanguage('EN');
    $translateProvider.useSanitizeValueStrategy('escape');

    //To set the idle time and timeout time
    IdleProvider.idle(900); //15 minutes 15 * 60
    IdleProvider.timeout(300); //5 minutes 5 * 60
    KeepaliveProvider.interval(300); //5 minutes 5 * 60
  },
];
