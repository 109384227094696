import { listUsers, fetchUser } from './users';

// Agent data can quite easily be cached. Its (relatively) lightweight, since there's only ever one agent
let agentCache = null;
let usersCache = null;

export async function fetchAgent($http) {
  if (agentCache) {
    return agentCache;
  }
  const response = await $http.get('/v1/agents/1/group/GM/EN');
  agentCache = response.data;
  return response.data;
}

export async function listAgentUsers($http) {
  if (usersCache) {
    return usersCache;
  }
  const agent = await fetchAgent($http);
  const users = await listUsers($http, 'AG', agent.userGroupId);
  usersCache = users;
  return users;
}

export async function fetchAgentUser($http, userId) {
  const agent = await fetchAgent($http);
  return await fetchUser($http, 'AG', agent.userGroupId, userId);
}

export function clearAgentCache() {
  agentCache = null;
}

export function clearUsersCache() {
  usersCache = null;
}

export async function updateAgent($http, agentId, payload) {
  const fd = new FormData();
  fd.append('groupName', payload.username);
  fd.append('userVO.workPhone', payload.personalPhone);
  fd.append('userId', payload.userGroupId);
  fd.append('groupId', 1);
  fd.append('groupEmail', payload.emailAddress);
  fd.append('city', payload.city);
  fd.append('country', payload.country);
  fd.append('productCode', 'GM');

  const { data } = await $http.post(`/v1/agents/${agentId}`, fd, {
    headers: {
      'Content-Type': undefined,
    },
  });
  clearAgentCache();
  return data;
}

export async function addAgentUser($http, payload) {
  const { data } = await $http.post(`/v1/users/create`, payload);
  clearUsersCache();
  return data;
}
