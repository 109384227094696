import { LitElement, html } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class VoteCollectorUser extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      user: { type: Object },
    };
  }

  static get scopedElements() {
    return {
      'aab-typography': customElements.get('aab-typography'),
      'user-details': customElements.get('agent-um-user-details'),
      'agent-um-simple-tile': customElements.get('agent-um-simple-tile'),
    };
  }

  render() {
    if (!this.user) {
      return null;
    }

    return html`
      <agent-um-simple-tile title="VoteCollector user: ${this.user.email}">
        <aab-typography>
          <user-details .user=${this.user}></user-details>
        </aab-typography>
      </agent-um-simple-tile>
    `;
  }
}

customElements.define('agent-um-vote-collector-user', VoteCollectorUser);
