export default [
  '$http',
  function ($http) {
    return function (resourceUrl) {
      this.resourceUrl = resourceUrl;
      this.resourceUrlPrefix = '/v2';

      this.resolvedResourceUrl = (pathParams) => {
        let resource = this.resourceUrl;

        //Replace all the path param placeholder {}
        if (pathParams) {
          resource = resource.replace(/{\w+}/g, function (p) {
            let objParam = p.replace(/[{}]/g, '');
            return pathParams[objParam] || p;
          });
        }

        return `${this.resourceUrlPrefix}${resource}`;
      };

      this.list = async function (params, pathParams) {
        return (
          await $http.get(this.resolvedResourceUrl(pathParams), { params })
        ).data;
      };

      this.getById = async function (id, params, pathParams) {
        return (
          await $http.get(`${this.resolvedResourceUrl(pathParams)}/${id}`, {
            params,
          })
        ).data;
      };

      this.create = async function (body, opts, pathParams) {
        try {
          return (
            await $http.post(this.resolvedResourceUrl(pathParams), body, opts)
          ).data;
        } catch (err) {
          if (err.data.errors) {
            throw new Error(err.data.errors[0].errorMessage);
          } else {
            throw new Error(
              err.data.errorMessage + ' (' + err.data.errorCode + ')'
            );
          }
        }
      };

      this.updateById = async function (id, changes, opts, pathParams) {
        try {
          return (
            await $http.put(
              `${this.resolvedResourceUrl(pathParams)}/${id}`,
              changes,
              opts
            )
          ).data;
        } catch (err) {
          if (err.data.errors) {
            throw new Error(err.data.errors[0].errorMessage);
          } else {
            throw new Error(
              err.data.errorMessage + ' (' + err.data.errorCode + ')'
            );
          }
        }
      };

      this.deleteById = async function (id, opts, pathParams) {
        return (
          await $http.delete(
            `${this.resolvedResourceUrl(pathParams)}/${id}`,
            opts
          )
        ).data;
      };
    };
  },
];
