import './AddProxyIntermediaryView';

export default {
  controller: [
    '$state',
    function ($state) {
      this.$onInit = () => {
        $state.go('userMaintenance.addProxyGroup');
      };
    },
  ],
  template: `
    <div class="my-3">
      <agent-um-add-proxy-intermediary-view></agent-um-add-proxy-intermediary-view>
    </div>
  `,
};
