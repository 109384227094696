import routes from './routes';

import agUserManagementMain from './views/agUserManagementMain.component';

import * as agentViews from './views/agent/componentRegistration';
import * as issuerViews from './views/issuers/componentRegistration';
import * as intermediaryViews from './views/intermediaries/componentRegistration';
import * as proxyIntermediaryViews from './views/proxyIntermediaries/componentRegistration';
import * as voteCollectorViews from './views/voteCollectors/componentRegistration';
import * as shareholderViews from './views/shareholders/componentRegistration';

// Generic components and layouts
import './components/AgentUserInputForm';
import './components/IssuerInputForm';
import './components/StatusMessageRenderer';
import './components/UserDetails';
import './components/UserGroupTableActions';
import './components/UserInputForm';
import './components/UserTableActions';

import './layouts/ButtonAndInputTile';
import './layouts/SimpleTile';

export default angular
  .module('agentUserManagement', [])
  .config(routes)
  .component({
    agUserManagementMain,

    ...agentViews,
    ...issuerViews,
    ...intermediaryViews,
    ...proxyIntermediaryViews,
    ...voteCollectorViews,
    ...shareholderViews,
  });
