import './EditIntermediaryUser';
import { fetchUser, updateUser } from '@/api/v1/users';

export default {
  controller: [
    '$http',
    '$scope',
    '$stateParams',
    function ($http, $scope, $stateParams) {
      this.$onInit = () => {
        this.fetchUser().then(() => {
          $scope.$apply();
        });
      };

      this.fetchUser = async () => {
        this.user = await fetchUser(
          $http,
          'IN',
          $stateParams.intermediaryId,
          $stateParams.userId
        );
      };

      this.handleSaveClick = async ({ detail }) => {
        try {
          const response = await updateUser($http, detail.userId, {
            blockGroupEmail: detail.groupMail,
            defaultLanguage: 'EN',
            emailAddress: detail.email,
            firstName: detail.firstName,
            isAdmin: detail.admin,
            lastName: detail.lastName,
            loggedInUserId: $sessionStorage.userId,
            personalPhone: detail.personalPhoneNo,
            prodType: 'GM',
            status: detail.status,
            title: detail.title,
            userId: detail.userId,
            userGroupId: $sessionStorage.agentId,
            userType: 'AG',
            workPhone: detail.officePhoneNo,
          });
          this.status = 'success';
          this.statusMessage = response.message;
        } catch (err) {
          this.status = 'error';
          this.statusMessage = err.data.errors.map((e) => e.errorMessage);
        } finally {
          $scope.$apply();
        }
      };
    },
  ],
  template: `
    <div class="my-3">
      <div class="p-5" ng-if="!$ctrl.user">
        <cbp-loading-spinner></cpb-loading-spinner>
      </div>

      <agent-um-edit-intermediary-user
        ng-prop-user="$ctrl.user"
        ng-on-save-click="$ctrl.handleSaveClick($event)"
      >
        <agent-um-status-message-renderer
          ng-prop-status="$ctrl.status"
          ng-prop-status_message="$ctrl.statusMessage"
        ></agent-um-status-message-renderer>
      </agent-um-edit-intermediary-user>
    </div>
  `,
};
