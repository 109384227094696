export const SID_BASE_URL = 'features/modules/shareholders-id';
export const SID_COMMON_BASE_URL = `${SID_BASE_URL}/modules/common`;
export const SID_AG_BASE_URL = `${SID_BASE_URL}/modules/agent`;
export const SID_IS_BASE_URL = `${SID_BASE_URL}/modules/issuer`;
export const SID_IN_BASE_URL = `${SID_BASE_URL}/modules/intermediary`;

export const DISCLOSURE_DEADLINE_DATE_MIN_DAYS_LATER = 7;
export const MILLISECONDS_IN_A_DAY = 86400000;
export const REPORT_DEADLINE_DATE_MIN_DAYS_LATER = 14;
export const RECORD_DATE_MAX_MONTHS_EARLIER = 12;
export const RECORD_DATE_MAX_DAYS_LATER = 30;
export const START_DATE_MAX_MONTHS_LATER = 2;
export const ROLE_ISSUER = 'issuer';
export const ROLE_AGENT = 'agent';
export const DEADLINE_DAYS_DELAY_FOR_ROLE = {
  agent: 0,
  issuer: REPORT_DEADLINE_DATE_MIN_DAYS_LATER
};
