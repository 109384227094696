import { SID_IS_BASE_URL } from 'Modules/shareholders-id/constants';
import { formatDate } from '@/lib/date';

const Ctrl = [
  'IS_SidRequestRepository',
  '$scope',
  '$state',
  function (SidRequestRepository, $scope, $state) {
    this.$onInit = () => {
      this.sidRequestsLandingPageState = {
        pageNo: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
        loading: false,
        status: 'all',
        sidRequests: [],
      };

      this.statusTabs = [
        {
          key: 'all',
          label: 'All',
        },
        {
          key: 'draft',
          label: 'Draft',
        },
        {
          key: 'awaiting_agent_approval',
          label: 'Awaiting agent approval',
        },
        {
          key: 'awaiting_issuer_approval',
          label: 'Awaiting issuer approval',
        },
        {
          key: 'approved',
          label: 'Approved',
        },
        {
          key: 'rejected',
          label: 'Rejected',
        },
        {
          key: 'in_progress',
          label: 'In progress',
        },
        {
          key: 'completed',
          label: 'Completed',
        },
        {
          key: 'cancelled',
          label: 'Cancelled',
        },
      ];

      this.fetchSidRequests();
    };

    this.constructParams = () => {
      const { pageNo, pageSize, status } = this.sidRequestsLandingPageState;
      const params = {
        pageNo,
        pageSize,
      };
      if (status !== 'all') {
        params.status = status;
      }
      params.sortBy = 'plannedStartDate';
      return params;
    };

    this.fetchSidRequests = async () => {
      this.sidRequestsLandingPageState.loading = true;
      try {
        const page = await SidRequestRepository.list(this.constructParams());
        this.formatDates(page.content);
        this.sidRequestsLandingPageState.sidRequests = page.content;
        this.sidRequestsLandingPageState.totalElements = page.totalElements;
      } catch (err) {
        this.error = err;
      } finally {
        this.sidRequestsLandingPageState.loading = false;
        $scope.$apply();
      }
    };

    this.openSidRequest = (sidRequest) => {
      if (sidRequest.status === 'DRAFT') {
        $state.go('shareholders-id.issuer.sidRequestDetails.edit', {
          sidRequestId: sidRequest.sidRequestId,
        });
      } else if (
        sidRequest.status === 'IN_PROGRESS' ||
        sidRequest.status === 'COMPLETED'
      ) {
        $state.go('shareholders-id.issuer.sidRequestDetails.results.overview', {
          sidRequestId: sidRequest.sidRequestId,
        });
      } else {
        $state.go('shareholders-id.issuer.sidRequestDetails.overview', {
          sidRequestId: sidRequest.sidRequestId,
        });
      }
    };

    this.handlePaginationChanged = (event) => {
      const { itemsPerPage, pageNumber } = event.detail.value;
      if (
        itemsPerPage === this.sidRequestsLandingPageState.pageSize &&
        pageNumber === this.sidRequestsLandingPageState.pageNo
      ) {
        // Nothing changed
        return;
      }

      this.sidRequestsLandingPageState.pageSize = itemsPerPage;
      this.sidRequestsLandingPageState.pageNo = pageNumber;
      this.fetchSidRequests();
    };

    this.handleStatusChanged = (status) => {
      this.sidRequestsLandingPageState.status = status;
      this.sidRequestsLandingPageState.pageNo = 1;
      this.sidRequestsLandingPageState.sidRequests = [];
      this.fetchSidRequests();
    };

    this.formatDates = (sidRequests) => {
      for (let item in sidRequests) {
        if (sidRequests.hasOwnProperty(item)) {
          sidRequests[item].recordDate = formatDate(
            sidRequests[item].recordDate,
            'EN'
          );
          sidRequests[item].dateCreated = formatDate(
            sidRequests[item].dateCreated,
            'EN'
          );
        }
      }
    };
  },
];

export default {
  controller: Ctrl,
  templateUrl: `${SID_IS_BASE_URL}/views/landing-page/sid-issuer-landing-page.template.html`,
};
