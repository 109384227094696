import { LitElement, html, css } from 'lit-element';

export default class AabTile extends LitElement {
  static get styles() {
    return css`
      .root {
        border-radius: 4px;
        background-color: white;
        box-shadow: 0 -2px 4px 0 rgb(34 34 34 / 2%),
          0 2px 2px 0 rgb(34 34 34 / 15%) !important;
      }
    `;
  }

  render() {
    return html`
      <div class="root">
        <slot></slot>
      </div>
    `;
  }
}
