import './VoteCollectorUsers.js';
import { listUsers, toggleUserDisabled } from '@/api/v1/users';

export default {
  controller: [
    '$state',
    '$stateParams',
    '$scope',
    '$http',
    function ($state, $stateParams, $scope, $http) {
      this.$onInit = () => {
        this.fetchUsers().then(() => {
          $scope.$apply();
        });
      };

      this.fetchUsers = async () => {
        this.users = await listUsers($http, 'IS', $stateParams.voteCollectorId);
      };

      this.handleAddUserClick = () => {
        $state.go('agentUserManagement.voteCollectors.addUser', {
          voteCollectorId: $stateParams.voteCollectorId,
        });
      };

      this.handleUserClick = ({ detail: { userId } }) => {
        $state.go('agentUserManagement.voteCollectors.user', {
          voteCollectorId: $stateParams.voteCollectorId,
          userId,
        });
      };

      this.handleEditUserClick = ({ detail: { userId } }) => {
        $state.go('agentUserManagement.voteCollectors.editUser', {
          voteCollectorId: $stateParams.voteCollectorId,
          userId,
        });
      };

      this.toggleUserDisabled = async ({ detail: { userId } }) => {
        if (!confirm("Are you sure you want to update the user's status?")) {
          return;
        }
        await toggleUserDisabled(
          $http,
          'VC',
          $stateParams.voteCollectorId,
          userId,
          $sessionStorage.userId
        );
        this.users = null;
        $scope.$apply();

        $timeout(() => {
          this.fetchUsers().then(() => {
            $scope.$apply();
          });
        });
      };
    },
  ],
  template: `
  <div class="my-3">
    <div class="p-5" ng-if="!$ctrl.users">
      <cbp-loading-spinner></cbp-loading-spinner>
    </div>
    
    <agent-um-vote-collector-users
      ng-if="$ctrl.users"
      ng-prop-users="$ctrl.users"
      ng-on-user-click="$ctrl.handleUserClick($event)"
      ng-on-add-user-click="$ctrl.handleAddUserClick($event)"
      ng-on-edit-user-click="$ctrl.handleEditUserClick($event)"
      ng-on-enable-click="$ctrl.toggleUserDisabled($event)"
      ng-on-disable-click="$ctrl.toggleUserDisabled($event)"
    ></agent-um-vote-collector-users>
  </div>

  `,
};
