export default [
  'CbpResourceRepositoryFactory',
  '$http',
  function (CbpResourceRepositoryFactory, $http) {
    const repository = new CbpResourceRepositoryFactory('/agent/issuers');

    let gmListCache = null;
    let allListCache = null;
    repository.listAllGM = async () => {
      if (gmListCache) {
        return gmListCache;
      } else {
        const response = await repository.list({
          pageNo: 0,
          productCode: 'GM',
          active: true,
        });
        gmListCache = response.content.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        return gmListCache;
      }
    };

    repository.listAll = async () => {
      if (allListCache) {
        return allListCache;
      } else {
        const response = await repository.list({
          pageNo: 0,
          active: true,
        });
        allListCache = response.content.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        return allListCache;
      }
    };

    return repository;
  },
];
