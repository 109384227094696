const { BASE_URL } = require('../constants');

require('./em-select.scss');

/**
 * Options should be an object where:
 *
 *  { 'VALUE1': 'NAME1', 'VALUE2': 'NAME2'", ..." } is mapped to:
 *
 * <option value="VALUE1">NAME</option> elements
 */

const Ctrl = [
  function () {
    this.$onInit = () => {
      if (!this.options) {
        this.options = [];
      }

      this.inputId = String(Math.random() * 21349087);
    };

    this.$onChanges = (changeObj) => {
      if (changeObj.options?.currentValue) {
        const options = changeObj.options.currentValue;
        this.aabSelectOptions = Object.keys(options).map((key) => ({
          value: key,
          name: options[key],
        }));
      }
    };

    this.handleChange = (event) => {
      const { value } = event.detail;
      this.onChange({
        value,
        name: this.name,
      });
    };
  },
];

module.exports = {
  bindings: {
    label: '@',
    value: '<',
    options: '<',
    name: '@',
    disabled: '<',
    placeholder: '@',
    isInvalid: '<',

    onChange: '&',
  },
  controller: Ctrl,
  templateUrl: `${BASE_URL}/em-select/em-select.template.html`,
};
