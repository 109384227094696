export default [
  '$stateProvider',
  function ($stateProvider) {
    /**
     * Agent states
     */
    const agentMainState = {
      name: 'shareholders-id.agent',
      url: '/agent',
      template: '<div ui-view></div>',
      redirectTo: 'shareholders-id.agent.landing',
    };
    const agentLandingState = {
      name: 'shareholders-id.agent.landing',
      component: 'sidAgentLandingPage',
      url: '/list',
    };
    const agentCreateRequestState = {
      name: 'shareholders-id.agent.createRequest',
      component: 'sidAgentCreateRequest',
      url: '/create-request',
    };
    const agentSidRequestDetailsState = {
      name: 'shareholders-id.agent.sidRequestDetails',
      component: 'sidAgentRequestDetails',
      url: '/sid-request/:sidRequestId',
    };

    const agentSidRequestDetailsOverviewState = {
      name: 'shareholders-id.agent.sidRequestDetails.overview',
      component: 'sidAgentRequestDetailsOverview',
      url: '/overview',
      params: {
        'shareholdingTypes': 'BENE,OOAC,UKWN,NONE'
      },
    };

    const agentSidRequestDetailsOverviewEditState = {
      name: 'shareholders-id.agent.sidRequestDetails.edit',
      component: 'sidAgentRequestDetailsOverviewEdit',
      url: '/edit',
    };

    const agentSidRequestDetailsResultsState = {
      name: 'shareholders-id.agent.sidRequestDetails.results',
      component: 'sidAgentRequestDetailsResults',
      url: '/results',
      redirectTo: 'shareholders-id.agent.sidRequestDetails.results.overview',
    };

    const agentSidRequestDetailsResultsOverviewState = {
      name: 'shareholders-id.agent.sidRequestDetails.results.overview',
      component: 'sidAgentResultsShareholderReportsOverview',
      url: '/overview',
      params: {
        'shareholdingTypes': 'BENE,OOAC,UKWN,NONE'
      },
    };

    const agentSidRequestDetailsResultsNomiState = {
      name: 'shareholders-id.agent.sidRequestDetails.results.nomi',
      component: 'sidAgentResultsShareholderReportsNomi',
      url: '/nomi',
    };

    const agentSidRequestDetailsResultsDetailsState = {
      name: 'shareholders-id.agent.sidRequestDetails.results.details',
      component: 'sidAgentResultsShareholderDetails',
      url: '/details/:itemIds',
    };

    $stateProvider.state(agentMainState);
    $stateProvider.state(agentLandingState);
    $stateProvider.state(agentCreateRequestState);
    $stateProvider.state(agentSidRequestDetailsState);
    $stateProvider.state(agentSidRequestDetailsOverviewState);
    $stateProvider.state(agentSidRequestDetailsOverviewEditState);

    $stateProvider.state(agentSidRequestDetailsResultsState);
    $stateProvider.state(agentSidRequestDetailsResultsOverviewState);
    $stateProvider.state(agentSidRequestDetailsResultsNomiState);
    $stateProvider.state(agentSidRequestDetailsResultsDetailsState);
  },
];
