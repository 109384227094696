import { BASE_URL } from '../../constants';

import './cbp-tabs.scss';

const tabsToAabTabs = (tabs) =>
  tabs.map((tab) => ({
    id: tab.key,
    title: tab.label,
    disabled: !!tab.disabled,
  }));

/**
 * tabs must looks like:
 * {
 *   key, label, badge
 * }
 */
const Ctrl = [
  function () {
    this.$onInit = () => {
      this.aabTabs = tabsToAabTabs(this.tabs);
    };

    this.$onChanges = () => {
      this.aabTabs = tabsToAabTabs(this.tabs);
    };

    this.handleTabClicked = (ev) => {
      this.onChange({ tab: ev.target.selected });
    };
  },
];

export default {
  bindings: {
    tabs: '<',
    selectedTab: '@',
    fullWidth: '<',
    onChange: '&',
    scrolling: '<',
  },
  controller: Ctrl,
  templateUrl: `${BASE_URL}/components/cbp-tabs/cbp-tabs.template.html`,
};
