import { SID_AG_BASE_URL } from 'Modules/shareholders-id/constants';

import './sid-request-tile.scss';

const Ctrl = [
  function () {
    this.statusMap = {
      draft: 'Draft',
      awaiting_agent_approval: 'Awaiting agent approval',
      pending: 'Pending',
      completed: 'Completed',
    };

    this.iconName = () => {
      if (this.sidRequest.status === 'completed') {
        return 'pr-profiles-family';
      }
      if (this.sidRequest.status === 'draft') {
        return 'pr-paper-marker';
      } else {
        return 'pr-documents-search';
      }
    };

    this.thresholdPct = () => {
      return this.sidRequest.shareThreshold;
    };
  },
];

export default {
  bindings: {
    sidRequest: '<',
    showArrow: '<',
  },
  controller: Ctrl,
  templateUrl: `${SID_AG_BASE_URL}/components/sid-request-tile/sid-request-tile.template.html`,
};
