const { BASE_URL } = require('../../../constants');

require('./em-product-tile-2.scss');

module.exports = {
  bindings: {
    icon: '@',
    imageSrc: '@',
    class: '@',
  },
  transclude: {
    image: '?emProductTile2Image',
    left: '?emProductTile2Left',
    middle: '?emProductTile2Middle',
    right: '?emProductTile2Right',
  },
  templateUrl: `${BASE_URL}/_modules/emerald-2/em-product-tile-2/em-product-tile-2.template.html`,
};
