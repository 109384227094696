import { SID_IS_BASE_URL } from 'Modules/shareholders-id/constants';

const Ctrl = [
  '$stateParams',
  function ($stateParams) {
    this.$onInit = () => {
      this.sidRequestId = $stateParams.sidRequestId;
    };

    this.handleToggle = (opened) => {
      this.onToggle({ opened });
    };
  },
];

export default {
  bindings: {
    shareholder: '<',
    boDetails: '<',
    onToggle: '&',
  },
  controller: Ctrl,
  templateUrl: `${SID_IS_BASE_URL}/components/sid-request-report-shareholder-tile/sid-request-report-shareholder-tile.template.html`,
};
