const controller = [
  'IS_SidResponseItemRepository',
  'cbpToast',
  '$stateParams',
  '$scope',
  function (SidResponseItemRepository, cbpToast, $stateParams, $scope) {
    this.$onInit = () => {
      this.sidRequestId = $stateParams.sidRequestId;
      this.itemIds = $stateParams.itemIds.split(',');

      this.responseItems = [];
      this.fetching = false;

      this.fetchSidResponseItems();
    };

    this.fetchSidResponseItems = async () => {
      const promises = this.itemIds.map((id) => {
        return SidResponseItemRepository.getById(this.sidRequestId, id);
      });

      this.fetching = false;
      try {
        this.responseItems = await Promise.all(promises);
      } catch (err) {
        cbpToast.show(JSON.stringify(err), 'danger');
      } finally {
        this.fetching = false;
        $scope.$apply();
      }
    };
  },
];

export default {
  controller,
  templateUrl:
    '/features/modules/shareholders-id/modules/issuer/views/sid-request-details/results/shareholder-details/shareholder-details.template.html',
};
