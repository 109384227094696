export default {
  bindings: {
    /**
     * value: {
     *  sortBy: string,
     *  sortDirection: string
     * }
     */
    value: '<',
    onChange: '&',
  },
  controller: [
    function () {
      const sortMap = {
        'Shareholding quantity': 'sum_quantity_unit',
        Name: 'names',
        Country: 'countries',
        'Shareholding type': 'shareholding_type',
        'Entity type': 'entity_type',
      };

      this.$onInit = () => {
        this.sortOptions = Object.keys(sortMap);
      };

      this.selectedSortOption = () => {
        return {
          sum_quantity_unit: 'Shareholding quantity',
          names: 'Name',
          countries: 'Country',
          shareholding_type: 'Shareholding type',
          entity_type: 'Entity type',
        }[this.value.sortBy];
      };

      this.handleSelectEvent = (name, payload) => {
        this.onChange({
          newValue: {
            ...this.value,
            sortBy: sortMap[payload.value],
          },
        });
      };
    },
  ],
  templateUrl:
    '/features/modules/shareholders-id/modules/agent/components/sid-response-shareholder-reports-filter/sid-response-shareholder-reports-filter.template.html',
};
