import './AddProxyIntermediaryUser';
import { createUser } from '@/api/v1/users';

export default {
  controller: [
    '$http',
    '$sessionStorage',
    '$scope',
    '$stateParams',
    function ($http, $sessionStorage, $scope, $stateParams) {
      this.handleSaveClick = async (ev) => {
        const data = ev.detail;
        this.status = 'pending';
        try {
          const response = await createUser($http, {
            blockGroupEmail: 'N',
            defaultLanguage: 'EN',
            emailAddress: data.email,
            firstName: data.firstName,
            isAdmin: data.admin ? 'Y' : 'N',
            lastName: data.lastName,
            loggedInUserId: $sessionStorage.userId,
            personalPhone: data.personalPhoneNo,
            prodType: 'GM',
            status: 'P',
            title: data.title,
            userGroupId: $stateParams.proxyIntermediaryId,
            userId: $sessionStorage.userId,
            userType: 'IN',
            workPhone: data.officePhoneNo,
          });
          this.status = 'success';
          this.statusMessage = response.message;
        } catch (err) {
          this.status = 'error';
          this.statusMessage = err.data.errors.map((e) => e.errorMessage);
        } finally {
          $scope.$apply();
        }
      };
    },
  ],
  template: `
    <div class="my-3">
      <agent-um-add-proxy-intermediary-user
        ng-on-save-click="$ctrl.handleSaveClick($event)"
      >
        <agent-um-status-message-renderer
          ng-prop-status="$ctrl.status"
          ng-prop-status_message="$ctrl.statusMessage"
        ></agent-um-status-message-renderer>
      </agent-um-add-proxy-intermediary-user>
    </div>
  `,
};
