import * as changeCase from 'change-case';
import { formatDate, formatDateTimeNoTimezone } from './date';
import formatNumber from './formatNumber';

/**
 * Function to format and humanize properties in a map
 *
 * @param {map[]} map - data containing properties to be humanized
 * @param {string[]} properties - list of properties to humanize
 * @returns humanized string properties
 */
export const formatStringMapValues = (map, properties) => {
  for (const prop of properties) {
    if (map[prop]) {
      map[prop] = changeCase.capitalCase(map[prop]);
    }
  }
};

/**
 * Function to round up integer properties in a map
 *
 * @param {map[]} map - data containing properties to be rounded up
 * @param {string[]} properties - list of properties to round up
 * @returns updated map with rounded up integers
 */
export const formatNumberMapValues = (map, properties) => {
  for (const prop of properties) {
    if (map[prop]) {
      map[prop] = formatNumber(Math.round(map[prop]));
    }
  }
};

/**
 * Function to round up integer properties in a map
 *
 * @param {map[]} map - data containing properties to be rounded up
 * @param {string[]} properties - list of properties to round up
 * @returns updated map with rounded up integers
 */
export const formatPercentageMapValues = (map, properties) => {
  for (const prop of properties) {
    if (map[prop]) {
      map[prop] = formatNumber(Math.round(map[prop]));
    }
  }
};

/**
 * Function to format and humanize properties in a map
 *
 * @param {map[]} map - data containing properties to be humanized
 * @param {string[]} properties - list of properties to humanize
 * @param {string} languageCode - the language code
 * @returns humanized string properties
 */
export const formatDateMapValues = (map, properties, format, languageCode) => {
  for (const prop of properties) {
    if (map[prop]) {
      if (format === 'dateTime') {
        map[prop] = formatDateTimeNoTimezone(map[prop], languageCode);
      } else {
        map[prop] = formatDate(map[prop], languageCode);
      }
    }
  }
};

/**
 * Function to format keys in a map
 *
 * @param {map[]} map - data containing keys to be formatted
 * @returns formatted data
 */
export const formatMapKeys = (map) => {
  if (map) {
    for (let key of Object.keys(map)) {
      const newKey = changeCase.capitalCase(key);
      if (newKey !== key) {
        map[newKey] = map[key];
        delete map[key];
      }
    }
  }
};
