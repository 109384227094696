import { SID_AG_BASE_URL } from 'Modules/shareholders-id/constants';

const controller = [
  'AG_SidRequestRepository',
  'cbpToast',
  '$scope',
  '$stateParams',
  '$state',
  function (SidRequestRepository, cbpToast, $scope, $stateParams, $state) {
    this.$onInit = () => {
      this.sidRequestId = $stateParams.sidRequestId;
      this.loading = false;
      this.fetchSidRequest();
    };

    this.fetchSidRequest = async () => {
      this.loading = true;
      try {
        this.sidRequest = await SidRequestRepository.getById(this.sidRequestId);
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
        $scope.$apply();
      }
    };

    this.approve = async () => {
      try {
        await SidRequestRepository.setApproval(this.sidRequestId, true, null);
        cbpToast.show('The SID request has been approved.', 'success');
        $state.go('shareholders-id.agent.landing');
      } catch (err) {
        if (err.status === 412) {
          cbpToast.show(
            'The SID request has not been rejected, the request is not waiting for approval',
            'negative'
          );
          $state.go('shareholders-id.agent.landing');
        }
      }
    };

    this.complete = async () => {
      try {
        await SidRequestRepository.changeStatus(this.sidRequestId, 'COMPLETED');
        cbpToast.show('The SID request has been completed.', 'success');
        $state.go('shareholders-id.agent.landing');
      } catch (err) {
        if (err.status === 412) {
          cbpToast.show(
            'You can only complete SID Requests that are in progress',
            'negative'
          );
          $state.go('shareholders-id.agent.landing');
        }
      }
    };

    this.reject = async (reason) => {
      try {
        await SidRequestRepository.setApproval(
          this.sidRequestId,
          false,
          reason
        );
        cbpToast.show('The SID request has been rejected.', 'success');
        $state.go('shareholders-id.agent.landing');
      } catch (err) {
        if (err.status === 412) {
          cbpToast.show(
            'The SID request has not been rejected, the request is not waiting for approval',
            'negative'
          );
          $state.go('shareholders-id.agent.landing');
        }
      }
    };

    this.handleEditClicked = async () => {
      $state.go('shareholders-id.agent.sidRequestDetails.edit', {
        sidRequestId: this.sidRequestId,
      });
    };

    this.handleDeleteClicked = async () => {
      await SidRequestRepository.deleteById(this.sidRequestId);
      cbpToast.show('The SID request has been deleted.', 'success');
      $state.go('shareholders-id.agent.landing');
    };
  },
];

export default {
  controller,
  templateUrl: `${SID_AG_BASE_URL}/views/sid-request-details/overview/sid-request-details-overview.template.html`,
};
