import './EditIntermediary';
import { fetchIntermediary } from '@/api/v1/userGroups';

export default {
  controller: [
    '$http',
    '$scope',
    '$stateParams',
    '$state',
    function ($http, $scope, $stateParams, $state) {
      $state.go('userMaintenance.editIntermediaryGroup', {
        groupId: $stateParams.intermediaryId,
      });

      this.$onInit = () => {
        this.fetchIntermediary().then(() => {
          $scope.$apply();
        });
      };

      this.fetchIntermediary = async () => {
        this.intermediary = await fetchIntermediary(
          $http,
          $stateParams.intermediaryId
        );
      };
    },
  ],
  template: `
    <div class="agUserManagementEditIntermediary my-3">
      <div class="p-5" ng-if="!$ctrl.intermediary">
        <cbp-loading-spinner></cbp-loading-spinner>
      </div>
      <agent-um-edit-intermediary
        ng-if="$ctrl.intermediary"
        ng-prop-intermediary="$ctrl.intermediary"
        ng-on-save-click="$ctrl.handleSaveClick($event)"
      ></agent-um-edit-intermediary>
    </div>
  `,
};
