import { LitElement, html, css } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';
import {
  sy_form_valid,
  sy_tools_pencil,
  sy_tools_eye,
  sy_profile_family,
  sy_others_blocked,
} from '@aab/sc-aab-icon-set';

export default class UserGroupTableActions extends ScopedElementsMixin(
  LitElement
) {
  static get properties() {
    return {
      toggleButtonIsEnable: {
        type: Boolean,
      },
    };
  }

  static get scopedElements() {
    return {
      'aab-icon-button': customElements.get('aab-icon-button'),
    };
  }

  static get styles() {
    return css`
      [data-tag-name='aab-icon-button'] {
        display: inline-block;
        margin-right: 1rem;
      }
    `;
  }

  _emitEvent(eventName) {
    this.dispatchEvent(new CustomEvent(`${eventName}-click`));
  }

  render() {
    const enableDisableButton = this.toggleButtonIsEnable
      ? html` <aab-icon-button
          alt="Enable"
          svg="${sy_form_valid}"
          @click="${() => this._emitEvent('enable')}"
        ></aab-icon-button>`
      : html`<aab-icon-button
          alt="Disable"
          svg="${sy_others_blocked}"
          @click="${() => this._emitEvent('disable')}"
        ></aab-icon-button>`;

    return html`
      <aab-icon-button
        svg="${sy_tools_eye}"
        alt="View"
        @click="${() => this._emitEvent('view')}"
      ></aab-icon-button>

      <aab-icon-button
        svg="${sy_profile_family}"
        alt="View users"
        @click="${() => this._emitEvent('view-users')}"
      ></aab-icon-button>

      <aab-icon-button
        alt="Edit"
        svg="${sy_tools_pencil}"
        @click="${() => this._emitEvent('edit')}"
      ></aab-icon-button>

      ${enableDisableButton}

      <slot></slot>
    `;
  }
}

customElements.define(
  'agent-um-user-group-table-actions',
  UserGroupTableActions
);
