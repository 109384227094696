export const shareholderMainState = {
  name: 'agentUserManagement.shareholders',
  url: '/shareholders',
  template: '<div ui-view></div>',
  redirectTo: 'agentUserManagement.shareholders.searchShareholder',
};

export const searchShareholderState = {
  name: 'agentUserManagement.shareholders.searchShareholder',
  url: '/search',
  component: 'agUserManagementSearchShareholder',
};
