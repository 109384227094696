import { SID_IS_BASE_URL } from 'Modules/shareholders-id/constants';

const controller = [
  'IS_SidRequestRepository',
  '$stateParams',
  '$state',
  '$scope',
  function (SidRequestRepository, $stateParams, $state, $scope) {
    this.$onInit = () => {
      this.sidRequestId = $stateParams.sidRequestId;
      this.pageTitle = '...';

      this.tabs = [
        {
          key: 'overview',
          label: 'Info',
        },
        {
          key: 'results',
          label: 'Disclosure Report',
        },
      ];

      this.tabStateMap = {
        overview: 'shareholders-id.issuer.sidRequestDetails.overview',
        results: 'shareholders-id.issuer.sidRequestDetails.results.overview',
        nomi: 'shareholders-id.issuer.sidRequestDetails.results.nomi',
      };
      this.stateTabMap = Object.keys(this.tabStateMap).reduce((acc, key) => {
        acc[this.tabStateMap[key]] = key;
        return acc;
      }, {});

      this.fetchSidRequest();
    };

    this.fetchSidRequest = async () => {
      try {
        this.sidRequest = await SidRequestRepository.getById(this.sidRequestId);
        this.pageTitle = this.sidRequest.isin;

        if (
          [
            'DRAFT',
            'AWAITING_ISSUER_APPROVAL',
            'AWAITING_AGENT_APPROVAL',
            'REJECTED',
          ].includes(this.sidRequest.status)
        ) {
          const tabs = [...this.tabs];
          tabs[1].disabled = true;
          this.tabs = tabs;
        }
      } catch (err) {
        this.error = err;
      } finally {
        $scope.$apply();
      }
    };

    this.selectedTab = () => {
      return this.stateTabMap[$state.current.name];
    };

    this.handleTabChange = (tab) => {
      $state.go(this.tabStateMap[tab]);
    };
  },
];

export default {
  controller,
  templateUrl: `${SID_IS_BASE_URL}/views/sid-request-details/sid-request-details.template.html`,
};
