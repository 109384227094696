import { SID_COMMON_BASE_URL } from 'Modules/shareholders-id/constants';
import { parsePercentageNumber } from '@/lib/parseNumber';
import { formatDate } from '@/lib/date';

const Ctrl = [
  'SidRequestConverter',
  function (SidRequestConverter) {
    this.$onChanges = () => {
      this.update();
    };

    this.update = () => {
      if (this.sidRequestData) {
        this.sidRequest = SidRequestConverter.sidRequestFromCreationForm(
          this.sidRequestData
        );

        this.parsedThresholdPct = parsePercentageNumber(
          this.sidRequestData.thresholdPct
        );

        this.formattedRecordDate = formatDate(
          this.sidRequestData.recordDate,
          'EN'
        );
        this.formattedStartDate = formatDate(
          this.sidRequestData.startDate,
          'EN'
        );
        this.formattedReportDeadlineDate = formatDate(
          this.sidRequestData.reportDeadlineDate,
          'EN'
        );
        this.formattedDisclosureDeadlineDate = formatDate(
            this.sidRequestData.disclosureDeadlineDate,
            'EN'
        );

        if (this.sidRequestData.issuerId && this.issuers) {
          this.selectedIssuerName = this.issuers.find(
            (i) => i.id === this.sidRequest.issuerId
          ).name;
        }

        if (this.sidRequest.csdId && this.csds) {
          this.selectedCsdName = this.csds.find(
            (c) => c.csdId === this.sidRequest.csdId
          ).name;
        }
      }
    };
  },
];

export default {
  bindings: {
    sidRequestData: '<',
    issuers: '<',
    csds: '<',
    source: '<',
  },
  controller: Ctrl,
  templateUrl: `${SID_COMMON_BASE_URL}/components/sid-request-input-summary/sid-request-input-summary.template.html`,
};
