import { SID_AG_BASE_URL } from 'Modules/shareholders-id/constants';

import './sid-request-details-overview-edit.scss';

const controller = [
  'AG_SidRequestRepository',
  'AG_IssuerRepository',
  'SidRequestConverter',
  'AG_SidRequestValidator',
  'cbpToast',
  '$scope',
  '$stateParams',
  '$state',
  'issuerPreferencesService',
  '$element',
  function (
    SidRequestRepository,
    IssuerRepository,
    SidRequestConverter,
    SidRequestValidator,
    cbpToast,
    $scope,
    $stateParams,
    $state,
    issuerPreferencesService,
    $element
  ) {
    this.setAction = null;
    this.$onInit = async () => {
      this.sidRequestId = $stateParams.sidRequestId;

      this.loading = true;
      try {
        await Promise.all([this.fetchIssuers(), this.fetchSidRequest()]);
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
        $scope.$apply();
      }
    };

    this.fetchIssuers = async () => {
      this.loadingIssuers = true;
      try {
        this.issuers = (
          await IssuerRepository.list({
            pageSize: 500,
          })
        ).content;
      } catch (err) {
        this.error = err;
      } finally {
        this.loadingIssuers = false;
      }
    };

    this.fetchSidRequest = async () => {
      this.loadingSidRequest = true;
      try {
        this.sidRequest = await SidRequestRepository.getById(this.sidRequestId);
        this.sidRequestData = SidRequestConverter.creationFormDataFromSidRequest(
          this.sidRequest
        );
      } catch (err) {
        this.error = err;
      } finally {
        this.loadingSidRequest = false;
      }
    };

    this.handleInput = (sidReqData) => {
      if (sidReqData.issuerId !== this.sidRequestData.issuerId) {
        // Fetch issuerPrefs, and set the csdId
        this.updateCsdIdForIssuer(sidReqData.issuerId);
      }
      this.sidRequestData = sidReqData;
    };

    this.updateCsdIdForIssuer = async (issuerId) => {
      const csdPreference = await issuerPreferencesService.getCsdPreferenceForIssuer(
        issuerId
      );
      if (csdPreference) {
        this.sidRequestData.csdId = csdPreference;
        $scope.$apply();
      }
    };

    this.showModal = () => {
      const root = $element[0];
      const modal = root.querySelector('aab-modal');
      modal.openModal();
    };

    this.closeModal = () => {
      this.setAction = null;
      const root = $element[0];
      const modal = root.querySelector('aab-modal');
      modal.closeModal(new Event('click'));
    };

    this.validate = () => {
      const validation = SidRequestValidator.validateCreationForm(
        this.sidRequestData
      );
      if (validation.isValid) {
        this.error = null;
        return true;
      }
      this.error = validation.message;
      return false;
    };

    this.handleSaveAsDraft = async () => {
      const sidRequestData = SidRequestConverter.sidRequestFromCreationForm(
        this.sidRequestData
      );
      try {
        await SidRequestRepository.updateById(
          this.sidRequest.sidRequestId,
          sidRequestData
        );
        cbpToast.show('The SID request has been saved as draft.', 'success');
        $state.go('shareholders-id.agent.landing');
      } catch (err) {
        this.error = err;
        $scope.$apply();
      }
    };

    this.handleSubmit = async () => {
      const sidRequestData = SidRequestConverter.sidRequestFromCreationForm(
        this.sidRequestData
      );
      try {
        const sidRequest = await SidRequestRepository.updateById(
          this.sidRequest.sidRequestId,
          sidRequestData
        );
        await SidRequestRepository.requestApproval(sidRequest.sidRequestId);
        cbpToast.show('The SID request has been submitted.', 'success');
        $state.go('shareholders-id.agent.landing');
      } catch (err) {
        this.error = err.data.errors[0].errorCode;
        $scope.$apply();
      }
    };

    this.handleSaveOrSubmit = (action) => {
      if (this.setAction) {
        this.closeModal();
        action === 'save' ? this.handleSaveAsDraft() : this.handleSubmit();
      } else {
        if (this.validate()) {
          action === 'save' ? this.handleSaveAsDraft() : this.handleSubmit();
        }
      }
    };

    this.handleDelete = async () => {
      await SidRequestRepository.deleteById(this.sidRequest.sidRequestId);
      cbpToast.show('The SID request has been deleted.', 'success');
      $state.go('shareholders-id.agent.landing');
    };
  },
];

export default {
  controller,
  templateUrl: `${SID_AG_BASE_URL}/views/sid-request-details/overview-edit/sid-request-details-overview-edit.template.html`,
};
