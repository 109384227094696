// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3rssEblWd558Kwz0lDSYNc{width:64px;height:64px}._3FAweys9R4Tvpgj8aW8rWW{width:20px;height:20px}", ""]);
// Exports
exports.locals = {
	"container": "_3rssEblWd558Kwz0lDSYNc",
	"smallContainer": "_3FAweys9R4Tvpgj8aW8rWW"
};
module.exports = exports;
