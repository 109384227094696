export default [
  '$http',
  function ($http) {
    const repo = {};

    repo.getBySidRequestId = async (sidRequestId, params) => {
      return (
        await $http.get(
          `/v2/sid/issuer/sid-reports/${sidRequestId}/shareholders`,
          {
            params,
          }
        )
      ).data;
    };

    repo.getProgressBySidRequestId = async (sidRequestId) => {
      return (
        await $http.get(
          `/v2/sid/issuer/sid-reports/${sidRequestId}/progress`
        )
      ).data;
    };

    return repo;
  },
];
