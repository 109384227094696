require('./em-well.scss');

module.exports = {
  transclude: true,
  bindings: {
    padding: '<',
  },
  controller: [
    function () {
      this.$onInit = () => {
        this.padding = this.padding || 0;
      };
    },
  ],
  template: `
  <div class="em-well" ng-style="{ padding: $ctrl.padding * 4 }" ng-transclude></div>
  `,
};
