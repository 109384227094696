require('Modules/emerald/emerald.module');

const toastModule = angular.module('cbpToast', [
  'emerald'
]);

const cbpToastService = require('./cbp-toast.service.js');
const cbpToastCmpt = require('./cbp-toast.component.js');

toastModule
  .factory('cbpToast', cbpToastService)
  .component('cbpToast', cbpToastCmpt);

module.exports = toastModule;