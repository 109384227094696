import { sortCountryDataByShareholders } from '@/lib/sid/sortCountryData';
import { groupSortedCountryData } from '@/lib/sid/groupSortedCountryData';
import { chartColors } from '../../../../../cbp-ui/components/cbp-chart/cbp-chart.component';

export default {
  bindings: {
    countryData: '<',
  },
  controller: [
    function () {
      this.$onInit = () => {
        const totalAmountOfShareholders = this.countryData.reduce(
          (acc, country) => {
            return (
              acc +
              country.numberLegalShareholders +
              country.numberNaturalShareholders
            );
          },
          0
        );

        this.chartOptions = {
          elements: {
            bar: {
              borderWidth: 1,
            },
          },
          scales: {
            // Disables showing the ticks on the axis for floating-point number
            y: {
              ticks: {
                callback: (value) => {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label(context) {
                  const amount = context.dataset.data[context.dataIndex];
                  return `${amount} of total ${totalAmountOfShareholders} shareholders`;
                },
              },
            },
          },
        };

        const filteredCountryData = this.countryData.filter(
          (cdi) => !!cdi.countryCode
        );
        const sortedCountryData = sortCountryDataByShareholders(
          filteredCountryData
        );
        const groupedCountryData = groupSortedCountryData(sortedCountryData, 7);

        this.data = {
          labels: groupedCountryData.map((cdi) => cdi.countryCode),
          datasets: [
            {
              label: 'Legal',
              backgroundColor: chartColors[0].light,
              borderColor: chartColors[0].default,
              data: groupedCountryData.map(
                (cdi) => cdi.numberLegalShareholders
              ),
            },
            {
              label: 'Natural',
              backgroundColor: chartColors[1].light,
              borderColor: chartColors[1].default,
              data: groupedCountryData.map(
                (cdi) => cdi.numberNaturalShareholders
              ),
            },
          ],
        };
      };
    },
  ],
  templateUrl:
    '/features/modules/shareholders-id/modules/common/components/sid-shareholders-country-chart/sid-shareholders-country-chart.template.html',
};
