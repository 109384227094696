import { SID_IN_BASE_URL } from 'Modules/shareholders-id/constants';

export default {
  bindings: {},
  controller: [
    function () {
      this.$onInit = () => {
        this.redirectUrl = `${
          process.env.FE_V2_BASE_URL || ''
        }/intermediary-lite`;
      };

      this.redirectToV2 = () => {
        window.location = this.redirectUrl;
      };
    },
  ],
  templateUrl: `${SID_IN_BASE_URL}/views/landing-page/sid-intermediary-landing-page.template.html`,
};
