import { BASE_URL } from '../../constants';

import './product-tile-alert-box.scss';

const Ctrl = [function() {
  
}];

export default {
  bindings: {
    variant: '@',
  },
  transclude: {
    header: 'alertBoxHeader',
    main: 'alertBoxMain',
  },
  controller: Ctrl,
  templateUrl: `${BASE_URL}/components/product-tile-alert-box/product-tile-alert-box.template.html`,
};
