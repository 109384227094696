import { SID_IS_BASE_URL } from 'Modules/shareholders-id/constants';

export default {
  bindings: {
    sidRequestId: '<',
  },
  controller: [
    'IS_SidRequestRepository',
    'cbpToast',
    '$scope',
    function (SidRequestRepository, cbpToast, $scope) {
      this.$onInit = () => {
        this.loading = false;
        this.sidRequest = null;
        this.loadSidRequest();
      };

      this.loadSidRequest = async () => {
        this.loading = true;
        try {
          this.sidRequest = await SidRequestRepository.getById(
            this.sidRequestId
          );
        } catch (err) {
          cbpToast.show(
            `An error occurred while fetching the SidRequest: ${err}`,
            'danger'
          );
        } finally {
          this.loading = false;
          $scope.$apply();
        }
      };
    },
  ],
  templateUrl: `${SID_IS_BASE_URL}/components/sid-request-timeline/sid-request-timeline.template.html`,
};
