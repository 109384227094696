import { html } from 'lit-element';
import { isIe11 } from '@/lib/detectIe11';
import formatNumber, { formatAsPercentageNumber } from '@/lib/formatNumber';
import { SID_IS_BASE_URL } from 'Modules/shareholders-id/constants';
import { formatBoDetails } from '@/lib/sid/formatBoDetails';

const controller = [
  'IS_SidReportShareholdersRepository',
  'IS_SidRequestRepository',
  'IS_SidCountryDataRepository',
  'cbpToast',
  '$stateParams',
  '$scope',
  'SidReportDownloader',
  '$filter',
  'IS_SidShareholderBoDetails',
  function (
    SidReportShareholdersRepository,
    SidRequestRepository,
    SidCountryDataRepository,
    cbpToast,
    $stateParams,
    $scope,
    SidReportDownloader,
    $filter,
    SidShareholderBoDetails
  ) {
    this.$onInit = () => {
      this.selectedTab = 'dashboard';
      this.fetching = false;
      this.sidRequestId = $stateParams.sidRequestId;
      this.totalOutstandingShares = -1; // initialize with -1 to prevent divide by 0
      this.shareholdingTypes = $stateParams.shareholdingTypes;
      this.pageNo = 1;
      this.totalPages = 1;
      this.pageSize = 50;
      this.filterValue = {
        sortBy: 'sum_quantity_unit',
      };

      Promise.all([
        this.fetchShareholders(),
        this.fetchCountryData(),
        this.fetchOutstandingShares(),
        this.fetchTop10BeneficialShareholders(),
      ]).finally(() => {
        this.createTop10TableDefinition();
        $scope.$apply();
      });
    };

    this.fetchShareholders = async (performDigest = false) => {
      this.fetching = true;
      try {
        const page = await SidReportShareholdersRepository.getBySidRequestId(
          this.sidRequestId,
          {
            pageNo: this.pageNo,
            pageSize: this.pageSize,
            sortBy: this.filterValue.sortBy,
            shareholdingTypes: this.shareholdingTypes,
          }
        );
        this.totalPages = Math.ceil(page.totalElements / page.pageSize);
        this.shareholdersData = page.list;
      } catch (err) {
        cbpToast.show(`There was an error: ${err.message}`, 'danger');
      } finally {
        this.fetching = false;
        if (performDigest) {
          $scope.$apply();
        }
      }
    };

    this.fetchTop10BeneficialShareholders = async (performDigest = false) => {
      this.fetching = true;
      try {
        const page = await SidReportShareholdersRepository.getBySidRequestId(
          this.sidRequestId,
          {
            pageNo: 1,
            pageSize: 10,
            sortBy: this.filterValue.sortBy,
            shareholdingTypes: 'BENE',
          }
        );
        this.top10Shareholders = page.list;
        this.top10TotalShares = this.top10Shareholders.reduce(
          (acc, shareholder) => acc + shareholder.quantityUnit,
          0
        );
      } catch (err) {
        cbpToast.show(`There was an error: ${err.message}`, 'danger');
      } finally {
        this.fetching = false;
        if (performDigest) {
          $scope.$apply();
        }
      }
    };

    this.fetchCountryData = async () => {
      this.isFetchingCountryData = true;
      try {
        this.sidCountryData = await SidCountryDataRepository.getByRequestId(
          this.sidRequestId
        );
      } catch (err) {
        console.error(err);
        cbpToast.show('There was an error fetching the country data', 'danger');
      } finally {
        this.isFetchingCountryData = false;
      }
    };

    this.fetchOutstandingShares = async () => {
      try {
        let sidRequest = await SidRequestRepository.getById(this.sidRequestId);
        this.totalOutstandingShares = sidRequest.totalOutstandingShares;
      } catch (err) {
        console.error(err);
        cbpToast.show('There was an error fetching the country data', 'danger');
      }
    };

    this.handlePaginationChanged = (newPage) => {
      this.pageNo = newPage;
      this.fetchShareholders(true);
    };

    this.handleDetailClicked = (shareholderReport) => {
      // $state.go('shareholders-id.issuer.sidRequestDetails.results.details', {
      //   itemIds: shareholderReport.sidResponseItemIds.join(','),
      // });
    };

    this.handleDownloadExcelReport = async () => {
      if (isIe11()) {
        cbpToast.show(
          $filter('translate')('ie11_xlsx_download_warning'),
          'warning'
        );
      }
      this.isExcelDownloadButtonDisabled = true;
      try {
        await SidReportDownloader.download(
          `/v2/sid/issuer/sid-reports/${this.sidRequestId}/summary?mediaType=xlsx`
        );
      } finally {
        this.isExcelDownloadButtonDisabled = undefined;
        $scope.$apply();
      }
    };
    this.createTop10TableDefinition = async () => {
      if (-1 == this.totalOutstandingShares) {
        return;
      }

      this.top10TableHeaders = [
        {
          label: 'Name',
          render: (item, index) => `${index}. ${item.names.join(', ')}`,
        },
        {
          label: 'Securities',
          align: 'right',
          width: 128,
          render: (item) =>
            html`<span>
              ${formatNumber(Math.round(item.quantityUnit))}
              <small>
                (${formatAsPercentageNumber(
                  item.quantityUnit / this.totalOutstandingShares,
                  2
                )}%)
              </small>
            </span>`,
        },
      ];
      $scope.$apply();
    };

    this.boDetailsMap = {};

    this.handleShareholderTileToggle = async (opened, shareholder) => {
      if (opened && !this.boDetailsMap[shareholder.disclosureResponseId]) {
        let data = await SidShareholderBoDetails.fetchBoDetails(
          this.sidRequestId,
          shareholder.disclosureResponseId
        );
        formatBoDetails(data);
        this.boDetailsMap[shareholder.disclosureResponseId] = data;
        $scope.$apply();
      }
    };
  },
];

export default {
  controller,
  templateUrl: `${SID_IS_BASE_URL}/views/sid-request-details/results/shareholder-reports-overview/shareholder-reports-overview.template.html`,
};
