import { LitElement, html } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';
import debounce from 'debounce';

const DEBOUNCE_WAIT = 400;

class SearchShareholder extends ScopedElementsMixin(LitElement) {
  constructor() {
    super();
    this._emitEvent = debounce(this._emitEvent, DEBOUNCE_WAIT);
  }

  static get scopedElements() {
    return {
      'aab-input': customElements.get('aab-input'),
    };
  }

  _emitEvent(value) {
    this.dispatchEvent(
      new CustomEvent('agent-search-shareholder-input', {
        detail: { value },
      })
    );
  }

  _handleInput(ev) {
    this._emitEvent(ev.target.value);
  }

  render() {
    return html` <form action="#" @submit="${(ev) => ev.preventDefault()}">
      <aab-input>
        <label for="shareholderEmailInput" slot="label">
          Search shareholder by email
        </label>
        <input
          type="text"
          id="shareholderEmailInput"
          placeholder="Search by email"
          @input="${this._handleInput}"
        />
      </aab-input>
    </form>`;
  }
}

customElements.define('agent-search-shareholder', SearchShareholder);
