import './cbp-icon-circle.scss';

export default {
  bindings: {
    icon: '@',
  },
  controller: [function () {}],
  templateUrl:
    '/features/modules/cbp-ui/components/cbp-icon-circle/cbp-icon-circle.template.html',
};
