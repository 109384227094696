/* eslint-disable indent */
import { LitElement, html, css } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

export default class AabIconButton extends ScopedElementsMixin(LitElement) {
  constructor() {
    super();
    this._defaultColor = '#00716B';
    this._hoverColor = 'rgb(0, 133, 122)';
    this.color = this._defaultColor;
  }

  static get properties() {
    return {
      svg: { type: String },
      alt: { type: String },
      color: {
        attribute: false,
      },
    };
  }

  static get scopedElements() {
    return {
      'aab-icon': customElements.get('aab-icon'),
    };
  }

  static get styles() {
    return css`
      aab-icon {
        cursor: pointer;
      }
    `;
  }

  render() {
    return html`
      <aab-icon
        title="${this.alt}"
        svg="${this.svg}"
        size="3"
        color="${this.color}"
        @mouseenter="${() => {
          this.color = this._hoverColor;
        }}"
        @mouseleave="${() => {
          this.color = this._defaultColor;
        }}"
      ></aab-icon>
    `;
  }
}
