/* eslint-disable indent */
import { LitElement, html } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';
import createTableHeaders from '../../../lib/userGroupTableHeaders';
import { userGroupFilter } from '../../../lib/filters';

class VoteCollectorsView extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      voteCollectors: { type: Array },
      nameFilter: { type: String, attribute: false },
    };
  }

  constructor() {
    super();
    this.voteCollectors = [];
    this.tableHeaders = createTableHeaders({
      actions: (vc) => html`
        <agent-um-user-group-table-actions
          .toggleButtonIsEnable=${vc.status !== 'Enabled'}
          @view-click="${() => this._handleVoteCollectorClicked(vc.groupId)}"
          @view-users-click="${() => this._handleViewUsersClicked(vc.groupId)}"
          @edit-click="${() => this._handleEditClicked(vc.groupId)}"
          @enable-click="${() => this._handleEnableClicked(vc.groupId)}"
          @disable-click="${() => this._handleDisableClicked(vc.groupId)}"
        ></agent-um-user-group-table-actions>
      `,
    });

    this.tableHeaders = this.tableHeaders.filter((h) => h.id !== 'email');
  }

  static get scopedElements() {
    return {
      'aab-tile': customElements.get('aab-tile'),
      'aab-simple-table': customElements.get('aab-simple-table'),
      'agent-um-button-and-input-tile': customElements.get(
        'agent-um-button-and-input-tile'
      ),
      'agent-um-user-group-table-actions': customElements.get(
        'agent-um-user-group-table-actions'
      ),
    };
  }

  _handleAddVoteCollectorClicked() {
    this.dispatchEvent(new CustomEvent('add-vote-collector-click'));
  }

  __dispatchEvent(name, voteCollectorId) {
    this.dispatchEvent(
      new CustomEvent(name, {
        detail: { voteCollectorId },
        bubbles: true,
        composed: true,
      })
    );
  }

  _handleVoteCollectorClicked(voteCollectorId) {
    this.__dispatchEvent('vote-collector-click', voteCollectorId);
  }

  _handleViewUsersClicked(voteCollectorId) {
    this.__dispatchEvent('view-users-click', voteCollectorId);
  }

  _handleEditClicked(voteCollectorId) {
    this.__dispatchEvent('edit-click', voteCollectorId);
  }

  _handleEnableClicked(voteCollectorId) {
    this.__dispatchEvent('enable-click', voteCollectorId);
  }

  _handleDisableClicked(voteCollectorId) {
    this.__dispatchEvent('disable-click', voteCollectorId);
  }

  _filteredData() {
    if (!this.nameFilter) {
      return this.voteCollectors;
    } else {
      return this.voteCollectors.filter((vc) =>
        userGroupFilter(vc, this.nameFilter)
      );
    }
  }

  render() {
    return html`
      <agent-um-button-and-input-tile
        buttonTitle="Add vote collector"
        inputPlaceholder="Filter by vote collector name"
        @button-click="${this._handleAddVoteCollectorClicked}"
        @input-change="${(ev) => {
          this.nameFilter = ev.detail.value;
        }}"
      >
        <aab-tile>
          <aab-simple-table
            fullWidth
            fixed
            paginated
            .columnHeaders="${this.tableHeaders}"
            .data="${this._filteredData()}"
          ></aab-simple-table>
        </aab-tile>
      </agent-um-button-and-input-tile>
    `;
  }
}

customElements.define('agent-um-vote-collectors', VoteCollectorsView);
