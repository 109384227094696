import './AddVoteCollectorView';

export default {
  controller: [
    '$state',
    function ($state) {
      this.$onInit = () => {
        $state.go('userMaintenance.addVCGroup');
      };
    },
  ],
  template: `
    <div class="my-3">
      <agent-um-add-vote-collector-view></agent-um-add-vote-collector-view>
    </div>
  `,
};
