import { BASE_URL } from '../constants';

import './em-file-input.scss';

const Ctrl = [
  '$scope',
  '$log',
  function ($scope, $log) {
    this.$onInit = () => {
      this.inputId = `em-file-input-${String(
        Math.floor(Math.random() * 6782)
      )}`;

      this.isInputElementSetUp = false;

      this.lazilySetupInputElement();
    };

    this.lazilySetupInputElement = () => {
      if (this.isInputElementSetUp) {
        return;
      } else {
        const inputElement = document.querySelector(`#${this.inputId}`);
        if (inputElement) {
          inputElement.addEventListener('change', (ev) => {
            $log.info('Not implemented');
          });
        }
      }
    };

    this.handleSelectFile = () => {
      const inputElement = document.querySelector(`#${this.inputId}`);
      this.lazilySetupInputElement();
      inputElement.click();
    };
  },
];

export default {
  bindings: {
    label: '@',
    value: '<',
    onChange: '&',
  },
  controller: Ctrl,
  templateUrl: `${BASE_URL}/em-file-input/em-file-input.template.html`,
};
