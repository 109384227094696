import { BASE_URL } from '../constants';

import './em-radio-inputs.scss';

export default {
  bindings: {
    label: '@',
    name: '@',
    isInvalid: '<',

    options: '<',
    value: '<',

    onChange: '&',
  },

  controller: [
    function () {
      this.$onInit = () => {
        this.modelValue = this.value;
      };

      this.handleChange = (value) => {
        if (this.onChange) {
          this.onChange({
            value,
            name: this.name,
          });
        }
      };
    },
  ],

  /**
   * This is copied from the emerald example pages
   */
  templateUrl: `${BASE_URL}/em-radio-inputs/em-radio-inputs.template.html`,
};
