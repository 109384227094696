import { SID_COMMON_BASE_URL } from 'Modules/shareholders-id/constants';

export default {
  bindings: {
    error: '<',
    submitDisabled: '<',
    onSave: '&',
    onSubmit: '&',
    status: '<',
  },
  templateUrl: `${SID_COMMON_BASE_URL}/components/sid-request-form-footer/sid-request-form-footer.template.html`,
};
