export default [
  '$http',
  function ($http) {
    async function fetchBoDetails(sidRequestId, shareholderId) {
      const { data } = await $http.get(
        `/v2/sid/issuer/sid-reports/${sidRequestId}/shareholder/disclosure/${shareholderId}`
      );
      return data;
    }

    return { fetchBoDetails };
  },
];
