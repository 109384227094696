export const listIntermediaries = {
  name: 'agentUserManagement.proxyIntermediaries',
  url: '/proxyIntermediaries',
  template: '<div ui-view></div>',
  redirectTo: 'agentUserManagement.proxyIntermediaries.list',
};

export const listProxyIntermediariesState = {
  name: 'agentUserManagement.proxyIntermediaries.list',
  url: '/list',
  component: 'agUserManagementProxyIntermediaries',
};

export const viewProxyIntermediaryState = {
  name: 'agentUserManagement.proxyIntermediaries.proxyIntermediary',
  url: '/{proxyIntermediaryId}',
  component: 'agUserManagementProxyIntermediary',
};

export const addProxyIntermediaryState = {
  name: 'agentUserManagement.proxyIntermediaries.addProxyIntermediary',
  url: '/add',
  component: 'agUserManagementAddProxyIntermediary',
};

export const editProxyIntermediaryState = {
  name: 'agentUserManagement.proxyIntermediaries.editProxyIntermediary',
  url: '/edit/{proxyIntermediaryId}',
  component: 'agUserManagementEditProxyIntermediary',
};

/** users  */
export const viewProxyIntermediaryUsersState = {
  name: 'agentUserManagement.proxyIntermediaries.users',
  url: '/{proxyIntermediaryId}/users',
  component: 'agUserManagementProxyIntermediaryUsers',
};

export const viewProxyIntermediaryUserState = {
  name: 'agentUserManagement.proxyIntermediaries.user',
  url: '/{proxyIntermediaryId}/users/{userId}',
  component: 'agUserManagementProxyIntermediaryUser',
};

export const addProxyIntermediaryUserState = {
  name: 'agentUserManagement.proxyIntermediaries.addUser',
  url: '/{proxyIntermediaryId}/addUser',
  component: 'agUserManagementAddProxyIntermediaryUser',
};

export const editProxyIntermediaryUserState = {
  name: 'agentUserManagement.proxyIntermediaries.editUser',
  url: '/{proxyIntermediaryId}/editUser/{userId}',
  component: 'agUserManagementEditProxyIntermediaryUser',
};
