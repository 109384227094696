import * as iconSet from '@aab/sc-aab-icon-set';

/**
 * A wrapper for the @aab/sc-aab-icon component, to hide its clunky interface
 */
export default {
  bindings: {
    icon: '@',
    size: '<',
  },
  controller: [
    function () {
      this.$onInit = () => {
        if (this.icon === 'sy_tools_lock_black') {
          this.svg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#222222" fill-rule="evenodd" d="M8 19h8v-6H8v6zM9 8c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9V8zm8 3.037V8c0-2.75-2.25-5-5-5S7 5.25 7 8v3.037c-.562.09-1 .574-1 1.186v7.554C6 20.453 6.525 21 7.173 21h9.653c.649 0 1.174-.547 1.174-1.223v-7.554a1.19 1.19 0 0 0-1-1.186zm-6 5.574V17a1 1 0 0 0 2 0v-.39c.305-.274.5-.667.5-1.11a1.5 1.5 0 0 0-3 0c0 .443.195.836.5 1.11"/></svg>';
        } else {
          this.svg = iconSet[this.icon];
        }

        if (!this.size) {
          this.size = 4;
        }
      };
    },
  ],
  templateUrl:
    '/features/modules/cbp-ui/components/cbp-aab-icon/cbp-aab-icon.template.html',
};
