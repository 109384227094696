const { BASE_URL } = require('../constants');

require('./em-message.scss');

/**
 * `type` is injected straight into the emerald classes. Therefore, it should be one of the following:
 * - positive
 * - info (default)
 * - negative
 * - warning
 * - privacy
 */

module.exports = {
  bindings: {
    type: '@',
    showIcon: '<',
    showCloseIcon: '<',
    onClose: '&'
  },
  controller: [function() {
    this.$onInit = () => {
      if (!this.type) {
        this.type = 'info';
      }

      if (this.showIcon === undefined) {
        this.showIcon = true;
      }
    };
  }],
  transclude: true,
  templateUrl: `${BASE_URL}/em-message/em-message.template.html`
};