import './AgentUser';
import { fetchAgentUser } from '@/api/v1/agent';

export default {
  controller: [
    '$stateParams',
    '$http',
    '$scope',
    function ($stateParams, $http, $scope) {
      this.$onInit = () => {
        this.fetchUser().then(() => {
          $scope.$apply();
        });
      };

      this.fetchUser = async () => {
        this.agentUser = await fetchAgentUser($http, $stateParams.userId);
      };
    },
  ],
  template: `
    <div class="my-3">
      <div class="p-5" ng-if="!$ctrl.agentUser">
        <cbp-loading-spinner></cbp-loading-spinner>
      </div>
      <agent-um-agent-user
        ng-prop-user="$ctrl.agentUser"
      ></agent-um-agent-user>
    </div>
  `,
};
