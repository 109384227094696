import 'Modules/shareholders-id/modules/common/sid-common.module';

const sidIssuerModule = angular.module('sidIssuer', ['ui.router', 'sidCommon']);

/**
 * Services
 */
import sidCountryDataRepository from './model/repositories/sid-country-data-repository.service';
import sidRequestRepository from './model/repositories/sid-request-repository.service';
import sidReportShareholdersRepository from './model/repositories/sid-report-shareholders-repository.service';
import sidResponseItemRepository from './model/repositories/sid-response-item-repository.service';
import sidResponseForwardRepository from './model/repositories/sid-response-forward-repository.service';
import sidShareholderBoDetailsRepository from './model/repositories/shareholder-bo-details.service';

import sidRequestValidatorService from './model/services/sid-request-validator.service';

sidIssuerModule.service(
  'IS_SidCountryDataRepository',
  sidCountryDataRepository
);
sidIssuerModule.service('IS_SidRequestRepository', sidRequestRepository);
sidIssuerModule.service(
  'IS_SidReportShareholdersRepository',
  sidReportShareholdersRepository
);
sidIssuerModule.service(
  'IS_SidResponseItemRepository',
  sidResponseItemRepository
);
sidIssuerModule.service(
  'IS_SidResponseForwardRepository',
  sidResponseForwardRepository
);
sidIssuerModule.service(
  'IS_SidShareholderBoDetails',
  sidShareholderBoDetailsRepository
);

sidIssuerModule.service('IS_SidRequestValidator', sidRequestValidatorService);

/**
 * Views
 */
import sidIssuerLandingPage from './views/landing-page/sid-issuer-landing-page.component';
import sidIssuerCreateRequest from './views/create-request/sid-issuer-create-request.component';
import sidIssuerRequestDetails from './views/sid-request-details/sid-request-details.component';
import sidIssuerRequestDetailsOverview from './views/sid-request-details/overview/sid-request-details-overview.component';
import sidIssuerRequestDetailsOverviewEdit from './views/sid-request-details/overview-edit/sid-request-details-overview-edit.component';

import sidIssuerRequestDetailsResults from './views/sid-request-details/results/sid-request-details-results.component';
import sidIssuerResultsShareholderReportsOverview from './views/sid-request-details/results/shareholder-reports-overview/shareholder-reports-overview.component';
import sidIssuerResultsShareholderReportsNomi from './views/sid-request-details/results/shareholder-reports-nomi/shareholder-reports-nomi.component';
import sidIssuerResultsShareholderDetails from './views/sid-request-details/results/shareholder-details/shareholder-details.component';

sidIssuerModule.component({
  sidIssuerLandingPage,
  sidIssuerCreateRequest,
  sidIssuerRequestDetails,
  sidIssuerRequestDetailsOverview,
  sidIssuerRequestDetailsOverviewEdit,
  sidIssuerRequestDetailsResults,
  sidIssuerResultsShareholderReportsNomi,
  sidIssuerResultsShareholderDetails,
  sidIssuerResultsShareholderReportsOverview,
});

/**
 * Components
 */
import issuerSidRequestTile from './components/sid-request-tile/sid-request-tile.component';
import issuerSidRequestTimeline from './components/sid-request-timeline/sid-request-timeline.component';
import issuerSidRequestReport from './components/sid-request-report/sid-request-report.component';
import issuerSidRequestReportShareholderTile from './components/sid-request-report-shareholder-tile/sid-request-report-shareholder-tile.component';

sidIssuerModule.component({
  issuerSidRequestTile,
  issuerSidRequestTimeline,
  issuerSidRequestReport,
  issuerSidRequestReportShareholderTile,
});

/**
 * Routes
 */
import routes from './routes';

sidIssuerModule.config(routes);

export default sidIssuerModule;
