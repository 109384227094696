const investorTypeDisplayNameMap = {
  ELIG: 'Eligble counter party',
  RETL: 'Retail client',
  PROF: 'Professional client',
  PROP: 'Proprietary',
  NONE: 'Unknown',
};

export default function (investorTypeEnum) {
  return investorTypeDisplayNameMap[investorTypeEnum];
}
