import { LitElement, html } from 'lit-element';
import styles from './AabTypography.scss';

export default class AabTypography extends LitElement {
  static get styles() {
    return styles;
  }

  render() {
    return html`<div><slot></slot></div>`;
  }
}
