export default [
  '$state',
  '$rootScope',
  '$sessionStorage',
  'AuthService',
  'UserNavigationHistory',
  function ($state, $rootScope, $sessionStorage, AuthService) {
    window.myAppErrorLog = [];
    $state.defaultErrorHandler(function (error) {
      // This is a naive example of how to silence the default error handler.
      window.myAppErrorLog.push(error);
    });

    //Handle Page refresh issue
    $rootScope.$on('$locationChangeSuccess', function (event, next, current) {
      //Below variable is for checking if the current page is 'send email to euroclear'
      $rootScope.euroclearPage = next.indexOf('sendEmailEuroclear');
      // For getting url from absolute path
      var currentUrl = current.split('/').pop();

      var substring = '#%2F%23'; // For identifying vertical tab menus #/#
      // If we are refreshing from vertical tab menus
      $rootScope.refreshtriggered = false;

      const something = currentUrl.indexOf(substring) !== -1;
      const isSamePage = next === current;
      const currentIsLoginPage = AuthService.loginURL(current);

      if (something || (isSamePage && !currentIsLoginPage)) {
        event.preventDefault();
        // Fetching menus
        $rootScope.$broadcast('onRefresh', $sessionStorage.workstationData);
        //Navigate to the previous state
        $rootScope.refreshtriggered = true;
        //Navigate to welcome page if the browser is closed and hit the stored url
        if (
          $sessionStorage.userId === undefined &&
          $sessionStorage.isPublicPage === false
        ) {
          $state.go('welcomePage');
        } else {
          $state.go($sessionStorage.toState.name, $sessionStorage.toParams);
        }
      }
    });
  },
];
