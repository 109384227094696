import * as formatMap from '../formatMap';

/**
 * Shortens the list of string values so that it does not overload
 * a product tile on full expansion of the display window.
 *
 * Note: there are still some alignment issues when the window is smaller
 * then full screen (which need to be fixed in the (css) of the product
 * tile)
 */
export const joinStringValuesMaxLength = (array, maxLength) => {
  const ending = '...';
  const separator = ', ';
  let joinedStrings = [];

  for (let i = 0; i < array.length; i++) {
    let value = array[i];

    // this handles array with single entry
    if (array.length === 1) {
      if (value.length > maxLength) {
        joinedStrings.push(
          value.substring(0, maxLength - ending.length) + ending
        );
      } else {
        joinedStrings.push(value);
      }
      break;
      // this handles array with more entries but the first is already too big
    } else if (i === 0 && value.length >= maxLength) {
      joinedStrings.push(
        value.substring(0, maxLength - ending.length) + ending
      );
      break;
      // this handles array where after latest addition, maxLength is reached
    } else if (joinedStrings.join(separator).length === maxLength) {
      // is this the final entry
      if (array.length === i + 1) {
        joinedStrings.join(separator);
      } else {
        joinedStrings.pop();
        return joinedStrings.join(separator) + separator + ending;
      }
      // final option is that more items can be added
    } else {
      joinedStrings.push(value);
      // did the final entry exceed maxLength
      if (joinedStrings.join(separator).length > maxLength) {
        joinedStrings.pop();
        return joinedStrings.join(separator) + separator + ending;
      }
    }
  }
  return joinedStrings.join(separator);
};

/**
 * Shortens a string value to specified length and adds ellipsis
 *
 */
export const formatStringMaxLength = (value, maxLength) => {
  const ending = '...';
  if (value.length > maxLength) {
    return value.substring(0, maxLength - ending.length) + ending;
  } else {
    return value;
  }
};

/**
 * Formats the BO Details for agent and issuer views.
 *
 */
export const formatBoDetails = (data) => {
  formatValuesShareholderDetails(data);

  data.respondingIntermediaries.forEach(function (ri) {
    formatMap.formatDateMapValues(ri, ['disclosureDate'], 'dateTime', 'EN');
    if (!!ri['contactPerson']) {
      formatMap.formatStringMapValues(ri['contactPerson'], ['name']);
    }

    ri.accountsAndHoldings.forEach(function (ah) {
      formatValuesAccountsAndHoldings(ah);
      if (!!ah.thirdParties) {
        ah.thirdParties.forEach(function (tp) {
          formatValuesThirdParties(tp);
          formatMap.formatMapKeys(tp);
        });
      }
      formatMap.formatMapKeys(ah);
    });
    formatMap.formatMapKeys(ri.contactPerson);
    formatMap.formatMapKeys(ri);
  });
  formatMap.formatMapKeys(data);
};

function formatValuesShareholderDetails(data) {
  if (data) {
    formatMap.formatStringMapValues(data, ['entityType']);
    formatMap.formatNumberMapValues(data, ['securities']);
    if (!matchesBicOrLei(data['identificationType'])) {
      formatMap.formatStringMapValues(data, ['identificationType']);
    }
    if (data['email'] === 'UNDISCLOSED') {
      formatMap.formatStringMapValues(data, ['email']);
    }
    formatAddressDetails(data.address);
  }
}

function formatValuesAccountsAndHoldings(ah) {
  if (ah) {
    formatMap.formatNumberMapValues(ah, ['securities']);
    formatMap.formatStringMapValues(ah, ['ownershipType', 'investorType']);
    formatMap.formatDateMapValues(
      ah,
      ['initialDateOfShareholding'],
      'date',
      'EN'
    );
    if (!matchesBicOrLei(ah['identificationType'])) {
      formatMap.formatStringMapValues(ah, ['identificationType']);
    }
    formatAddressDetails(ah.address);
  }
}

function formatValuesThirdParties(tp) {
  if (tp) {
    formatMap.formatStringMapValues(tp, ['partyRole', 'entityType']);
    if (!matchesBicOrLei(tp['identificationType'])) {
      formatMap.formatStringMapValues(tp, ['identificationType']);
    }
    formatAddressDetails(tp.address);
  }
}

function formatAddressDetails(data) {
  if (data) {
    formatMap.formatStringMapValues(data, [
      'streetName',
      'townName',
      'countrySubDivision',
      'country',
    ]);
  }
}

function matchesBicOrLei(identificationType) {
  switch (identificationType) {
    case 'LEI':
    case 'BIC':
      return true;
    default:
      return false;
  }
}
