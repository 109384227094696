// Polyfills, required to run properly on IE11
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@webcomponents/webcomponentsjs/webcomponents-bundle';
import '@webcomponents/scoped-custom-element-registry';

import './setup/global.scss';

// Local components
import '@/components/aab-hero';
import '@/components/aab-icon-button';
import '@/components/aab-link';
import '@/components/aab-table';
import '@/components/aab-tile';
import '@/components/aab-tile-button';
import '@/components/aab-typography';
import '@/components/cbp-simple-navigator';

// ScAabComponents (Emerald)
import '@aab/sc-aab-accordion';
import '@aab/sc-aab-actionbar';
import '@aab/sc-aab-button';
import '@aab/sc-aab-input';
import '@aab/sc-aab-info-popover';
import '@aab/sc-aab-modal';
import '@aab/sc-aab-notification';
import '@aab/sc-aab-pagination';
import '@aab/sc-aab-select';
import '@aab/sc-aab-spinner';
import '@aab/sc-aab-table';
import '@aab/sc-aab-tabs';
import '@aab/sc-aab-toggleswitch';

import './modules/app';

document.addEventListener('DOMContentLoaded', function () {
  angular.bootstrap(document, ['cbpApp']);
});
