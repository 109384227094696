/**
 * @ngdoc constant
 * @name appConstant
 * @description This is a constant file for action home
 */
var appConstant = {
  GM_FIRST_TAB: '1',
  GM_SECOND_TAB: '2',
  GM_THIRD_TAB: '3',
  GM_FORTH_TAB: '4',
  GM_FIFTH_TAB: '5',
  GM_SIXTH_TAB: '6',
  REMIND: 'Contact issuer to approve meeting',
  REMIND_DWT: 'Contact issuer to approve event',
  CREATE: 'Create Intermediary',
  CONTACT_EXTN_REQ: 'Contact issuer for extension request',
  SET_SHARE_PLAN: 'Set-up share plan',
  SEND_REG_LIST: 'Download registration list',
  EDIT_GM: 'Edit GM',
  VC_USR_ROLE: 'Vote Collector Users yet to accept their role',
  VOTE_CNF_SENT: 'Vote Confirmations to be sent out',
  REVIEW_GM: 'Review/Approve GM',
  REVIEW_EXT: 'Review/Approve Extension Request',
  DOWNLOAD_REG: 'Download registration list',
  ACCEPT_VC: 'Accept Vote Collector Invitation',
  SEND_VC: 'Send Vote Confirmation',
  ACTION_VI: 'Voting Instructions',
  ACTION_AR: 'Attendance Requests',
  SEND_REG: 'Send new registration form',
  OPTED_EN: 'EN',
  OPTED_NL: 'NL',
  OPTED_FR: 'FR',
  OPTED_DE: 'DE',
  OPTED_ES: 'ES',
  IS: 'IS',
  SH: 'SH',
  IS_HOME: 'IS Home',
  BODY: 'Body',
  CBP: 'cbp',
  CONTEXT_ROOT: '/cbp',
  IS_HOME_PAGE: 'Issuer Home Page',
  SUBMIT_QRF_CLAIMS: 'Submit the QRF claims',
  APPROVE_EVENT: 'Approve event',
  Submit_QRF_or_PE_Claims: 'Submit QRF or PE Claims',
  CHECK_PE_CLAIMS: 'Check  PE Claims',
  VIEW_EVENT: 'View Event',
  INTERMEDIARY_ALEX: 'Alex Vermogensbeheer',
  INTERMEDIARY_BINCKBANK: 'BinckBank',
  INTERMEDIARY_BINCKBANK_WEBISTE: 'https://www.binck.nl',
  INTERMEDIARY_ALEX_WEBISTE: 'https://www.alex.nl',
  BAD_REQUEST: 'Bad Request',
  PAGE_SIZE: 10,
  REGISTRATIONS: 'registrations',
  VOTING_DETAILS: 'votingDetails',
  SEARCH_VIDETAILS: 'searchVotingDetails',
  ATTENDANCE: 'attendance',
  VOTING: 'voting',
  VOTING_INSTRUCTION: 'votingInstruction',
  FOR: 'for',
  AGAINST: 'against',
  ABSTAINED: 'abstained',
  NONE: 'none',
  GENERAL_MEETING_DATE: 'GENERAL_MEETING_DATE',
  ASCENDING: 'ASC',
  DESCENDING: 'DESC',
  VOTECOLLECTOR_TAB: 'votecollectorTab',
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  TO_BE_PUBLISHED: 'TO_BE_PUBLISHED',
  LIVE: 'LIVE',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  MODIFIED_DATETIME: 'MODIFIED_DATETIME',
  APPROVED: 'APPROVED',
  IN_PROXY_PARTICIPATION: 'In person/proxy participation',
  VOTING_BY_CORRESPONDENCE: 'Voting by correspondence',
  FOR_OR_AGAINST_VI: 'For/Against Voting Instruction',
  ABSTAINED_VI: 'Abstained Voting Instruction',
  NO_ACTION: 'No action',
  DATE_FORMAT: 'yyyy-MM-dd HH:mm:ss',
  MONTH_DATE_YEAR_FORMAT: 'MMM dd yyyy HH:mm',
  TEMPLATE_CODE_EMAIL: 'EMAIL',
  TEMPLATE_TYPE_SHAREPLAN_NEW_USER_NOTIFICATION:
    'SharePlan New User GM Notification',
  EMAIL_REGEX_PATTERN:
    '[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})',
  GM_CONTACT_EMAIL: 'ava@nl.abnamro.com',
};
module.exports = appConstant;
