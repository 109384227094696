/**
 * @name cbpApp Module
 * @description Main module of the Corporate Broking Portal application.
 */
'use strict';

import '@/scss/em-colors.scss';
import '@/scss/em-icons.scss';
import '@/scss/rotate.scss';
import '@/scss/spacing.scss';

import 'intersection-observer';

import 'Modules/shareholders-id/sid.module';
import 'Modules/cbp-toast/cbp-toast.module';
import './agent/user-management/userManagement.module';
import './progress-bars/progress-bars.module';

import configFn from './app.config';
import runFn from './app.run';

const cbpAppModule = angular
  .module('cbpApp', [
    'commonModule',
    'generalMeetingMainModule',
    'loginRoute',
    'loginModule',
    'captchamodule',
    'actionHomeModule',
    'generalMeetingHomeModule',
    'uploadModule',
    'downloadModule',
    'pascalprecht.translate',
    'ui.bootstrap',
    'ui.router',
    'userMaintenanceModule',
    'userMaintenanceDwtModule',
    'myAccountsModule',
    'shareHolderModule',
    'ngIdle',
    'dwtModule',
    'sid',
    'recaptchaInfoModule',
    'angular.filter',
    'ngIntlTelInput',
    'cbpFilterModule',
    'intermediaryModule',
    'cbpToast',
    'agentUserManagement',
    'progressBars',
  ])
  .config(configFn)
  .run(runFn);

export default cbpAppModule;
