export default {
  bindings: {
    country: '@', // iso 3166 alpha 2 country code
    size: '<',
  },
  template: `
  <cbp-aab-icon
    icon="flag_{{ $ctrl.country.toLowerCase() }}"
    size="$ctrl.size"
  ></cbp-aab-icon>
  `,
};
