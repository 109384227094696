export async function listUsers($http, userType, userGroupId) {
  const response = await $http.get(
    `/v1/users/viewusers/${userGroupId}/${userType}/GM`
  );
  return response.data;
}

export async function fetchUser($http, userType, userGroupId, userId) {
  const response = await $http.get(
    `/v1/users/${userId}/${userType}/${userGroupId}/GM`
  );
  return response.data;
}

export async function toggleUserDisabled(
  $http,
  userType,
  userGroupId,
  userId,
  loggedInUserId
) {
  const { data } = await $http.put(
    `/v1/users/${userId}/GM/${userType}/${userGroupId}/${loggedInUserId}`
  );
  return data;
}

export async function updateUser($http, userId, payload) {
  const { data } = await $http.put(`/v1/users/edit/${userId}`, payload);
  return data;
}

export async function createUser($http, payload) {
  const { data } = await $http.post(`/v1/users/create`, payload);
  return data;
}
