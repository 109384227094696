/* eslint-disable indent */
import { html, LitElement } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class VoteCollectorView extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      voteCollector: { type: Object },
    };
  }

  static get scopedElements() {
    return {
      'aab-typography': customElements.get('aab-typography'),
      'agent-um-simple-tile': customElements.get('agent-um-simple-tile'),
    };
  }

  render() {
    if (!this.voteCollector) {
      return null;
    }

    return html`
      <agent-um-simple-tile
        title="VoteCollector: ${this.voteCollector.groupName}"
      >
        <aab-typography>
          <label>Name</label>
          <p>${this.voteCollector.groupName}</p>

          <label>City</label>
          <p>${this.voteCollector.city || '-'}</p>

          <label>Country</label>
          <p>${this.voteCollector.country || '-'}</p>
        </aab-typography>
      </agent-um-simple-tile>
    `;
  }
}

customElements.define('agent-um-vote-collector-view', VoteCollectorView);
