import styles from './cbp-loading-spinner.module.css';

export default {
  bindings: {
      dynclass: '<',
  },
  controller: [
    function () {
      this.styles = styles;
    },
  ],
  templateUrl:
    '/features/modules/cbp-ui/components/cbp-loading-spinner/cbp-loading-spinner.template.html',
};
