export default [
  '$http',
  function ($http) {
    async function list(sidRequestId) {
      return (
        await $http.get(
          `/v2/sid/agent/sid-requests/${sidRequestId}/response-items`
        )
      ).data;
    }

    async function getById(sidRequestId, responseItemId) {
      return (
        await $http.get(
          `/v2/sid/agent/sid-requests/${sidRequestId}/response-items/${responseItemId}`
        )
      ).data;
    }

    return {
      list,
      getById,
    };
  },
];
