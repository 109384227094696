export const voteCollectorsMainState = {
  name: 'agentUserManagement.voteCollectors',
  url: '/voteCollectors',
  template: '<div ui-view></div>',
  redirectTo: 'agentUserManagement.voteCollectors.list',
};

export const listVoteCollectorsState = {
  name: 'agentUserManagement.voteCollectors.list',
  url: '/list',
  component: 'agUserManagementVoteCollectors',
};

export const viewVoteCollectorState = {
  name: 'agentUserManagement.voteCollectors.voteCollector',
  url: '/{voteCollectorId}',
  component: 'agUserManagementVoteCollector',
};

export const addVoteCollectorState = {
  name: 'agentUserManagement.voteCollectors.addVoteCollector',
  url: '/add',
  component: 'agUserManagementAddVoteCollector',
};

export const editVoteCollectorState = {
  name: 'agentUserManagement.voteCollectors.editVoteCollector',
  url: '/edit/{voteCollectorId}',
  component: 'agUserManagementEditVoteCollector',
};

/** users  */
export const viewVoteCollectorUsersState = {
  name: 'agentUserManagement.voteCollectors.users',
  url: '/{voteCollectorId}/users',
  component: 'agUserManagementVoteCollectorUsers',
};

export const viewVoteCollectorUserState = {
  name: 'agentUserManagement.voteCollectors.user',
  url: '/{voteCollectorId}/users/{userId}',
  component: 'agUserManagementVoteCollectorUser',
};

export const addVoteCollectorUserState = {
  name: 'agentUserManagement.voteCollectors.addUser',
  url: '/{voteCollectorId}/addUser',
  component: 'agUserManagementAddVoteCollectorUser',
};

export const editVoteCollectorUserState = {
  name: 'agentUserManagement.voteCollectors.editUser',
  url: '/{voteCollectorId}/editUser/{userId}',
  component: 'agUserManagementEditVoteCollectorUser',
};
