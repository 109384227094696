export const listIntermediaries = {
  name: 'agentUserManagement.intermediaries',
  url: '/intermediaries',
  template: '<div ui-view></div>',
  redirectTo: 'agentUserManagement.intermediaries.list',
};

export const listIntermediariesState = {
  name: 'agentUserManagement.intermediaries.list',
  url: '/list',
  component: 'agUserManagementIntermediaries',
};

export const viewIntermediaryState = {
  name: 'agentUserManagement.intermediaries.intermediary',
  url: '/{intermediaryId}',
  component: 'agUserManagementIntermediary',
};

export const addIntermediaryState = {
  name: 'agentUserManagement.intermediaries.addIntermediary',
  url: '/add',
  component: 'agUserManagementAddIntermediary',
};

export const editIntermediaryState = {
  name: 'agentUserManagement.intermediaries.editIntermediary',
  url: '/edit/{intermediaryId}',
  component: 'agUserManagementEditIntermediary',
};

/** users  */
export const viewIntermediaryUsersState = {
  name: 'agentUserManagement.intermediaries.users',
  url: '/{intermediaryId}/users',
  component: 'agUserManagementIntermediaryUsers',
};

export const viewIntermediaryUserState = {
  name: 'agentUserManagement.intermediaries.user',
  url: '/{intermediaryId}/users/{userId}',
  component: 'agUserManagementIntermediaryUser',
};

export const addIntermediaryUserState = {
  name: 'agentUserManagement.intermediaries.addUser',
  url: '/{intermediaryId}/addUser',
  component: 'agUserManagementAddIntermediaryUser',
};

export const editIntermediaryUserState = {
  name: 'agentUserManagement.intermediaries.editUser',
  url: '/{intermediaryId}/editUser/{userId}',
  component: 'agUserManagementEditIntermediaryUser',
};
