import './_modules/em-form-controls/em-form-controls.module';
import './_modules/emerald-2/emerald-2.module';

const emModule = angular.module('emerald', ['emFormControls', 'emerald2']);

import emButton from './em-button/em-button.component';
import emIcon from './em-icon/em-icon.component';
import emMessage from './em-message/em-message.component';
import emTag from './em-tag/em-tag.component';
import emLoadingSpinner from './em-loading-spinner/em-loading-spinner.component';
import emDivider from './em-divider/em-divider.component.js';
import emPageGradient from './em-page-gradient/em-page-gradient.component.js';

import emWell from './em-well/em-well.component';
import emTile from './em-tile/em-tile.component';
import emProductTileList from './em-product-tile-list/em-product-tile-list.component';
import emProductTile from './em-product-tile/em-product-tile.component';
import emProductTileLoader from './em-product-tile-loader/em-product-tile-loader.component';

import emHeading from './em-heading/em-heading.component';
import emTypography from './em-typography/em-typography.component';

import emHeadingSeparator from './em-heading/em-heading-separator.component';

emModule.component({
  emButton,
  emIcon,
  emMessage,
  emTag,
  emLoadingSpinner,
  emDivider,
  emPageGradient,

  emWell,
  emTile,
  emProductTileList,
  emProductTile,
  emProductTileLoader,

  emHeading,
  emTypography,

  emHeadingSeparator,
});

export default emModule;
