export default ({ actions: actionsRenderFn }) => [
  {
    id: 'firstName',
    label: 'First name',
    render: (user) => user.firstName || '-',
  },
  {
    id: 'lastName',
    label: 'Last name',
    key: 'lastName',
  },
  {
    id: 'officePhone',
    label: 'Office Phone',
    key: 'workPhone',
  },
  {
    id: 'status',
    label: 'Status',
    key: 'status',
  },
  {
    id: 'actions',
    label: 'Actions',
    render: actionsRenderFn,
  },
];
