export default {
  bindings: {
    sidRequestId: '<',
  },
  controller: [
    'AG_SidReportShareholdersRepository',
    'cbpToast',
    '$scope',
    function (SidReportShareholdersRepository, cbpToast, $scope) {
      this.$onInit = () => {
        this.loading = false;
        this.sidReport = null;

        this.fetchReport();
      };

      this.fetchReport = async () => {
        this.loading = true;
        try {
          this.sidReport = await SidReportShareholdersRepository.getProgressBySidRequestId(
            this.sidRequestId);
        } catch (err) {
          cbpToast.show(`There was an error: ${err.message}`, 'danger');
        } finally {
          this.loading = false;
          $scope.$apply();
        }
      };
    },
  ],
  templateUrl:
      'features/modules/shareholders-id/modules/agent/components/sid-request-report/sid-request-report.template.html',
};
