import './IssuerUser';
import { fetchUser } from '@/api/v1/users';

export default {
  controller: [
    '$http',
    '$scope',
    '$stateParams',
    function ($http, $scope, $stateParams) {
      this.$onInit = () => {
        this.fetchUser().then(() => {
          $scope.$apply();
        });
      };

      this.fetchUser = async () => {
        this.user = await fetchUser(
          $http,
          'IS',
          $stateParams.issuerId,
          $stateParams.userId
        );
      };
    },
  ],
  template: `
    <div class="my-3">
      <agent-um-issuer-user ng-prop-user="$ctrl.user"></agent-um-issuer-user>
    </div>
  `,
};
