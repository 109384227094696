import './sid-responding-intermediary-tile.scss';

export default {
  bindings: {
    respondingIntermediary: '<',
  },
  controller: [
    '$element',
    function ($element) {
      this.showModal = () => {
        const root = $element[0];
        const modal = root.querySelector('aab-modal');
        modal.openModal();
      };

      this.$onChanges = (changeObj) => {
        if (changeObj.respondingIntermediary?.currentValue) {
          const respondingIntermediaryCopy = angular.copy(
            changeObj.respondingIntermediary.currentValue
          );
          this.displayedLabels = createDisplayedLabels(
            respondingIntermediaryCopy
          );
          this.contactPerson = createContactPerson(
            respondingIntermediaryCopy['Contact Person']
          );
        }
      };

      function createDisplayedLabels(data) {
        return Object.keys(data)
          .filter(
            (key) =>
              ![
                'Name',
                'Alternative Name',
                'Accounts And Holdings',
                'Contact Person',
              ].includes(key)
          )
          .map((key) => {
            return {
              label: key,
              value: data[key],
            };
          });
      }

      function createContactPerson(data) {
        if (data) {
          return Object.keys(data).map((key) => {
            return {
              label: key,
              value: data[key],
            };
          });
        }
      }
    },
  ],
  templateUrl:
    'features/modules/shareholders-id/modules/common/components/sid-responding-intermediary-tile/sid-responding-intermediary-tile.template.html',
};
