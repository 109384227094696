import './IssuersView';
import { listIssuers, toggleIssuerDisabled } from '@/api/v1/userGroups';

export default {
  controller: [
    '$state',
    '$scope',
    '$http',
    function ($state, $scope, $http) {
      this.$onInit = () => {
        this.fetchIssuers().then(() => {
          $scope.$apply();
        });
      };

      this.fetchIssuers = async () => {
        this.issuers = await listIssuers($http);
      };

      this.handleAddIssuerClick = () => {
        $state.go('agentUserManagement.issuers.addIssuer');
      };

      this.handleIssuerClick = ({ detail: { issuerId } }) => {
        $state.go('agentUserManagement.issuers.issuer', { issuerId });
      };

      this.handleViewUsersClick = ({ detail: { issuerId } }) => {
        $state.go('agentUserManagement.issuers.users', { issuerId });
      };

      this.handleEditClick = ({ detail: { issuerId } }) => {
        $state.go('agentUserManagement.issuers.editIssuer', {
          issuerId,
        });
      };

      this.toggleIssuerDisabled = async ({ detail: { issuerId } }) => {
        await toggleIssuerDisabled($http, issuerId);
        this.issuers = null;
        await this.fetchIssuers();
        $scope.$apply();
      };
    },
  ],
  template: `
    <div class="my-3">
      <div class="p-5" ng-if="!$ctrl.issuers">
        <cbp-loading-spinner></cbp-loading-spinner>
      </div>
      <agent-um-issuers-view
        ng-if="$ctrl.issuers"
        ng-prop-issuers="$ctrl.issuers"
        ng-on-add-issuer-click="$ctrl.handleAddIssuerClick()"
        ng-on-issuer-click="$ctrl.handleIssuerClick($event)"
        ng-on-view-users-click="$ctrl.handleViewUsersClick($event)"
        ng-on-edit-click="$ctrl.handleEditClick($event)"
        ng-on-enable-click="$ctrl.toggleIssuerDisabled($event)"
        ng-on-disable-click="$ctrl.toggleIssuerDisabled($event)"
      ></agent-um-issuers-view>
    </div>
  `,
};
