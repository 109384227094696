import * as agentTabStates from './agent';
import * as issuerTabStates from './issuers';
import * as intermediaryTabStates from './intermediaries';
import * as proxyIntermediaryTabStates from './proxyIntermediaries';
import * as voteCollectorTabStates from './voteCollectors';
import * as searchShareholder from './shareholders';

export default [
  '$stateProvider',
  function ($stateProvider) {
    const mainState = {
      name: 'agentUserManagement',
      url: '/agent/userManagement',
      params: {
        selectedIndex: 1,
      },
      redirectTo: 'agentUserManagement.agent',
      views: {
        mainView: {
          component: 'agUserManagementMain',
        },
      },
    };

    $stateProvider.state(mainState);

    // Load all the exported state definitions in the './agent', './issuer', etc. files
    [
      agentTabStates,
      issuerTabStates,
      intermediaryTabStates,
      proxyIntermediaryTabStates,
      voteCollectorTabStates,
      searchShareholder,
    ].forEach((states) => {
      Object.values(states).forEach((state) => $stateProvider.state(state));
    });
  },
];
