const _constructPayload = (keys, payload) => {
  const fd = new FormData();

  keys.forEach((key) => {
    fd.append(key, payload[key]);
  });

  return fd;
};

export async function addIssuer($http, payload) {
  const fd = _constructPayload(
    ['groupName', 'groupEmail', 'city', 'country', 'userId', 'productCode'],
    payload
  );

  const { data } = await $http.post(`/v1/issuers`, fd, {
    headers: {
      'Content-Type': undefined,
    },
  });
  return data;
}

export async function editIssuer($http, issuerId, payload) {
  const fd = _constructPayload(
    [
      'groupName',
      'groupEmail',
      'groupId',
      'city',
      'country',
      'userId',
      'productCode',
      'status',
    ],
    payload
  );

  const { data } = await $http.post(`/v1/issuers/${issuerId}`, fd, {
    headers: {
      'Content-Type': undefined,
    },
  });
  return data;
}
