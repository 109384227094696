import inviteShareplanProgress from './invite-shareplan-progress.directive';
import progressBarBulkUpload from './progress-bar-bulk-upload.directive';
import progressBarDuplicateUpload from './progress-bar-duplicate-upload.directive';
import progressBar from './progress-bar.directive';

export default angular
  .module('progressBars', ['commonModule', 'ngStorage'])
  .directive({
    inviteShareplanProgress,
    progressBarBulkUpload,
    progressBarDuplicateUpload,
    progressBar,
  });
