export default function () {
  return {
    restrict: 'A',

    scope: {
      customEvent: '@',
      onCustomEvent: '&',
    },

    link: function (scope, element, attrs) {
      const htmlElement = element[0];

      htmlElement.addEventListener(scope.customEvent, function (event) {
        scope.onCustomEvent({
          name: scope.customEvent,
          payload: event.detail,
        });
      });
    },
  };
}
