import { SID_COMMON_BASE_URL } from 'Modules/shareholders-id/constants';
import { loadGoogleGeoCharts } from '@/lib/googleCharts';
import { g50, g300 } from '@aab/sc-styles-colors';
import countryRegionData from './countryRegionData';
import { formatAsPercentageNumber } from '@/lib/formatNumber';

function _getRandomId() {
  return (Math.random() + 1).toString(36).substring(2, 10);
}

export default {
  bindings: {
    chartId: '@',
    loading: '<',
    countryData: '<',
    totalOutstandingShares: '<',
  },
  controller: [
    '$scope',
    function ($scope) {
      this.$onInit = () => {
        this.id ||= _getRandomId();
        this.googleHasLoaded = false;
        this.region = 'world';
        this.style = {
          width: '993px',
          height: '612px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        };

        this.navigatorItems = [];

        loadGoogleGeoCharts()
          .then(() => {
            this.googleHasLoaded = true;
            this.initChart();
          })
          .finally(() => {
            $scope.$apply();
          });
      };

      this.$onChanges = (changeObj) => {
        if (this.googleHasLoaded) {
          this.initChart();
        }
      };

      this.initChart = () => {
        if (!this.countryData?.length) {
          return;
        }
        this.resetNavigatorItems();
        const chartData = this.countryData.map((countryDataItem) => {
          const {
            countryCode,
            totalLegalShares,
            totalNaturalShares,
          } = countryDataItem;
          return [countryCode, totalLegalShares + totalNaturalShares];
        });
        const options = {
          region: this.region,
          colorAxis: { colors: [g50, g300] },
          preserveAspectRatio: true,
        };
        const data = google.visualization.arrayToDataTable([
          ['Country', 'Identified Securities'],
          ...chartData,
        ]);

        const chart = new google.visualization.GeoChart(
          document.getElementById(this.chartId)
        );

        chart.draw(data, options);
      };

      this.resetNavigatorItems = () => {
        const allCountries = this.countryData.map((c) => c.countryCode);

        // Only display sub-continents if we've identified shares in them
        this.navigatorItems = countryRegionData.map((continent) => {
          return {
            name: continent.name,
            key: continent.code,
            children: continent.subContinents
              .filter((subContinent) => {
                return subContinent.countries.some((c) =>
                  allCountries.includes(c)
                );
              })
              .map((subContinent) => {
                const badgeNumber = this.countryData
                  .filter((c) => subContinent.countries.includes(c.countryCode))
                  .reduce(
                    (acc, c) => acc + c.totalLegalShares + c.totalNaturalShares,
                    0
                  );

                return {
                  name: subContinent.name,
                  key: subContinent.code,
                  badge: badgeNumber,
                };
              }),
          };
        });

        // Filter out continents for which we don't have sub-continents
        this.navigatorItems = this.navigatorItems.filter(
          (item) => item.children?.length
        );

        // For the remaining countries, add up the total of their sub-continents for their own badge
        this.navigatorItems = this.navigatorItems.map((item) => {
          return {
            ...item,
            badge: item.children.reduce((acc, child) => acc + child.badge, 0),
          };
        });

        // Add 'world'
        this.navigatorItems = [
          {
            name: 'World',
            key: 'world',
            badge: this.navigatorItems.reduce(
              (acc, item) => acc + item.badge,
              0
            ),
          },
          ...this.navigatorItems,
        ];

        // Format all badge numbers
        const formatBadge = (item) => ({
          ...item,
          children: item.children?.map(formatBadge),
          badge: `${formatAsPercentageNumber(
            item.badge / this.totalOutstandingShares,
            2
          )}%`,
        });
        this.navigatorItems = this.navigatorItems.map(formatBadge);
      };

      this.handleItemSelected = (event) => {
        this.region = event.detail.key;
        this.initChart();
      };
    },
  ],
  templateUrl: `${SID_COMMON_BASE_URL}/components/sid-country-geo-chart/sid-country-geo-chart.template.html`,
};
