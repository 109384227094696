import moment from 'moment';
import {
  SID_IS_BASE_URL,
  REPORT_DEADLINE_DATE_MIN_DAYS_LATER, DISCLOSURE_DEADLINE_DATE_MIN_DAYS_LATER,
} from 'Modules/shareholders-id/constants';

function defaultYYYYMMDD() {
  return moment().format('YYYY-MM-DD');
}

const Ctrl = [
  'SidRequestConverter',
  'IS_SidRequestRepository',
  'IS_SidRequestValidator',
  '$state',
  '$scope',
  'User',
  'issuerPreferencesService',
  function (
    SidRequestConverter,
    SidRequestRepository,
    SidRequestValidator,
    $state,
    $scope,
    User,
    issuerPreferenceService
  ) {
    this.$onInit = () => {
      this.sidReqData = {
        isin: '',
        csdId: null,
        recordDate: defaultYYYYMMDD(),
        thresholdOption: 'percentage',
        thresholdPct: 0,
        thresholdQty: 0,
        totalOutstandingShares: 0,
        startDateOption: 'asap',
        startDate: defaultYYYYMMDD(),
        disclosureDeadlineDateOption: 'default',
        disclosureDeadlineDate: moment()
          .add(DISCLOSURE_DEADLINE_DATE_MIN_DAYS_LATER, 'days')
          .format('YYYY-MM-DD'),
        reportDeadlineDateOption: 'default',
        reportDeadlineDate: moment()
          .add(REPORT_DEADLINE_DATE_MIN_DAYS_LATER, 'days')
          .format('YYYY-MM-DD'),
      };

      this.fetchCsdPreference();
    };

    this.fetchCsdPreference = async () => {
      const { issuer } = await User.getMeAsIssuer();
      const csdPreference = await issuerPreferenceService.getCsdPreferenceForIssuer(
        issuer.id
      );
      if (csdPreference) {
        this.sidReqData.csdId = csdPreference;
        $scope.$apply();
      }
    };

    this.handleInput = (sidReqData) => {
      this.sidReqData = sidReqData;
    };

    this.validate = () => {
      const validation = SidRequestValidator.validateCreationForm(
        this.sidReqData
      );
      if (validation.isValid) {
        this.error = null;
        return true;
      }
      this.error = validation.message;
      return false;
    };

    this.handleSaveAsDraft = async () => {
      if (this.validate()) {
        const sidRequestData = SidRequestConverter.sidRequestFromCreationForm(
          this.sidReqData
        );
        try {
          await SidRequestRepository.create(sidRequestData);
          $state.go('^.landing');
        } catch (err) {
          this.error = err;
          $scope.$apply();
        }
      }
    };

    this.handleSubmit = async () => {
      if (this.validate()) {
        const sidRequestData = SidRequestConverter.sidRequestFromCreationForm(
          this.sidReqData
        );
        try {
          const sidRequest = await SidRequestRepository.create(sidRequestData);
          await SidRequestRepository.requestApproval(sidRequest.sidRequestId);

          $state.go('^.landing');
        } catch (err) {
          this.error = err;
          $scope.$apply();
        }
      }
    };

    this.handleBackClick = () => {
      $state.go('^.landing');
    };
  },
];

export default {
  controller: Ctrl,
  templateUrl: `${SID_IS_BASE_URL}/views/create-request/sid-issuer-create-request.template.html`,
};
