/* eslint-disable indent */
import { LitElement, html, css, unsafeCSS } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';
import AabAccordion from '@aab/sc-aab-accordion/src/AabAccordion';
import { formatDateToDateTime } from '@/lib/date';
import { g400 } from '@aab/sc-styles-colors';

class ShareholderSearchResult extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      shareholder: { type: Object },
    };
  }

  static get scopedElements() {
    return {
      'aab-accordion': AabAccordion,
      'aab-tile': customElements.get('aab-tile'),
      'aab-typography': customElements.get('aab-typography'),
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
        margin-bottom: 1px !important;
      }

      .header {
        padding: 20px;
      }

      .content {
        padding: 24px;
      }

      .title {
        margin: 0;
        color: ${unsafeCSS(g400)};
        margin-bottom: 4px;
      }

      .subtitle {
        margin-bottom: 0;
      }
    `;
  }

  render() {
    if (!this.shareholder) {
      return null;
    }

    return html`
      <aab-tile>
        <aab-accordion>
          <div class="header" slot="header">
            <aab-typography>
              <h4 class="title">${this.shareholder.username}</h4>
              <label class="subtitle">
                Last login:
                ${this.shareholder.lastLoginTimestamp
                  ? formatDateToDateTime(this.shareholder.lastLoginTimestamp)
                  : 'Never'}
              </label>
            </aab-typography>
          </div>

          <div class="content">
            <aab-typography>
              <label>User ID</label>
              <p>${this.shareholder.id}</p>

              <label>Account locked?</label>
              <p>${this.shareholder.accountLocked ? 'Yes' : 'No'}</p>

              <label>Failed login attempts</label>
              <p>${this.shareholder.failedLoginAttempts || '0'}</p>

              <label>Last modified</label>
              <p>
                ${this.shareholder.lastModifiedTimestamp
                  ? formatDateToDateTime(this.shareholder.lastModifiedTimestamp)
                  : 'Never'}
              </p>
            </aab-typography>
          </div>
        </aab-accordion>
      </aab-tile>
    `;
  }
}

customElements.define(
  'agent-shareholder-search-result',
  ShareholderSearchResult
);
