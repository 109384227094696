import './EditIssuer';
import { fetchIssuer } from '@/api/v1/userGroups';
import { editIssuer } from '@/api/v1/issuer';
import fetchCountries from '@/api/v1/countries';

export default {
  controller: [
    '$http',
    '$scope',
    '$stateParams',
    '$sessionStorage',
    function ($http, $scope, $stateParams, $sessionStorage) {
      let countries;

      this.$onInit = () => {
        Promise.all([this.fetchIssuer(), this.fetchCountries()]).then(() => {
          $scope.$apply();
        });
      };

      this.fetchIssuer = async () => {
        this.issuer = await fetchIssuer($http, $stateParams.issuerId);
      };

      this.fetchCountries = async () => {
        countries = await fetchCountries($http);
        this.countryOptions = countries.map((c) => ({
          value: c.countryName,
          name: c.countryName,
        }));
      };

      this.handleSaveClick = async (ev) => {
        ev.detail.country = countries.find(
          (c) => c.countryName === ev.detail.country
        ).isoCountryCode;

        this.status = 'pending';
        try {
          const response = await editIssuer($http, this.issuer.groupId, {
            ...ev.detail,
            groupId: this.issuer.groupId,
            userId: $sessionStorage.userId,
            productCode: this.issuer.productCode,
          });
          this.status = 'success';
          this.statusMessage = response.message;
        } catch (err) {
          this.status = 'error';
          this.statusMessage = err.data.errors.map((e) => e.errorMessage);
        } finally {
          $scope.$apply();
        }
      };
    },
  ],
  template: `
    <div class="agUserManagementEditIssuer my-3">
      <div class="p-5" ng-if="!$ctrl.issuer">
        <cbp-loading-spinner></cbp-loading-spinner>
      </div>

      <agent-um-edit-issuer
        ng-if="$ctrl.issuer"
        ng-prop-issuer="$ctrl.issuer"
        ng-prop-country_options="$ctrl.countryOptions"
        ng-on-save-click="$ctrl.handleSaveClick($event)"
      >
        <agent-um-status-message-renderer
          ng-prop-status="$ctrl.status"
          ng-prop-status_message="$ctrl.statusMessage"
        ></agent-um-status-message-renderer>
      </agent-um-edit-issuer>
    </div>
  `,
};
