import { SID_COMMON_BASE_URL } from 'Modules/shareholders-id/constants';

const Ctrl = [
  'csdService',
  '$scope',
  function (csdService, $scope) {
    this.$onInit = async () => {
      this.fetchCsds();

      if (this.sidRequestData && this.sidRequestData.issuerId) {
        this.issuerInputValue = this.issuers.find(
          (i) => i.id === this.sidRequestData.issuerId
        ).name;
      }

      this.thresholdOptions = {
        percentage: 'Percentage',
        quantity: 'Quantity',
      };

      this.startDateOptions = {
        asap: 'A.s.a.p.',
        scheduled: 'Scheduled',
      };

      this.reportDeadlineDateOptions = {
        default: 'Default',
        custom: 'Custom',
      };

      this.disclosureDeadlineDateOptions = {
        default: 'Default',
        custom: 'Custom',
      };

      this.isinInfo =
        'The ISIN of the security of which you want the holders disclosed';
      this.totalOutstandingSharesInfo =
        'Please enter the total number of outstanding shares for this security';
      this.recordDateInfo =
        'The returned results will be according to the standings on this particular date.';
      this.thresholdInfo =
        'Only shareholders with a larger share than the specified threshold will be reported in the results.';
      this.startDateInfo =
        "The date at which the request will be sent out. <br><br> <em>A.s.a.p.</em>: the request will be sent out as soon as it's approved.<br> <em>Scheduled</em>: the request will be sent out at the specified time";
      this.disclosureDeadlineInfo =
        'The default deadline date is one week from the start date';
      this.reportDeadlineInfo =
        'The default deadline date is two weeks from the start date';
    };

    this.issuersToOptions = (issuers) =>
      issuers.map((i) => ({
        value: i.id,
        name: i.name,
      }));

    this.handleChange = (value, name) => {
      const sidRequestData = { ...this.sidRequestData, [name]: value };
      this.onInput({
        sidRequestData,
      });
    };

    this.handleIssuerTextChange = (text) => {
      this.issuerInputValue = text;

      if (!this.issuerInputValue) {
        this.issuerOptions = [];
      } else {
        this.issuerOptions = this.issuersToOptions(
          this.issuers.filter((i) =>
            i.name.toLowerCase().includes(text.toLowerCase())
          )
        );
      }
    };

    this.handleIssuerSelected = (option) => {
      this.issuerInputValue = option.name;
      this.issuerOptions = [];

      const sidRequestData = { ...this.sidRequestData, issuerId: option.value };
      this.onInput({
        sidRequestData,
      });
    };

    this.handleIssuerClearClick = () => {
      this.issuerInputValue = '';
      this.issuerOptions = [];
      const sidRequestData = { ...this.sidRequestData, issuerId: null };
      this.onInput({
        sidRequestData,
      });
    };

    this.fetchCsds = async () => {
      this.fetchingCsds = true;
      try {
        const csds = await csdService.list();
        this.csdOptions = csds.reduce((acc, csd) => {
          acc[csd.csdId] = csd.name;
          return acc;
        }, {});
        this.onFetchCsds({ csds });
        $scope.$apply();
      } catch (err) {
        this.csdError = err;
      } finally {
        this.fetchingCsds = false;
      }
    };
  },
];

export default {
  bindings: {
    sidRequestData: '<',
    issuers: '<',
    source: '<',
    onFetchCsds: '&',
    onInput: '&',
  },
  controller: Ctrl,
  templateUrl: `${SID_COMMON_BASE_URL}/components/sid-request-input/sid-request-input.template.html`,
};
