/**
 * This module will contain all logic, components, you-name-it's, that are common to/shared by all workstations. As
 * such, this module is to be a module-dependency for all workstationStation-specific SID modules.
 */
import 'Modules/env/env.module';
import 'Modules/cbp-ui/cbp-ui.module';
import 'Modules/skeleton-loader/skeleton-loader.module';
import 'Modules/cbp-repository-utils/cbp-repository-utils.module';
import 'Modules/utils/iso-3166-filter/iso-3166-filter.module';
import 'Modules/utils/custom-event-adapter/custom-event-adapter.module';

import './scss/sid-create-request.scss';

const sidCommonModule = angular.module('sidCommon', [
  'env',
  'cbpUi',
  'skeletonLoader',
  'cbpRepositoryUtils',
  'iso3166Filter',
  'customEventAdapter',
]);

/**
 * Services, etc.
 */
import sidRequestConverter from './model/converters/sid-request-converter.service';

import sidRequestValidator from './model/services/sid-request-validator.service';
import sidReportDownloader from './model/services/sid-report-downloader.service';
import validationResult from './model/services/validation-result';

sidCommonModule.factory('SidRequestConverter', sidRequestConverter);
sidCommonModule.factory('SidRequestValidator', sidRequestValidator);
sidCommonModule.factory('SidReportDownloader', sidReportDownloader);
sidCommonModule.factory('ValidationResult', validationResult);

/**
 * Components
 */
import sidAccountsAndHoldingsAccordion from './components/sid-accounts-and-holdings-accordion/sid-accounts-and-holdings-accordion.component';
import sidAddress from './components/sid-address/sid-address.component';
import sidPageContentTile from './components/page-content-tile/page-content-tile.component';
import sidCountryGeoChart from './components/sid-country-geo-chart/sid-country-geo-chart.component';
import sidStatusTag from './components/sid-status-tag/sid-status-tag.component';
import sidRequestDl from './components/sid-request-dl/sid-request-dl.component';
import sidRequestFormFooter from './components/sid-request-form-footer/sid-request-form-footer.component';
import sidRequestInput from './components/sid-request-input/sid-request-input.component';
import sidRequestInputSummary from './components/sid-request-input-summary/sid-request-input-summary.component';
import sidRequestResultsImage from './components/sid-request-results-image/sid-request-results-image.component';
import sidRequestReportShareholderTile from './components/sid-request-report-shareholder-tile/sid-request-report-shareholder-tile.component';
import sidRequestReportNomiTile from './components/sid-request-report-nomi-tile/sid-request-report-nomi-tile.component';
import sidRequestTimeline from './components/sid-request-timeline/sid-request-timeline.component';
import sidRequestReport from './components/sid-request-report/sid-request-report.component';

import sidRespondingIntermediaryTile from './components/sid-responding-intermediary-tile/sid-responding-intermediary-tile.component';
import sidResponseItem from './components/sid-response-item/sid-response-item.component';
import sidResponseItemTypeTag from './components/sid-response-item-type-tag/sid-response-item-type-tag.component';
import sidResponseLegalPersonInfo from './components/sid-response-legal-person-info/sid-response-legal-person-info.component';
import sidResponseNaturalPersonInfo from './components/sid-response-natural-person-info/sid-response-natural-person-info.component';
import sidResponseShareholdingTile from './components/sid-response-shareholding-tile/sid-response-shareholding-tile.component';

import sidShareholdersCountryChart from './components/sid-shareholders-country-chart/sid-shareholders-country-chart.component';
import sidSharesCountryChart from './components/sid-shares-country-chart/sid-shares-country-chart.component';

import sidThirdParty from './components/sid-third-party/sid-third-party.component';

sidCommonModule.component({
  sidAccountsAndHoldingsAccordion,
  sidAddress,
  sidPageContentTile,
  sidCountryGeoChart,
  sidStatusTag,
  sidRequestDl,
  sidRequestFormFooter,
  sidRequestInput,
  sidRequestInputSummary,
  sidRequestResultsImage,
  sidRequestReportShareholderTile,
  sidRequestReportNomiTile,
  sidRequestTimeline,
  sidRequestReport,

  sidRespondingIntermediaryTile,
  sidResponseItem,
  sidResponseItemTypeTag,
  sidResponseLegalPersonInfo,
  sidResponseNaturalPersonInfo,
  sidResponseShareholdingTile,

  sidShareholdersCountryChart,
  sidSharesCountryChart,

  sidThirdParty,
});

import investorTypeFilter from './filters/investorType.filter';
import investorTypeHelpFilter from './filters/investorTypeHelp.filter';
import ownershipTypeFilter from './filters/ownershipType.filter';
import ownershipTypeHelpFilter from './filters/ownershipTypeHelp.filter';

sidCommonModule.filter('investorType', function () {
  return investorTypeFilter;
});
sidCommonModule.filter('investorTypeHelp', function () {
  return investorTypeHelpFilter;
});
sidCommonModule.filter('ownershipType', function () {
  return ownershipTypeFilter;
});
sidCommonModule.filter('ownershipTypeHelp', function () {
  return ownershipTypeHelpFilter;
});

export default sidCommonModule;
