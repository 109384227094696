/**
 * This is a port of [vue-content-loader](https://github.com/egoist/vue-content-loader/blob/master/src/ContentLoader.js)
 * (which is a port of react-content-loader)
 */

const BASE_URL = '/features/modules';

module.exports = {
  bindings: {
    width: '@',
    height: '@',
    speed: '@',
    preserveAspectRatio: '@',
    baseUrl: '@',
    primaryColor: '@',
    secondaryColor: '@',
    primaryOpacity: '@',
    secondaryOpacity: '@',
    uniqueKey: '@',
    animate: '<'
  },
  controller: [function() {
    this.$onInit = () => {
      this.setDefault('width', 400);
      this.setDefault('height', 130);
      this.setDefault('speed', 2);
      this.setDefault('preserveAspectRatio', 'xMidYMid meet');
      this.setDefault('baseUrl', '');
      this.setDefault('primaryColor', '#f9f9f9');
      this.setDefault('secondaryColor', '#ecebeb');
      this.setDefault('primaryOpacity', 1);
      this.setDefault('secondaryOpacity', 1);
      this.setDefault('animate', true);

      this.clipId = this.uniqueKey ? `${this.uniqueKey}-clipId` : this.randomId();
      this.gradientId = this.uniqueKey ? `${this.uniqueKey}-gradientId` : this.randomId();
    };

    this.randomId = () => {
      return Math.random()
        .toString(36)
        .substring(2);
    };

    this.setDefault = (key, value) => {
      if (this[key] == undefined && this[key] !== false) {
        this[key] = value;
      }
    };
  }],
  transclude: true,
  templateUrl: `${BASE_URL}/skeleton-loader/skeleton-loader.template.html`
};