import styles from './cbp-background-cover.module.scss';

export default {
  controller: [
    function () {
      this.styles = styles;
    },
  ],
  templateUrl:
    '/features/modules/cbp-ui/components/cbp-background-cover/cbp-background-cover.template.html',
  transclude: true,
};
