const { BASE_URL } = require('../constants');

module.exports = {
  bindings: {
    for: '@',
    rule: '@',
    message: '@'
  },
  controller: ['$element', function($element) {
    this.$onInit = () => {
      this.form = $element.controller('form');
    };
  }],
  templateUrl: `${BASE_URL}/em-input-validation-message/em-input-validation-message.template.html`
};