import './em-form-controls.scss';

import emInput from './em-input/em-input.component';
import emInputWAccessory from './em-input-w-accessory/em-input-w-accessory.component';
import emRadioInputs from './em-radio-inputs/em-radio-inputs.component';
import emDateInput from './em-date-input/em-date-input.component';
import emIsinInput from './em-isin-input/em-isin-input.component';
import emNumberInput from './em-number-input/em-number-input.component';
import emSelect from './em-select/em-select.component';
import emFileInput from './em-file-input/em-file-input.component';
import iText from './i-text/i-text.component';
import emInputValidationMessage from './em-input-validation-message/em-input-validation-message.component';
import emDatetimeInput from './em-datetime-input/em-datetime-input.component';

const emFormControls = angular.module('emFormControls', []);

emFormControls.component({
  emInput,
  emInputWAccessory,
  emRadioInputs,
  emDateInput,
  emIsinInput,
  emNumberInput,
  emSelect,
  emFileInput,
  iText,
  emInputValidationMessage,
  emDatetimeInput,
});

export default emFormControls;
