module.exports = [function() {
  
  /**
   * The CBP Toast component defines an actual implementation for this function. It's just here to show it's part of
   * the interface
   * 
   * @param {string} message 
   * @param {string} style - reuses the standard Emerald style/variant names: info, positive, negative, warning, etc. 
   */
  function show(message, style) {}  

  return {
    show
  };
}];