import './ProxyIntermediariesView';
import {
  listProxyIntermediaries,
  toggleIntermediaryDisabled,
} from '@/api/v1/userGroups';

export default {
  controller: [
    '$state',
    '$http',
    '$scope',
    function ($state, $http, $scope) {
      this.$onInit = () => {
        this.fetchProxyIntermediaries().then(() => {
          $scope.$apply();
        });
      };

      this.fetchProxyIntermediaries = async () => {
        this.proxyIntermediaries = await listProxyIntermediaries($http);
      };

      this.handleAddProxyIntermediaryClick = () => {
        $state.go(
          'agentUserManagement.proxyIntermediaries.addProxyIntermediary'
        );
      };

      this.handleProxyIntermediaryClick = ({
        detail: { proxyIntermediaryId },
      }) => {
        $state.go('agentUserManagement.proxyIntermediaries.proxyIntermediary', {
          proxyIntermediaryId,
        });
      };

      this.handleViewUsersClick = ({ detail: { proxyIntermediaryId } }) => {
        $state.go('agentUserManagement.proxyIntermediaries.users', {
          proxyIntermediaryId,
        });
      };

      this.handleEditClick = ({ detail: { proxyIntermediaryId } }) => {
        $state.go(
          'agentUserManagement.proxyIntermediaries.editProxyIntermediary',
          {
            proxyIntermediaryId,
          }
        );
      };

      this.toggleIntermediaryDisabled = async ({
        detail: { proxyIntermediaryId },
      }) => {
        await toggleIntermediaryDisabled($http, proxyIntermediaryId);
        this.proxyIntermediaries = null;
        await this.fetchProxyIntermediaries();
        $scope.$apply();
      };
    },
  ],
  template: `
    <div class="my-3">
      <div class="p-5" ng-if="!$ctrl.proxyIntermediaries">
        <cbp-loading-spinner></cbp-loading-spinner>
      </div>
      <agent-um-proxy-intermediaries-view
        ng-if="$ctrl.proxyIntermediaries"
        ng-prop-proxy_intermediaries="$ctrl.proxyIntermediaries"
        ng-on-add-proxy-intermediary-click="$ctrl.handleAddProxyIntermediaryClick()"
        ng-on-proxy-intermediary-click="$ctrl.handleProxyIntermediaryClick($event)"
        ng-on-view-users-click="$ctrl.handleViewUsersClick($event)"
        ng-on-edit-click="$ctrl.handleEditClick($event)"
        ng-on-enable-click="$ctrl.toggleIntermediaryDisabled($event)"
        ng-on-disable-click="$ctrl.toggleIntermediaryDisabled($event)"
      ></agent-um-proxy-intermediaries-view>
    </div>
  `,
};
