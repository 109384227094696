const investorTypeHelpTextMap = {
  ELIG:
    'Eligible customers are the most sophisticated level of investor, able to opt out of some the protections afforded by conduct of business rules.',
  RETL: 'Retail customers are the least sophisticated level of investor.',
  PROF:
    'Professional customers are, for example, investment firms, credit institutions, insurance companies.',
  PROP: 'Proprietary investor type',
  NONE: 'Unknown investor type',
};

export default function (investorTypeEnum) {
  return investorTypeHelpTextMap[investorTypeEnum];
}
