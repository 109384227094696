export default [
  '$http',
  function ($http) {
    return {
      async getByRequestId(requestId) {
        const { data } = await $http.get(
          `/v2/sid/issuer/sid-reports/${requestId}/dataPerCountry`
        );
        return data;
      },
    };
  },
];
