import './sid-accounts-and-holdings.scss';
import { formatStringMaxLength } from '@/lib/sid/formatBoDetails';

const Ctrl = [
  function () {
    this.$onChanges = (changeObj) => {
      if (changeObj.accountsAndHoldings?.currentValue) {
        const accountsAndHoldingsCopy = angular.copy(
          changeObj.accountsAndHoldings.currentValue
        );
        this.holdingName = formatStringMaxLength(
          accountsAndHoldingsCopy['Shareholder Name'],
          50
        );
        this.address = accountsAndHoldingsCopy['Address'];
        this.thirdParties = accountsAndHoldingsCopy['Third Parties'];
        this.displayedLabels = Object.keys(accountsAndHoldingsCopy)
          .filter(
            (key) =>
              ![
                'Securities Account Number',
                'Address',
                'Third Parties',
              ].includes(key)
          )
          .map((key) => {
            return {
              label: key,
              value: accountsAndHoldingsCopy[key],
            };
          });
      }
    };
  },
];

export default {
  bindings: {
    accountsAndHoldings: '<',
  },
  controller: Ctrl,
  templateUrl:
    'features/modules/shareholders-id/modules/common/components/sid-accounts-and-holdings-accordion/sid-accounts-and-holdings-accordion.template.html',
};
