let cache = {};
export default async ($http, languageCode = 'EN') => {
  if (cache[languageCode]) {
    return cache[languageCode];
  }
  const response = await $http.get('/v2/common/open/countries', {
    params: {
      languageCode,
    },
  });
  cache[languageCode] = response.data;
  return cache[languageCode];
};
