require('./cbp-toast.scss');

const TOAST_DURATION = 5000;

const Ctrl = ['cbpToast', '$timeout', function (cbpToast, $timeout) {
  this.$onInit = () => {
    this.style = 'info';
    this.show = false;

    this.styleMap = {
      info: 'info',
      default: 'info',
      success: 'positive',
      positive: 'positive',
      danger: 'negative',
      error: 'negative',
      negative: 'negative',
      warning: 'warning',
      privacy: 'privacy'
    };

    cbpToast.show = (message, style) => {
      this.message = message;
      this.style = style;
      this.show = true;

      $timeout(() => {
        this.clearToast();
      }, TOAST_DURATION);
    };
  };

  this.clearToast = () => {
    this.show = false;
  };

}];

module.exports = {
  controller: Ctrl,
  templateUrl: '/features/modules/cbp-toast/cbp-toast.template.html'
};