import './EditVoteCollector';
import { fetchVoteCollector } from '@/api/v1/userGroups';

export default {
  controller: [
    '$http',
    '$scope',
    '$stateParams',
    '$state',
    function ($http, $scope, $stateParams, $state) {
      this.$onInit = () => {
        $state.go('userMaintenance.editVCGroup', {
          groupId: $stateParams.voteCollectorId,
        });

        this.fetchVoteCollector().then(() => {
          $scope.$apply();
        });
      };

      this.fetchVoteCollector = async () => {
        this.voteCollector = await fetchVoteCollector(
          $http,
          $stateParams.voteCollectorId
        );
      };
    },
  ],
  template: `
    <div class="agUserManagementEditVoteCollector my-3">
      <div class="p-5" ng-if="!$ctrl.voteCollector">
        <cbp-loading-spinner></cbp-loading-spinner>
      </div>

      <agent-um-edit-vote-collector
        ng-if="$ctrl.voteCollector"
        ng-prop-vote_collector="$ctrl.voteCollector"
        ng-on-save-click="$ctrl.handleSaveClick($event)"
      ></agent-um-edit-voteCollector>
    </div>
  `,
};
