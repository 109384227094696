import { LitElement, html, css } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class AddVoteCollectorView extends ScopedElementsMixin(LitElement) {
  static get scopedElements() {
    return {
      'vote-collector-input-form': customElements.get(
        'agent-um-vote-collector-input-form'
      ),
      'agent-um-simple-tile': customElements.get('agent-um-simple-tile'),
    };
  }

  static get styles() {
    return css`
      aab-input,
      aab-select {
        display: block;
        margin-bottom: 1rem;
      }
    `;
  }

  _handleSaveClick(event) {
    console.warn(event);
  }

  render() {
    return html`
      <agent-um-simple-tile title="Add vote collector">
        <vote-collector-input-form
          @save-click="${this._handleSaveClick}"
        ></vote-collector-input-form>
      </agent-um-simple-tile>
    `;
  }
}

customElements.define('agent-um-add-vote-collector-view', AddVoteCollectorView);
