export default [
  'SidRequestValidator',
  function (SidRequestValidator) {
    function validateCreationForm(sidReqData) {
      return SidRequestValidator.validateIssuerCreationForm(sidReqData);
    }

    return {
      validateCreationForm,
    };
  },
];
