/* eslint-disable indent */
import { LitElement, html } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';
import createTableHeaders from '../../../lib/userTableHeaders';
import { userFilter } from '../../../lib/filters';

class AgentUsers extends ScopedElementsMixin(LitElement) {
  constructor() {
    super();
    this.tableHeaders = createTableHeaders({
      actions: (user) => html`
        <agent-um-user-table-actions
          .toggleButtonIsEnable=${user.status !== 'Enabled'}
          @view-click="${() => this._handleUserClick(user.userId)}"
          @edit-click="${() => this._handleEditUserClick(user.userId)}"
          @enable-click="${() => this._handleEnableClick(user.userId)}"
          @disable-click="${() => this._handleDisableClick(user.userId)}"
        ></agent-um-user-table-actions>
      `,
    });

    // Agent user table apparently has an additional column
    this.tableHeaders.splice(4, 0, {
      id: 'isAdmin',
      label: 'Is Admin',
      render: (user) => (user.isAdmin === 'Y' ? 'Yes' : 'No'),
    });
  }

  static get properties() {
    return {
      users: { type: Array },

      nameFilter: { type: String },
    };
  }

  static get scopedElements() {
    return {
      'aab-tile': customElements.get('aab-tile'),
      'aab-simple-table': customElements.get('aab-simple-table'),
      'agent-um-button-and-input-tile': customElements.get(
        'agent-um-button-and-input-tile'
      ),
      'agent-um-user-table-actions': customElements.get(
        'agent-um-user-table-actions'
      ),
    };
  }

  _handleAddUserClick() {
    this.dispatchEvent(new CustomEvent('add-user-click'));
  }

  _handleUserClick(userId) {
    this.dispatchEvent(new CustomEvent('user-click', { detail: { userId } }));
  }

  _handleEditUserClick(userId) {
    this.dispatchEvent(
      new CustomEvent('edit-user-click', { detail: { userId } })
    );
  }

  _handleEnableClick(userId) {
    this.dispatchEvent(new CustomEvent('enable-click', { detail: { userId } }));
  }

  _handleDisableClick(userId) {
    this.dispatchEvent(
      new CustomEvent('disable-click', { detail: { userId } })
    );
  }

  _filteredData() {
    if (!this.nameFilter) {
      return this.users;
    } else {
      return this.users.filter((u) => userFilter(u, this.nameFilter));
    }
  }

  render() {
    return html`
      <agent-um-button-and-input-tile
        buttonTitle="Add user"
        inputPlaceholder="Search"
        @button-click="${this._handleAddUserClick}"
        @input-change="${(ev) => {
          this.nameFilter = ev.detail.value;
        }}"
      >
        <aab-tile>
          <aab-simple-table
            fullWidth
            fixed
            paginated
            .columnHeaders="${this.tableHeaders}"
            .data="${this._filteredData()}"
          ></aab-simple-table>
        </aab-tile>
      </agent-um-button-and-input-tile>
    `;
  }
}

customElements.define('agent-um-agent-users', AgentUsers);
