/* eslint-disable indent */
import { LitElement, html, css } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class ButtonAndInputTile extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      buttonTitle: { type: String },
      inputPlaceholder: { type: String },
    };
  }

  static get scopedElements() {
    return {
      'aab-button': customElements.get('aab-button'),
      'aab-input': customElements.get('aab-input'),
      'aab-tile': customElements.get('aab-tile'),
    };
  }

  static get styles() {
    return css`
      main {
        padding: 32px;
      }

      .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
      }

      input {
        width: 320px;
      }
    `;
  }

  _handleButtonClick() {
    this.dispatchEvent(new CustomEvent('button-click'));
  }

  _handleInput({ target: { value } }) {
    this.dispatchEvent(new CustomEvent('input-change', { detail: { value } }));
  }

  render() {
    return html`<aab-tile>
      <main>
        <div class="header">
          <aab-button style-type="base">
            <button @click="${this._handleButtonClick}">
              ${this.buttonTitle}
            </button>
          </aab-button>

          <aab-input
            ><input
              placeholder="${this.inputPlaceholder || ''}"
              @input=${this._handleInput}
          /></aab-input>
        </div>

        <div>
          <slot></slot>
        </div>
      </main>
    </aab-tile>`;
  }
}

customElements.define('agent-um-button-and-input-tile', ButtonAndInputTile);
