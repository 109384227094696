/**
 * To allow the user to enter a (usually) large number as easily as possible,
 * we allow the user to input a string of any format they want, and we filter
 * out all but the numeric characters.
 *
 * For the intended usage:
 * input: '1 000 000' (fr), '1,000,000' (us), '1.000.000' (nl)
 * all return: 1_000_000 (one million)
 *
 * The leniency of the function means that nonsensical inputs (like
 * 1,0,0.0,0.0 0) will also return numbers, but we can (seriously)
 * take that as "being a feature, not a bug".
 *
 * @param {string} input
 * @returns {number}
 */
export function parseLargeInt(input) {
  if (!input) {
    return 0;
  } else if (typeof input === 'string') {
    input = input.replace(/[^0-9]/g, '');
    return parseInt(input, 10);
  } else {
    return input;
  }
}

/**
 * Again, we implement this function to be extremely naive, to a) have the
 * entering of the input as easy as possible, and b) because we don't need
 * the most complex parsing logic, since we can trust the user not to enter
 * complete garbage.
 *
 * This parsing only covers the errors that can occer due to locale-based
 * dot/comma differences. Most countries use a dot as the decimal separator,
 * some use a comma. Since floats also use dots, we simply change all comma's
 * to dots and use parseFloat()
 *
 * The user SHOULD never enter other characters, since - this is a percentage
 * - it should never reach 1,000.
 *
 * @param {string} input
 * @returns {number}
 */
export function parsePercentageNumber(input) {
  if (!input) {
    return 0;
  } else if (typeof input === 'string') {
    input = input.replace(/,/g, '.');
    return parseFloat(input);
  } else {
    return input;
  }
}
