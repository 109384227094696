/**
 * Sorts the array DESCENDING, according to
 * totalLegalShares + totalNaturalShares
 *
 * @typedef {object} SidCountryDataItem
 * @property {string | CountryCode | 'NONE' | 'AGG'} CountryCode
 * @property {number} totalLegalShares
 * @property {number} totalNaturalShares
 * @property {number} numberLegalShareholders
 * @property {number} numberNaturalShareholders
 *
 * @typedef {SidCountryDataItem[]} SidCountryData
 *
 * @param {SidCountryData} sidCountryData
 * @returns {SidCountryData}
 */
export function sortCountryDataByShares(sidCountryData) {
  function sortFn(c1, c2) {
    if (
      c1.totalLegalShares + c1.totalNaturalShares >
      c2.totalLegalShares + c2.totalNaturalShares
    ) {
      return -1;
    } else if (
      c1.totalLegalShares + c1.totalNaturalShares <
      c2.totalLegalShares + c2.totalNaturalShares
    ) {
      return 1;
    } else {
      0;
    }
  }
  return sidCountryData.sort(sortFn);
}

/**
 * Sorts the array DESCENDING, according to
 * totalLegalShareholder + numberNaturalShareholders
 *
 * @param {SidCountryData} sidCountryData
 * @returns {SidCountryData}
 */
export function sortCountryDataByShareholders(sidCountryData) {
  function sortFn(c1, c2) {
    if (
      c1.numberLegalShareholders + c1.numberNaturalShareholders >
      c2.numberLegalShareholders + c2.numberNaturalShareholders
    ) {
      return -1;
    } else if (
      c1.numberLegalShareholders + c1.numberNaturalShareholders <
      c2.numberLegalShareholders + c2.numberNaturalShareholders
    ) {
      return 1;
    } else {
      0;
    }
  }
  return sidCountryData.sort(sortFn);
}
