import Chart from 'chart.js/auto';

const g300 = 'rgba(0, 113, 107)';
const g300a = 'rgba(0, 113, 107, 0.9)';

const g75 = 'rgba(134, 209, 205)';
const g75a = 'rgba(134, 209, 205, 0.9)';

const y75 = 'rgb(255, 210, 0)';
const y75a = 'rgb(255, 210, 0, 0.9)';

export const chartColors = [
  {
    default: g300,
    light: g300a,
  },
  {
    default: g75,
    light: g75a,
  },
  {
    default: y75,
    light: y75a,
  },
];

export default {
  bindings: {
    type: '@',
    data: '<',
    options: '<',
    width: '<',
    height: '<',
    description: '@',
  },
  controller: [
    '$timeout',
    '$element',
    function ($timeout, $element) {
      this.$onInit = () => {
        this.data ||= [];
        this.options ||= {};

        $timeout(() => {
          this.element = $element[0];
          this.chart = new Chart(
            this.element.querySelector('.cbp-chart-canvas'),
            {
              type: this.type,
              data: this.data,
              options: this.options,
            }
          );
        });
      };

      this.$onChanges = (changesObj) => {
        if (
          changesObj.data.previousValue.datasets &&
          changesObj.data.currentValue.datasets
        ) {
          this.chart.destroy();
          this.chart = new Chart(
            this.element.querySelector('.cbp-chart-canvas'),
            {
              type: this.type,
              data: this.data,
              options: this.options,
            }
          );
        }
      };
    },
  ],
  templateUrl:
    '/features/modules/cbp-ui/components/cbp-chart/cbp-chart.template.html',
};
