/* eslint-disable indent */
import { LitElement, html } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class EditIssuer extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      issuer: { type: Object },
      issuerData: { type: Object, attribute: false },
      countryOptions: { type: Array },
    };
  }

  static get scopedElements() {
    return {
      'issuer-input-form': customElements.get('agent-um-issuer-input-form'),
      'agent-um-simple-tile': customElements.get('agent-um-simple-tile'),
    };
  }

  firstUpdated() {
    this.issuerData = { ...this.issuer };
  }

  _handleSaveClick(ev) {
    this.dispatchEvent(
      new CustomEvent('save-click', { detail: { ...ev.detail } })
    );
  }

  render() {
    if (!this.issuer) {
      return null;
    }

    return html`
      <agent-um-simple-tile title="Edit issuer: ${this.issuer.groupName}">
        <issuer-input-form
          .issuer=${this.issuerData}
          .countryOptions=${this.countryOptions}
          @issuer-change="${(ev) => (this.issuerData = { ...ev.detail })}"
          @save-click="${this._handleSaveClick}"
        ></issuer-input-form>

        <slot></slot>
      </agent-um-simple-tile>
    `;
  }
}

customElements.define('agent-um-edit-issuer', EditIssuer);
