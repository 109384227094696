export default [
  '$stateProvider',
  function ($stateProvider) {
    /**
     * Agent states
     */
    const mainState = {
      name: 'shareholders-id',
      url: '/shareholders-id',
      params: {
        selectedIndex: 3,
      },
      views: {
        mainView: {
          component: 'sidMain',
        },
      },
      redirectTo: (trans) => {
        const $sessionStorage = trans.injector().get('$sessionStorage');
        if ($sessionStorage.usertype === 'AG') {
          return 'shareholders-id.agent';
        } else if ($sessionStorage.usertype === 'IS') {
          return 'shareholders-id.issuer';
        } else if ($sessionStorage.usertype === 'IN'){
          return 'shareholders-id.intermediary';
        }
      },
    };
    $stateProvider.state(mainState);
  },
];
