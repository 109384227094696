import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import './progress-timeline-event.scss';

export default {
  bindings: {
    r: '<',
    color: '@',
    description: '@',
  },
  controller: [
    '$element',
    function ($element) {
      this.$onInit = () => {
        const element = $element[0];
        tippy(element, {
          content: this.description,
          theme: 'progress-timeline',
          allowHTML: true,
          hideOnClick: false,
        });
      };
    },
  ],
  templateUrl:
    '/features/modules/cbp-ui/components/progress-timeline-event/progress-timeline-event.template.html',
};
