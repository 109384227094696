import moment from 'moment';
import {
  SID_AG_BASE_URL,
  REPORT_DEADLINE_DATE_MIN_DAYS_LATER, DISCLOSURE_DEADLINE_DATE_MIN_DAYS_LATER,
} from 'Modules/shareholders-id/constants';

function defaultYYYYMMDD() {
  return moment().format('YYYY-MM-DD');
}

const Ctrl = [
  '$state',
  'AG_SidRequestRepository',
  'AG_IssuerRepository',
  'SidRequestConverter',
  'AG_SidRequestValidator',
  'cbpToast',
  'issuerPreferencesService',
  '$scope',
  '$element',
  function (
    $state,
    SidRequestRepository,
    IssuerRepository,
    SidRequestConverter,
    SidRequestValidator,
    cbpToast,
    issuerPreferencesService,
    $scope,
    $element
  ) {
    this.setAction = null;
    this.$onInit = async () => {
      this.fetchIssuers();

      this.sidReqData = {
        isin: '',
        csdId: null,
        recordDate: defaultYYYYMMDD(),
        thresholdOption: 'percentage',
        thresholdPct: 0,
        thresholdQty: 0,
        totalOutstandingShares: 0,
        startDateOption: 'asap',
        startDate: defaultYYYYMMDD(),
        disclosureDeadlineDateOption: 'default',
        disclosureDeadlineDate: moment()
          .add(DISCLOSURE_DEADLINE_DATE_MIN_DAYS_LATER, 'days')
          .format('YYYY-MM-DD'),
        reportDeadlineDateOption: 'default',
        reportDeadlineDate: moment()
          .add(REPORT_DEADLINE_DATE_MIN_DAYS_LATER, 'days')
          .format('YYYY-MM-DD'),
      };
    };

    this.fetchIssuers = async () => {
      this.loading = true;
      try {
        this.issuers = (
          await IssuerRepository.list({
            pageNo: 0,
            productCode: 'GM',
            active: true,
          })
        ).content.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
        $scope.$apply();
      }
    };

    this.handleInput = (sidReqData) => {
      if (
        sidReqData.issuerId !== null &&
        sidReqData.issuerId !== this.sidReqData.issuerId
      ) {
        // Fetch issuerPrefs, and set the csdId
        this.updateCsdIdForIssuer(sidReqData.issuerId);
      }
      this.sidReqData = sidReqData;
    };

    this.updateCsdIdForIssuer = async (issuerId) => {
      const csdPreference = await issuerPreferencesService.getCsdPreferenceForIssuer(
        issuerId
      );
      if (csdPreference) {
        this.sidReqData.csdId = csdPreference;
        $scope.$apply();
      }
    };

    this.showModal = () => {
      const root = $element[0];
      const modal = root.querySelector('aab-modal');
      modal.openModal();
    };

    this.closeModal = () => {
      this.setAction = null;
      const root = $element[0];
      const modal = root.querySelector('aab-modal');
      modal.closeModal(new Event('click'));
    };

    this.validate = () => {
      const validation = SidRequestValidator.validateCreationForm(
        this.sidReqData
      );
      if (validation.isValid) {
        this.error = null;
        return true;
      }
      this.error = validation.message;
      return false;
    };

    this.handleSaveAsDraft = async () => {
      const sidRequestData = SidRequestConverter.sidRequestFromCreationForm(
        this.sidReqData
      );
      try {
        await SidRequestRepository.create(sidRequestData);
        cbpToast.show('The SID request has been saved as draft.', 'success');
        $state.go('shareholders-id.agent.landing');
      } catch (err) {
        this.error = err;
        $scope.$apply();
      }
    };

    this.handleSubmit = async () => {
      const sidRequestData = SidRequestConverter.sidRequestFromCreationForm(
        this.sidReqData
      );
      try {
        const sidRequest = await SidRequestRepository.create(sidRequestData);
        await SidRequestRepository.requestApproval(sidRequest.sidRequestId);
        cbpToast.show('The SID request has been submitted.', 'success');
        $state.go('shareholders-id.agent.landing');
      } catch (err) {
        this.error = err;
        $scope.$apply();
      }
    };

    this.handleSaveOrSubmit = (action) => {
      if (this.setAction) {
        this.closeModal();
        action === 'save' ? this.handleSaveAsDraft() : this.handleSubmit();
      } else {
        if (this.validate()) {
          action === 'save' ? this.handleSaveAsDraft() : this.handleSubmit();
        }
      }
    };

    this.handleBackClick = () => {
      $state.go('^.landing');
    };
  },
];

export default {
  controller: Ctrl,
  templateUrl: `${SID_AG_BASE_URL}/views/create-request/sid-agent-create-request.template.html`,
};
