/* eslint-disable indent */
import { html, LitElement } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class ProxyIntermediaryView extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      proxyIntermediary: { type: Object },
    };
  }

  static get scopedElements() {
    return {
      'aab-typography': customElements.get('aab-typography'),
      'agent-um-simple-tile': customElements.get('agent-um-simple-tile'),
    };
  }

  render() {
    if (!this.proxyIntermediary) {
      return null;
    }

    return html`
      <agent-um-simple-tile
        title="ProxyIntermediary: ${this.proxyIntermediary.groupName}"
      >
        <aab-typography>
          <label>Name</label>
          <p>${this.proxyIntermediary.groupName}</p>

          <label>Email</label>
          <p>${this.proxyIntermediary.groupEmail}</p>

          <label>City</label>
          <p>${this.proxyIntermediary.city}</p>

          <label>Country</label>
          <p>${this.proxyIntermediary.country}</p>
        </aab-typography>
      </agent-um-simple-tile>
    `;
  }
}

customElements.define(
  'agent-um-proxy-intermediary-view',
  ProxyIntermediaryView
);
