require('./em-tile.scss');

module.exports = {
  bindings: {
    padding: '<',
    height: '@'
  },
  transclude: {
    footer: '?emTileFooter',
  },
  template: `
  <div class="em-tile__root bg-white em-shadow-tile" ng-style="{ padding: $ctrl.padding * 4, height: $ctrl.height || 'auto' }" >
    <div ng-transclude>
    
    </div>
    <div ng-transclude="footer" class="em-tile__footer p-2"></div>
  </div>
  `,
};
