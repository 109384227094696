/* eslint-disable indent */
import { LitElement, html } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';
import createTableHeaders from '../../../lib/userGroupTableHeaders';
import { userGroupFilter } from '../../../lib/filters';

class IssuersView extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      issuers: { type: Array },

      nameFilter: { type: String, attribute: false },
    };
  }

  constructor() {
    super();
    this.issuers = [];
    this.tableHeaders = createTableHeaders({
      actions: (i) => html`
        <agent-um-user-group-table-actions
          .toggleButtonIsEnable=${i.status !== 'Enabled'}
          @view-click="${() => this._handleIssuerClicked(i.groupId)}"
          @view-users-click="${() => this._handleViewUsersClicked(i.groupId)}"
          @edit-click="${() => this._handleEditClicked(i.groupId)}"
          @enable-click="${() => this._handleEnableClicked(i.groupId)}"
          @disable-click="${() => this._handleDisableClicked(i.groupId)}"
        ></agent-um-user-group-table-actions>
      `,
    });
  }

  static get scopedElements() {
    return {
      'aab-tile': customElements.get('aab-tile'),
      'aab-simple-table': customElements.get('aab-simple-table'),
      'agent-um-button-and-input-tile': customElements.get(
        'agent-um-button-and-input-tile'
      ),
      'agent-um-user-group-table-actions': customElements.get(
        'agent-um-user-group-table-actions'
      ),
    };
  }

  _handleAddIssuerClicked() {
    this.dispatchEvent(new CustomEvent('add-issuer-click'));
  }

  __dispatchEvent(name, issuerId) {
    this.dispatchEvent(
      new CustomEvent(name, {
        detail: { issuerId },
        bubbles: true,
        composed: true,
      })
    );
  }

  _handleIssuerClicked(issuerId) {
    this.__dispatchEvent('issuer-click', issuerId);
  }

  _handleViewUsersClicked(issuerId) {
    this.__dispatchEvent('view-users-click', issuerId);
  }

  _handleEditClicked(issuerId) {
    this.__dispatchEvent('edit-click', issuerId);
  }

  _handleEnableClicked(issuerId) {
    this.__dispatchEvent('enable-click', issuerId);
  }

  _handleDisableClicked(issuerId) {
    this.__dispatchEvent('disable-click', issuerId);
  }

  _filteredData() {
    if (!this.nameFilter) {
      return this.issuers;
    } else {
      return this.issuers.filter((i) => userGroupFilter(i, this.nameFilter));
    }
  }

  render() {
    return html`
      <agent-um-button-and-input-tile
        buttonTitle="Add issuer"
        inputPlaceholder="Filter by issuer name"
        @button-click="${this._handleAddIssuerClicked}"
        @input-change="${(ev) => {
          this.nameFilter = ev.detail.value;
        }}"
      >
        <aab-tile>
          <aab-simple-table
            fullWidth
            fixed
            paginated
            .columnHeaders="${this.tableHeaders}"
            .data="${this._filteredData()}"
          ></aab-simple-table>
        </aab-tile>
      </agent-um-button-and-input-tile>
    `;
  }
}

customElements.define('agent-um-issuers-view', IssuersView);
