/* eslint-disable indent */
import { css, html, LitElement } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class IssuerView extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      issuer: { type: Object },
    };
  }

  static get scopedElements() {
    return {
      'aab-typography': customElements.get('aab-typography'),
      'agent-um-simple-tile': customElements.get('agent-um-simple-tile'),
    };
  }

  static get styles() {
    return css`
      .logo {
        display: block;
        margin-top: 1rem;
        margin-bottom: 1rem;
        max-width: 160px;
        max-height: 120px;
        object-fit: contain;
      }
    `;
  }

  render() {
    if (!this.issuer) {
      return null;
    }

    return html`
      <agent-um-simple-tile title="Issuer: ${this.issuer.groupName}">
        <aab-typography>
          <label>Name</label>
          <p>${this.issuer.groupName}</p>

          <label>Email</label>
          <p>${this.issuer.groupEmail}</p>

          <label>City</label>
          <p>${this.issuer.city || '-'}</p>

          <label>Country</label>
          <p>${this.issuer.country || '-'}</p>

          <label>Logo</label>
          ${this.issuer.logoBaseStr
            ? html`<img
                class="logo"
                src="data:image/png;base64,${this.issuer.logoBaseStr}"
              />`
            : html`<p>None</p>`}
        </aab-typography>
      </agent-um-simple-tile>
    `;
  }
}

customElements.define('agent-um-issuer-view', IssuerView);
