/* eslint-disable indent */
import { LitElement, html } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class EditProxyIntermediary extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      proxyIntermediary: { type: Object },
    };
  }

  static get scopedElements() {
    return {
      'proxyIntermediary-input-form': customElements.get(
        'agent-um-proxyIntermediary-input-form'
      ),
      'agent-um-simple-tile': customElements.get('agent-um-simple-tile'),
    };
  }

  _handleSaveClick(event) {
    console.warn(event);
  }

  render() {
    if (!this.proxyIntermediary) {
      return null;
    }

    return html`
      <agent-um-simple-tile
        title="Edit proxyIntermediary: ${this.proxyIntermediary.name}"
      >
        <proxyIntermediary-input-form
          .proxyIntermediary=${this.proxyIntermediary}
          @save-click="${this._handleSaveClick}"
        ></proxyIntermediary-input-form>
      </agent-um-simple-tile>
    `;
  }
}

customElements.define(
  'agent-um-edit-proxy-intermediary',
  EditProxyIntermediary
);
