import { LitElement, html } from 'lit-element';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';

class EditProxyIntermediaryUser extends ScopedElementsMixin(LitElement) {
  static get properties() {
    return {
      user: { type: Object },
    };
  }

  static get scopedElements() {
    return {
      'aab-button': customElements.get('aab-button'),
      'user-input-form': customElements.get('agent-um-user-input-form'),
      'agent-um-simple-tile': customElements.get('agent-um-simple-tile'),
    };
  }

  render() {
    if (!this.user) {
      return null;
    }

    return html`
      <agent-um-simple-tile
        title="Edit proxy intermediary user: ${this.user.email}"
      >
        <form>
          <user-input-form
            .user=${this.user}
            @user-change=${(ev) => {
              this.user = { ...ev.detail };
            }}
          ></user-input-form>

          <aab-button style-type="primary">
            <button
              @click="${(ev) => {
                ev.preventDefault();
                this.dispatchEvent(
                  new CustomEvent('save-click', {
                    detail: { ...this.user },
                  })
                );
              }}"
            >
              Save
            </button>
          </aab-button>
        </form>

        <slot></slot>
      </agent-um-simple-tile>
    `;
  }
}

customElements.define(
  'agent-um-edit-proxy-intermediary-user',
  EditProxyIntermediaryUser
);
