import moment from 'moment';
import {
  DISCLOSURE_DEADLINE_DATE_MIN_DAYS_LATER,
  REPORT_DEADLINE_DATE_MIN_DAYS_LATER
} from 'Modules/shareholders-id/constants';
import { parseLargeInt, parsePercentageNumber } from '@/lib/parseNumber';

export default [
  function () {
    function resolveShareThreshold(option, pct, qty, total) {
      if (option === 'percentage') {
        /** thresholdPct could contain comma's instead of dot as radix markers - we want dots */
        pct = parsePercentageNumber(pct);
        pct /= 100;

        // ShareThreshold must be greater than 0 - even if pct === 0
        return Math.max(1, parseLargeInt(total) * pct);
      } else {
        return parseLargeInt(qty);
      }
    }

    function resolveStartDate(option, startDate) {
      if (option === 'asap') {
        return moment().format('YYYY-MM-DD');
      } else {
        return moment(startDate).format('YYYY-MM-DD');
      }
    }

    function resolveReportDeadlineDate(
      option,
      date,
      { startDate, recordDate }
    ) {
      const laterDate = startDate > recordDate ? startDate : recordDate;
      if (option === 'default') {
        return moment(laterDate)
          .add(REPORT_DEADLINE_DATE_MIN_DAYS_LATER, 'days')
          .format('YYYY-MM-DD');
      } else {
        return date;
      }
    }

    function resolveDisclosureDeadlineDate(
      option,
      date,
      { startDate, recordDate }
    ) {
      const laterDate = startDate > recordDate ? startDate : recordDate;
      if (option == 'default') {
        return moment(laterDate)
          .add(DISCLOSURE_DEADLINE_DATE_MIN_DAYS_LATER, 'days')
          .format('YYYY-MM-DD');
      } else {
        return date;
      }
    }

    function sidRequestFromCreationForm(formData) {
      let {
        issuerId,
        csdId,
        totalOutstandingShares,
        isin,
        recordDate,
        thresholdOption,
        thresholdPct,
        thresholdQty,
        startDateOption,
        startDate: formStartDate,
        reportDeadlineDateOption,
        reportDeadlineDate: formReportDeadlineDate,
        disclosureDeadlineDateOption,
        disclosureDeadlineDate: formDisclosureDeadlineDate,
      } = formData;

      totalOutstandingShares = parseLargeInt(totalOutstandingShares);
      const shareThreshold = resolveShareThreshold(
        thresholdOption,
        thresholdPct,
        thresholdQty,
        totalOutstandingShares
      );
      const startDate = resolveStartDate(startDateOption, formStartDate);
      const reportDeadlineDate = resolveReportDeadlineDate(
        reportDeadlineDateOption,
          formReportDeadlineDate,
        { startDate, recordDate }
      );
      const disclosureDeadlineDate = resolveDisclosureDeadlineDate(
        disclosureDeadlineDateOption,
          formDisclosureDeadlineDate,
        { startDate, recordDate }
      );

      return {
        issuerId,
        csdId,
        isin,
        totalOutstandingShares,
        recordDate,
        shareThreshold,
        startDate,
        disclosureDeadlineDate,
        reportDeadlineDate,
      };
    }

    function creationFormDataFromSidRequest(sidRequest) {
      return {
        issuerId: sidRequest.issuerId,
        csdId: sidRequest.csdId,
        isin: sidRequest.isin,
        recordDate: sidRequest.recordDate,
        thresholdOption: 'quantity',
        thresholdQty: sidRequest.shareThreshold,
        totalOutstandingShares: sidRequest.totalOutstandingShares,
        startDateOption: 'scheduled',
        startDate: sidRequest.startDate,
        disclosureDeadlineDateOption: 'custom',
        disclosureDeadlineDate: sidRequest.disclosureDeadlineDate,
        reportDeadlineDateOption: 'custom',
        reportDeadlineDate: sidRequest.reportDeadlineDate,
      };
    }

    return {
      sidRequestFromCreationForm,
      creationFormDataFromSidRequest,
    };
  },
];
