import './IntermediaryView';
import { fetchIntermediary } from '@/api/v1/userGroups';

export default {
  controller: [
    '$http',
    '$scope',
    '$stateParams',
    function ($http, $scope, $stateParams) {
      this.$onInit = () => {
        this.fetchIntermediary().then(() => {
          $scope.$apply();
        });
      };

      this.fetchIntermediary = async () => {
        this.intermediary = await fetchIntermediary(
          $http,
          $stateParams.intermediaryId
        );
      };
    },
  ],
  template: `
    <div class="agUserManagementIntermediary my-3">
      <div class="p-5" ng-if="!$ctrl.intermediary">
        <cbp-loading-spinner></cbp-loading-spinner>
      </div>
      <agent-um-intermediary-view ng-if="$ctrl.intermediary" ng-prop-intermediary="$ctrl.intermediary"></agent-um-intermediary-view>
    </div>
  `,
};
