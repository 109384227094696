const { BASE_URL } = require('../constants');

require('./em-product-tile.css');

module.exports = {
  bindings: {
    image: '@',
    icon: '@',
    title: '@',
    subtitle: '@',
    detail: '@',
    showArrow: '<',
    arrowDirection: '@',
    verticalAlign: '@',
  },
  transclude: {
    image: '?emProductTileImage',
    main: '?emProductTileMain',
    middle: '?emProductTileMiddle',
    right: '?emProductTileRight',
    actions: '?emActions'
  },
  controller: [
    function () {
      this.rotateClass = () => {
        const direction = this.arrowDirection || 'right';
        const map = {
          right: 'rotate-0',
          down: 'rotate-90',
          left: 'rotate-180',
          up: '-rotate-90',
        };
        return map[direction];
      };

      this.verticalAlignClass = () => {
        const vAlignment = this.verticalAlign || 'middle';
        const map = {
          top: 'justify-content-start',
          middle: 'justify-content-center',
          bottom: 'justify-content-end'
        };

        return map[vAlignment];
      };
    },
  ],
  templateUrl: `${BASE_URL}/em-product-tile/em-product-tile.template.html`,
};
